import React, { useEffect, useState, useRef, Fragment } from "react";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  useUpdateEventStatusOrRemark,
  useGetEventsInvoiceOnSuccess,
} from "@api";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EventsInvoice from "./EventsInvoice";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Download } from "react-feather";

const MySwal = withReactContent(Swal);

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [reservationId, setReservationId] = useState("");
  const [quantity, setQuantity] = useState(0);
  // const [amount, setAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("init");
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [razorpayPaymentId, setRazorpayPaymentId] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [bookingId, setBookingId] = useState("");
  const [bookingDate, setBookingDate] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);
  const [cancellationRemark, setCancellationRemark] = useState("");
  const [cancellationAmount, setCancellationAmount] = useState(0);
  const [cancelledBy, setCancelledBy] = useState("");
  const [refundId, setRefundId] = useState("");
  const [refundDate, setRefundDate] = useState(null);
  const [refundStatus, setRefundStatus] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  // const [status, setStatus] = useState("");
  const [status, setStatus] = useState({ label: "Pending", value: "Pending" });
  const [remark, setRemark] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [guestName, setGuestName] = useState("");
  const [guestMobile, setGuestMobile] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventTime, setEventTime] = useState(null);
  const [logs, setLogs] = useState(editData ? editData[0]?.logs : []);
  const [rating, setRating] = useState(0);

  const [updateEventStatusOrRemarkHandler, { data: updatedEventData }] =
    useUpdateEventStatusOrRemark();

  useEffect(() => {
    if (updatedEventData) {
      refetch();
      handleToggle();
    }
  }, [updatedEventData]);

  const EventStatusList = [
    { label: "Pending", value: "Pending" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  useEffect(() => {
    if (editData) {
      setReservationId(editData.reservationId || "");
      setQuantity(editData.quantity || 0);
      setTax(editData?.tax || 0);
      setTotalAmount(editData?.totalAmount || 0);
      setPaymentStatus(editData.paymentStatus || "");
      setRazorpayOrderId(editData.razorpayOrderId || "");
      setRazorpayPaymentId(editData.razorpayPaymentId || "");
      setEventDate(editData.eventDate || null);
      setBookingId(editData.bookingId || "");
      setBookingDate(editData.bookingDate || null);
      setCancellationDate(editData.cancellationDate || null);
      setCancellationRemark(editData.cancellationRemark || "");
      setCancellationAmount(editData.cancellationAmount || 0);
      setCancelledBy(editData.cancelledBy || "");
      setRefundId(editData.refundId || "");
      setRefundDate(editData.refundDate || null);
      setRefundStatus(editData.refundStatus || "");
      setInvoiceNo(editData.invoiceNo || "");
      setStatus(
        editData
          ? {
              label: editData?.status,
              value: editData?.status,
            }
          : ""
      );
      setRemark(editData?.remark || "");
      setHostelName(editData?.hostel?.name || "");
      setGuestName(editData?.guestDetails?.GuestName || "");
      setGuestMobile(editData?.guestDetails?.Mobile || "");
      setEventCategory(editData?.eventDetails?.category || "");
      setEventTitle(editData?.eventDetails?.title || "");
      setEventTime(editData?.eventDetails?.time || "");
      setLogs(editData?.logs || "");
      setRating(editData?.rating || 0);
    }
  }, [editData]);

  const handleSaveButtonClick = () => {
    if (editData?.status === "Cancelled" || editData?.status === "Completed") {
      handleToggle();
      return;
    }
    onSubmitHandler();
  };

  const onSubmitHandler = () => {
    status?.value === "Cancelled"
      ? MySwal.fire({
          title: "Confirm Cancellation?",
          text: "Are you sure you want to proceed with cancellation?",
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#FFE700",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          customClass: {
            confirmButton: "mr-1",
            cancelButton: "ml-1",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            updateStatus();
          }
        })
      : updateStatus();
  };

  const updateStatus = () => {
    const data = {
      status: status?.value,
      remark: remark,
      logs: [
        ...logs,
        `${localStorage.getItem(
          "name"
        )} modified this request on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}, status: ${status.value} & ${
          remark === null ? "" : `Remark: ${remark}`
        }`,
      ],
    };

    if (status && remark) {
      if (editData?._id) {
        const _id = editData._id;
        updateEventStatusOrRemarkHandler(_id, data);
      }
      toaster("success", "Status Updated successfully");
      refetch();
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  const { data } = useGetEventsInvoiceOnSuccess(bookingId);

  const pdfExportComponent = useRef(null);

  const handleExportWithComponent = () => {
    try {
      pdfExportComponent?.current?.save();
    } catch (error) {
      console.error(error);
    }
  };

  const pdfDownloadFunction = () => {
    if (status?.value === "Completed") {
      setBookingId(bookingId);
      handleExportWithComponent();
      toaster("success", "PDF downloaded successfully!");
    } else {
      toaster(
        "error",
        "PDF download is only available for completed bookings."
      );
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      // submitDataHandler={() => onSubmitHandler()}
      submitDataHandler={handleSaveButtonClick}
    >
      {/* Event Details Section */}
      {eventCategory && (
        <>
          <h5>Event Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  name="eventCategory"
                  id="eventCategory"
                  value={eventCategory}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  name="eventTitle"
                  id="eventTitle"
                  value={eventTitle}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="title">Date</Label>
                <Input
                  type="text"
                  name="eventDate"
                  id="eventDate"
                  // value={eventDate}
                  value={new Date(eventDate).toLocaleDateString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  })}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="eventTime">Time</Label>
                <Input
                  type="text"
                  name="eventTime"
                  id="eventTime"
                  // value={eventTime}
                  value={new Date(eventTime).toLocaleTimeString()}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="text"
                  name="quantity"
                  id="quantity"
                  value={quantity}
                  readOnly
                />
              </FormGroup>
            </Col>

            {/* //invoice */}
            {editData?.status === "Completed" && (
              <Col sm="6">
                <FormGroup>
                  <Label for="invoiceNo">Invoice No</Label>
                  <Input
                    type="text"
                    name="invoiceNo"
                    id="invoiceNo"
                    value={invoiceNo}
                    readOnly
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          <hr />
        </>
      )}

      {/* Guest Details Section */}
      {guestName && (
        <>
          <h5>Guest Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="guestName">Guest Name</Label>
                <Input
                  type="text"
                  name="guestName"
                  id="guestName"
                  value={guestName}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="reservationId">Reservation ID</Label>
                <Input
                  type="text"
                  name="reservationId"
                  id="reservationId"
                  value={reservationId}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="guestMobile">Guest Number</Label>
                <Input
                  type="text"
                  name="guestMobile"
                  id="guestMobile"
                  value={guestMobile}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="hostelName">Hostel Name</Label>
                <Input
                  type="text"
                  name="hostelName"
                  id="hostelName"
                  value={hostelName}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
        </>
      )}

      <>
        <h5>Event Booking Details</h5>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                type="text"
                name="paymentStatus"
                id="paymentStatus"
                value={paymentStatus}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="totalAmount">Total Amount</Label>
              <Input
                type="text"
                name="totalAmount"
                id="totalAmount"
                value={totalAmount}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="tax">Tax</Label>
              <Input type="text" name="tax" id="tax" value={tax} readOnly />
            </FormGroup>
          </Col>
          {/* //rating */}

          <Col sm="6">
            <FormGroup>
              <Label for="rating">Rating</Label>
              <Input
                type="rating"
                name="rating"
                id="rating"
                value={rating}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="bookingId">Booking ID</Label>
              <Input
                type="text"
                name="bookingId"
                id="bookingId"
                value={bookingId}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label for="bookingDate">Booking Date</Label>
              <Input
                type="text"
                name="bookingDate"
                id="bookingDate"
                // value={bookingDate}
                value={new Date(bookingDate).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="razorpayPaymentId">Razorpay Payment ID</Label>
              <Input
                type="text"
                name="razorpayPaymentId"
                id="razorpayPaymentId"
                value={razorpayPaymentId}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
      </>

      {/* Cancellation Details */}
      {editData?.status === "Cancelled" && (
        <>
          <h5>Cancellation & Refund Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="cancellationAmount">Cancellation Amount</Label>
                <Input
                  type="text"
                  name="cancellationAmount"
                  id="cancellationAmount"
                  value={cancellationAmount}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancelledBy">Cancelled By</Label>
                <Input
                  type="text"
                  name="cancelledBy"
                  id="cancelledBy"
                  value={cancelledBy}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancellationDate">Cancellation Date</Label>
                <Input
                  type="text"
                  name="cancellationDate"
                  id="cancellationDate"
                  value={new Date(cancellationDate).toLocaleDateString(
                    "en-IN",
                    {
                      timeZone: "Asia/Kolkata",
                    }
                  )}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="cancellationDate">Cancellation Time</Label>
                <Input
                  type="text"
                  name="cancellationDate"
                  id="cancellationDate"
                  value={new Date(cancellationDate).toLocaleTimeString()}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundDate">Refund Date</Label>
                <Input
                  type="text"
                  name="refundDate"
                  id="refundDate"
                  // value={new Date(refundDate).toLocaleDateString("en-IN", {
                  //   timeZone: "Asia/Kolkata",
                  // })}
                  value={
                    refundDate
                      ? new Date(refundDate).toLocaleDateString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })
                      : null
                  }
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundDate">Refund Time</Label>
                <Input
                  type="text"
                  name="refundDate"
                  id="refundDate"
                  // value={new Date(refundDate).toLocaleTimeString()}
                  value={
                    refundDate
                      ? new Date(refundDate).toLocaleTimeString()
                      : null
                  }
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundId">Refund ID</Label>
                <Input
                  type="text"
                  name="refundId"
                  id="refundId"
                  value={refundId}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundStatus">Refund Status</Label>
                <Input
                  type="text"
                  name="refundStatus"
                  id="refundStatus"
                  value={refundStatus}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancellationRemark">Cancellation Remark</Label>
                <Input
                  type="text"
                  name="cancellationRemark"
                  id="cancellationRemark"
                  value={cancellationRemark}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
        </>
      )}

      {/*// Status */}
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label for="Status">Status</Label>
            <Select
              id="status"
              name="status"
              required
              value={status}
              options={EventStatusList}
              // isDisabled={
              //   editData?.status === "Completed" ||
              //   editData?.status === "Cancelled"
              // }
              onChange={(selectedOption) => setStatus(selectedOption)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
      </Row>

      {/* Remark */}
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label for="Remark">Comment</Label>

            <Input
              type="textarea"
              id="remark"
              name="remark"
              required
              placeholder="Type your remark..."
              disabled={
                editData?.status === "Completed" ||
                editData?.status === "Cancelled"
              }
              value={remark}
              rows={4}
              onChange={(e) => setRemark(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* logs */}

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="logs">
              <strong>Logs</strong>
            </Label>
            <br />

            <ul>
              {editData?.logs?.map((i, index) => {
                return (
                  <Fragment key={index}>
                    <li>{i}</li>
                  </Fragment>
                );
              })}
            </ul>
          </FormGroup>
        </Col>
      </Row>

      {/* for event invoice download */}
      <Col>
        {status?.value === "Completed" && totalAmount > 0 && (
          <div>
            <button
              onClick={pdfDownloadFunction}
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                marginLeft: "auto",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "medium",
                  color: "#497BDD",
                  cursor: "pointer",
                  margin: 0,
                  textDecoration: "underline",
                }}
              >
                Invoice
              </p>
              <Download size={20} color="#497BDD" />
            </button>
          </div>
        )}
        {data?.getEventsInvoiceOnSuccess && (
          // <div
          //   style={{
          //     position: "relative",
          //     display: `${
          //       status?.value === "Completed" && totalAmount > 0
          //         ? `block`
          //         : `none`
          //     }`,
          //   }}
          // >
          <div
            style={{
              position: "relative",
              right: "-2000px",
              height: "0px",
              display: `${status?.value === "Completed" ? `block` : `none`}`,
            }}
          >
            <PDFExport
              ref={pdfExportComponent}
              paperSize={"A4"}
              fileName={`invoice_${data?.getEventsInvoiceOnSuccess?.invoiceNo}`}
              author="The Hosteller"
              date={new Date()}
              imageResolution={100}
            >
              <EventsInvoice data={data?.getEventsInvoiceOnSuccess} />
            </PDFExport>
          </div>
        )}
      </Col>
    </SlideModal>
  );
};

export default AddEditModal;
