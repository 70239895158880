import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_POST_STAY_REFUND_REQUEST_LIST = gql`
  query getPaginatedPostStayRefundRequestList($filter: filter) {
    getPaginatedPostStayRefundRequestList(filter: $filter) {
      count
      limit
      page
      data {
        _id
        createdAt
        updatedAt
        hostel {
          name
          _id
        }
      }
    }
  }
`;

export const useGetPaginatedPostStayRefundRequestList = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_POST_STAY_REFUND_REQUEST_LIST,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_REFUND_GUEST_DATA_BY_RESERVATIONID = gql`
  query getRefundGuestDataByReservationId($reservationID: String!) {
    getRefundGuestDataByReservationId(reservationID: $reservationID) {
      reservationID
      bookingAmount
      guestName
      phoneNumber
      email
      bookingSource
    }
  }
`;

// Custom Hook with Skip Logic
// export const useGetRefundGuestDataByReservationId = (reservationID) => {
//   const shouldSkip = !reservationID?.trim();

//   const { loading, error, data } = useQuery(
//     GET_REFUND_GUEST_DATA_BY_RESERVATIONID,
//     {
//       variables: { reservationID },
//       skip: shouldSkip,
//     }
//   );

//   useLoadingHandler(loading);

//   return { loading, error, data };
// };

export const useGetRefundGuestDataByReservationId = (reservationID) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(
    GET_REFUND_GUEST_DATA_BY_RESERVATIONID,
    {
      variables: {
        reservationID,
      },
    }
  );

  return {
    fetchData,
    loading,
    error,
    data,
  };
};
