import { BarChart, Circle } from "react-feather";

const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

export default // role === "Executive" ||
// role === "Revenue" ||
// role === "Reservation" ||
// role === "Super Admin" ||
// role === "Sales Admin" ||
// role === "Marketing Admin" ||
modules?.includes("Reports")
  ? [
      {
        id: "reports",
        title: "Reports",
        icon: <BarChart size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "guestDatabase",
            title: "Centralised Guest Database",
            icon: <Circle size={12} />,
            navLink: "/report/guestdatabase",
          },
          {
            id: "occupancyTracker",
            title: "Daily Occupancy Tracker",
            icon: <Circle size={12} />,
            navLink: "/report/occupancyTracker",
          },
          {
            id: "memberships",
            title: "Membership",
            icon: <Circle size={12} />,
            navLink: "/report/memberships",
          },
          {
            id: "reportsCoupon",
            title: "Coupon Statistics",
            icon: <Circle size={12} />,
            navLink: "/report/coupon",
          },
          {
            id: "chatbotBooking",
            title: "Chatbot Bookings",
            icon: <Circle size={12} />,
            navLink: "/report/chatbotBooking",
          },
          {
            id: "websiteBooking",
            title: "Website Booking",
            icon: <Circle size={12} />,
            navLink: "/report/websiteBooking",
          },
          {
            id: "loyaltyReport",
            title: " Loyalty Coins Usage",
            icon: <Circle size={12} />,
            navLink: "/report/loyaltyReport",
          },
          {
            id: "whatsappMessages",
            title: "WA Message Delivery Report",
            icon: <Circle size={12} />,
            navLink: "/report/whatsappMessages",
          },
          {
            id: "bookingReport",
            title: "Hostelwise booking report",
            icon: <Circle size={12} />,
            navLink: "/report/bookingReport",
          },
          {
            id: "dailyBookings",
            title: "Daily Bookings",
            icon: <Circle size={12} />,
            navLink: "/report/dailyBookings",
          },
          {
            id: "unpaidReservation",
            title: "Unpaid Reservations",
            icon: <Circle size={12} />,
            navLink: "/report/unpaidReservation",
          },
          {
            id: "OTACancellation",
            title: "OTA Cancellation",
            icon: <Circle size={12} />,
            navLink: "/report/OTACancellation",
          },
          {
            id: "staydateBookings",
            title: "Staydate Bookings",
            icon: <Circle size={12} />,
            navLink: "/report/staydateBookings",
          },
          {
            id: "sourceReport",
            title: "Business Source Report",
            icon: <Circle size={12} />,
            navLink: "/report/sourceReport",
          },
          {
            id: "carReport",
            title: "Contribution Analysis Report",
            icon: <Circle size={12} />,
            navLink: "/report/carReport",
          },
          {
            id: "monthlyRevenueSalesReport",
            title: "Roomwise ADR Report",
            icon: <Circle size={12} />,
            navLink: "/report/monthlyRevenueSalesReport",
          },
          {
            id: "hourlySales",
            title: "Hourly Booking Report",
            icon: <Circle size={12} />,
            navLink: "/report/hourlySales",
          },
          {
            id: "extraChargesReport",
            title: "Website Extra Charges Report",
            icon: <Circle size={12} />,
            navLink: "/report/extraChargesReport",
          },
          {
            id: "hotelCenter",
            title: "Google Hotel Centre",
            icon: <Circle size={12} />,
            navLink: "/report/hotelCenter",
          },
          {
            id: "hotelCenter",
            title: "Search Report/Demand Report",
            icon: <Circle size={12} />,
            navLink: "/report/searchReport",
          },
          {
            id: "propertyWiseNoShowCancellation",
            title: "No Show / Cancel Report",
            icon: <Circle size={12} />,
            navLink: "/report/propertyWise/noShow&Cancellation",
          },
          {
            id: "occupancyADRReport",
            title: "Occupancy ADR Report",
            icon: <Circle size={12} />,
            navLink: "/report/occupancyADRReport",
          },
          {
            id: "webCheckinAnalysisReport",
            title: "Web Checkin Analysis",
            icon: <Circle size={12} />,
            navLink: "/report/webCheckinAnalysis",
          },
        ],
      },
    ]
  : [];
