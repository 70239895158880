import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Collapse,
} from "reactstrap";
import SlideModal from "@components/common/slide-modal";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import {
  useUpdateBasicGuestDetails,
  useGetAllAdminUsersList,
  useUpdateGuestFeedbackDetails,
  useUpdateHostelFeedbackDetails,
  useUpdateCXDetails,
  useUpdateCXTicket,
  useGetAllReplyTemplate,
  useGetWebcheckinDataByReservationId,
  useUpdateFakeReview,
  useUpdateAreaManagerInput,
  useUpdateFinalCXRemark,
  useShiftReviewToCantReply,
  useLogGuestAction,
} from "../../api/dataSource";
import AttachmentComponent from "../Opeartions/GOPaymentRequest/AttachmentComponent";

const AddEditModal = ({ open, handleToggle, editData, refetch }) => {
  const [id, setId] = useState(null);
  const [guestName, setGuestName] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [selectedHostelId, setSelectedHostelId] = useState(null);
  const [reviewDate, setReviewDate] = useState(null);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [outOfRating, setOutOfRating] = useState(null);
  const [positiveReview, setPositiveReview] = useState("");
  const [negativeReview, setNegativeReview] = useState("");
  const [bookingSource, setBookingSource] = useState("");
  const [roomDetails, setRoomDetails] = useState("");
  const [noOfNights, setNoOfNights] = useState(null);
  const [groupType, setgroupType] = useState("");
  const [reviewReply, setReviewReply] = useState("");
  const [replyText, setReplyText] = useState("");
  const [replyDate, setReplyDate] = useState(null);
  const [knowAboutThisInstance, setKnowAboutThisInstance] = useState("");
  const [resolveInTime, setResolveInTime] = useState("");
  const [description, setDescription] = useState("");
  const [ticketRespondedBy, setTicketRespondedBy] = useState("");
  const [communicationStablished, setCommunicationStablished] = useState("");
  const [didTheyInform, setDidTheyInform] = useState("");
  const [wereTheySatisfied, setWereTheySatisfied] = useState("");
  const [cxSummary, setCXSummary] = useState("");
  const [Justified, setJustified] = useState("");
  const [cxTicketNumber, setCXTicketNumber] = useState("");
  const [resolution, setResolution] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponValidity, setCouponValidity] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [roomNo, setRoomNo] = useState("");
  const [updateBasicGuestDetailsHandler] = useUpdateBasicGuestDetails();
  const [updateGuestFeedbackDetailsHandler] = useUpdateGuestFeedbackDetails();
  const [updateFakeReviewHandler] = useUpdateFakeReview();
  const [updateHostelFeedbackDetailsHandler] = useUpdateHostelFeedbackDetails();
  const [updateCXDetailsHandler] = useUpdateCXDetails();
  const [updateCXTicketHandler, { data: cxData }] = useUpdateCXTicket();
  const { data: allUsersData } = useGetAllAdminUsersList();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCC, setSelectedCC] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [ccOptions, setCCOptions] = useState([]);
  const { data: replyTemplateData } = useGetAllReplyTemplate();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [images, setImages] = useState([]);
  const [cxDetailsSubmitted, setCxDetailsSubmitted] = useState(false);
  const userRole = localStorage.getItem("role");
  const [webcheckinNotFound, setWebcheckinNotFound] = useState(false);
  const [ticketCreationTime, setTicketCreationTime] = useState(null);
  const [cxTicketResponseTime, setCxTicketResponseTime] = useState(null);
  const [fakeReview, setFakeReview] = useState("");
  const [areaManagerRemark, setAreaManagerRemark] = useState("");
  const [updateAreaManagerInputHandler] = useUpdateAreaManagerInput();
  const [finalCXInput, setFinalCXInput] = useState("");
  const [updateFinalCXRemarkHandler] = useUpdateFinalCXRemark();
  const [logGuestActionHandler] = useLogGuestAction();
  const [caseMediaAttachmentList, setcaseMediaAttachmentList] = useState([]);
  const [dummyStateCaseMedia] = useState(null);
  const uploadTypeCaseMedia = [{ label: "imageURL", value: "caseMedia" }];
  const [brand, setBrand] = useState(null);

  const [reviewId, setReviewId] = useState("");
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [logs, setLogs] = useState([]);
  const [sentForRemoval, setSentForRemoval] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(false);

  const toggleLogs = () => {
    setIsLogsOpen(!isLogsOpen);
  };

  const [hostelTicketResponseTime, setHostelTicketResponseTime] =
    useState(null);

  const { data: webcheckinData, refetch: refetchWebcheckinData } =
    useGetWebcheckinDataByReservationId();

  const [modal, setModal] = useState(false);
  const [shiftReviewToCantReplyHandler, { loading, error, data, reset }] =
    useShiftReviewToCantReply();

  const toggleModal = () => setModal(!modal);

  const handleShiftReview = async () => {
    try {
      await shiftReviewToCantReplyHandler(id);
      toggleModal();
      refetch();
      handleToggle();
      toaster("success", "Review shifted to 'Can't reply' successfully");
    } catch (error) {
      toaster("error", "Failed to shift review to 'Can't reply'");
    }
  };

  useEffect(() => {
    if (editData) {
      refetch();
    }
  }, [editData]);

  useEffect(() => {
    if (editData) {
      setId(editData?._id || null);
      setGuestName(editData?.guestName || "");
      setHostelName(editData?.hostel?.name || "");
      setBrand(editData?.hostel?.brand || null);
      setSelectedHostelId(editData?.hostel?._id || null);
      setReviewDate(editData?.reviewDate || null);
      setReview(editData?.review || "");
      setCXTicketNumber(editData?.cxTicketNumber || "");
      setRating(editData?.rating || null);
      setOutOfRating(editData?.outOfRating || null);
      setPositiveReview(editData?.positiveReview || "");
      setNegativeReview(editData?.negativeReview || "");
      setBookingSource(editData?.bookingSource || "");
      setRoomDetails(editData?.roomDetails || "");
      setNoOfNights(editData?.noOfNights || "");
      setgroupType(editData?.groupType || "");
      setReviewReply(editData?.reviewReply || "");
      setReplyText(editData?.reviewReply || "");
      setReplyDate(editData?.replyDate || null);
      setReservationId(editData?.reservationId || "");
      setPhoneNumber(editData?.phoneNumber || "");
      setEmailId(editData?.emailId || "");
      setRoomNo(editData?.roomNo || "");
      setDidTheyInform(editData?.guestFeedbackDetails?.didTheyInform || "");
      setCommunicationStablished(
        editData?.guestFeedbackDetails?.wasCommunicationStablished || ""
      );
      setWereTheySatisfied(
        editData?.guestFeedbackDetails?.wereTheySatisfied || ""
      );

      setKnowAboutThisInstance(
        editData?.hostelFeedbackDetails?.knowAboutThisInstance || ""
      );
      setResolveInTime(editData?.hostelFeedbackDetails?.resolveInTime || "");
      setDescription(editData?.hostelFeedbackDetails?.description || "");
      setCXSummary(editData?.cxDetails?.cxSummary || "");
      setJustified(editData?.cxDetails?.Justified || "");
      setResolution(editData?.cxDetails?.resolution || "");
      setCoupon(editData?.cxDetails?.coupon || null);
      setSelectedCC(editData?.ticketDetails?.selectedCC || null);
      setSelectedUser(editData?.ticketDetails?.selectedUser || null);
      setCouponValidity(editData?.cxDetails?.couponValidity || null);
      setImages(editData?.images || []);
      setCxDetailsSubmitted(editData?.cxDetails?.cxDetailsSubmitted || false);
      setTicketCreationTime(
        editData?.ticketDetails?.TicketCreationTime || null
      );
      setHostelTicketResponseTime(
        editData?.hostelFeedbackDetails?.TicketResponseTime || null
      );
      setCxTicketResponseTime(
        editData?.cxDetails?.cxTicketResponseTime || null
      );
      setFakeReview(editData?.isReviewFake === "true" ? "yes" : "no");
      setAreaManagerRemark(editData?.areaManagerRemark || "");
      setFinalCXInput(editData?.finalCXRemark || "");
      setReviewId(editData?.reviewId || "");
      setLogs(editData?.logs ? editData?.logs : []);
      setcaseMediaAttachmentList(
        editData?.caseMedia ? editData?.caseMedia : []
      );
      setSentForRemoval(
        editData?.sentForRemoval ? editData?.sentForRemoval : false
      );
    }
  }, [editData]);

  const bookingSourceImages = {
    "Booking.com":
      "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
    Goibibo:
      "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
    HostelWorld:
      "https://static.thehosteller.com/Hostel/Media/hostelworld%202-1695294893675.svg",
    Google:
      "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
    MakeMyTrip:
      "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
    Tripadvisor:
      "https://static.thehosteller.com/Hostel/Media/Tripadvisor-1695294898265.svg",
    Airbnb:
      "https://static.thehosteller.com/Hostel/Media/image-1725440828248.svg",
  };

  const renderRating = () => {
    const ratingElements = [];
    const maxRating = ["Booking.com", "HostelWorld"].includes(bookingSource)
      ? 10
      : 5;

    for (let i = 1; i <= maxRating; i++) {
      const boxStyle = {
        backgroundColor: i <= rating ? "green" : "grey",
        color: "white",
        padding: "4px",
        margin: "2px",
        borderRadius: "5px",
      };
      ratingElements.push(
        <span key={i} style={boxStyle}>
          {i}
        </span>
      );
    }

    return ratingElements;
  };

  const detailsOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const cxSummaryOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Partially", value: "Partially" },
    { label: "Can't say", value: "Can't say" },
  ];

  const resolutionDropDown = [
    { label: "N/A", value: "N/A" },
    { label: "Apology mail", value: "apologyMail" },
    { label: "Coupon", value: "Coupon" },
    {
      label: "Communication not established",
      value: "communicationNotEstablished",
    },
    { label: "Refund", value: "Refund" },
    { label: "Amendment Mail", value: "amendmentMail" },
    { label: "Follow-up Mail", value: "followUpMail" },
  ];

  const couponDropDown = [
    { label: "0", value: 0 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
  ];

  const couponValidityDropDown = [
    { label: "30", value: 30 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
    { label: "180", value: 180 },
    { label: "365", value: 365 },
  ];

  const isReviewFakeDropDown = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  useEffect(() => {
    if (selectedHostelId && allUsersData) {
      const filteredUsers = allUsersData?.getAllAdminUsersList?.data?.filter(
        (user) => user?.hostels?.includes(selectedHostelId)
      );
      const users = filteredUsers?.map((user) => ({
        value: user?._id,
        label: user?.name,
      }));

      setUserOptions(users);
      setCCOptions(users);
    }
  }, [selectedHostelId, allUsersData]);

  const handleCreateTicket = async () => {
    const _id = id;

    const ticketInput = {
      ticketDetails: {
        selectedUser: {
          label: selectedUser.label,
          value: selectedUser.value,
        },
        selectedCC: selectedCC.map((option) => ({
          label: option.label,
          value: option.value,
        })),
      },
    };

    try {
      await updateCXTicketHandler(_id, ticketInput);
      toaster("success", "Ticket created successfully");
      refetch();
    } catch (error) {
      toaster("error", "Failed to create a ticket.");
    }
  };

  useEffect(() => {
    setReplyText(selectedTemplate?.value || "");
  }, [selectedTemplate]);

  const templateOptions = replyTemplateData?.getAllReplyTemplate?.data?.map(
    (template) => ({
      value: template.templateContent,
      label: template.templateTitle,
    })
  );

  const handleTemplateChange = (selectedOption) => {
    setSelectedTemplate(selectedOption);
  };

  useEffect(() => {
    setSelectedTemplate(null);
  }, [open]);

  // Helper function to calculate the difference in days
  const calculateNights = (checkinDate, checkoutDate) => {
    const checkIn = new Date(checkinDate);
    const checkOut = new Date(checkoutDate);
    const timeDiff = checkOut.getTime() - checkIn.getTime();
    const nights = timeDiff / (1000 * 3600 * 24);
    return nights > 0 ? nights : 0;
  };

  const handleFetchData = async () => {
    try {
      const { data: webcheckinData } = await refetchWebcheckinData({
        reservationID: reservationId,
      });

      if (!webcheckinData?.getWebcheckinDataByReservationId) {
        setWebcheckinNotFound(true);
      } else {
        setWebcheckinNotFound(false);
        setPhoneNumber(
          webcheckinData.getWebcheckinDataByReservationId.phoneNumber || ""
        );
        setEmailId(
          webcheckinData?.getWebcheckinDataByReservationId?.email || ""
        );
        setRoomNo(
          webcheckinData?.getWebcheckinDataByReservationId?.RoomNo || ""
        );
        const checkIn =
          webcheckinData?.getWebcheckinDataByReservationId?.checkInDate;
        const checkOut =
          webcheckinData?.getWebcheckinDataByReservationId?.checkOutDate;

        setCheckinDate(checkIn);
        setCheckoutDate(checkOut);

        const nights = calculateNights(checkIn, checkOut);
        setNoOfNights(nights);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleGuestDetailsSubmit = async () => {
    const _id = id;
    const nights = calculateNights(checkinDate, checkoutDate);

    const guestDetailsInput = {
      reservationId,
      phoneNumber,
      emailId,
      roomNo,
      noOfNights: nights,
    };

    try {
      const { data: webcheckinData } = await refetchWebcheckinData({
        reservationID: reservationId,
      });

      setPhoneNumber(
        webcheckinData?.getWebcheckinDataByReservationId?.phoneNumber || ""
      );
      setEmailId(webcheckinData?.getWebcheckinDataByReservationId?.email || "");
      setRoomNo(webcheckinData?.getWebcheckinDataByReservationId?.RoomNo || "");
      setCheckinDate(
        webcheckinData?.getWebcheckinDataByReservationId?.checkInDate
      );
      setCheckoutDate(
        webcheckinData?.getWebcheckinDataByReservationId?.checkOutDate
      );

      await updateBasicGuestDetailsHandler(_id, guestDetailsInput);

      toaster("success", "Guest details updated successfully");
      refetch();
    } catch (error) {
      toaster("error", "Failed to update guest details.");
    }
  };

  const handleGuestFeedbackDetailsSubmit = async () => {
    if (!communicationStablished) {
      toaster("error", "Please select whether communication was established");
      return;
    }

    if (communicationStablished === "Yes" && !didTheyInform) {
      toaster("error", "Please select whether they informed");
      return;
    }

    if (
      (communicationStablished === "Yes" && didTheyInform === "Yes") ||
      editData?.guestFeedbackDetails?.wereTheySatisfied
    ) {
      if (!wereTheySatisfied) {
        toaster("error", "Please select whether they were satisfied");
        return;
      }
    }

    const _id = id;
    const guestFeedbackDetailsInput = {
      wasCommunicationStablished: communicationStablished,
      didTheyInform: didTheyInform,
      wereTheySatisfied: wereTheySatisfied,
    };

    try {
      await updateGuestFeedbackDetailsHandler(_id, guestFeedbackDetailsInput);
      toaster("success", "Guest Feedback details updated successfully");
      refetch();
    } catch (error) {
      toaster("error", "Failed to update guest feedback details.");
    }
  };

  const handleHostelFeedbackDetailsSubmit = async () => {
    if (!knowAboutThisInstance) {
      toaster("error", "Please select whether you know about this instance");
      return;
    }

    if (knowAboutThisInstance === "Yes") {
      if (!resolveInTime) {
        toaster("error", "Please select whether you resolved in time");
        return;
      }

      if (resolveInTime === "Yes" && !description) {
        toaster(
          "error",
          "Please provide a description of how you resolved the issue"
        );
        return;
      }

      if (resolveInTime === "No" && !description) {
        toaster(
          "error",
          "Please provide a description of why there was no resolution"
        );
        return;
      }
    }

    if (!ticketRespondedBy) {
      toaster("error", "Please enter your name");
      return;
    }

    const _id = id;
    const hostelFeedbackDetailsInput = {
      knowAboutThisInstance: knowAboutThisInstance,
      resolveInTime: resolveInTime,
      description: description,
      ticketRespondedBy: ticketRespondedBy,
    };

    try {
      await updateHostelFeedbackDetailsHandler(_id, hostelFeedbackDetailsInput);
      toaster("success", "Hostel Feedback details updated successfully");
      refetch();
    } catch (error) {
      toaster("error", "Failed to update Hostel feedback details.");
    }
  };

  const handleCXDetailsSubmit = async () => {
    if (!cxSummary || !Justified || !resolution) {
      toaster("error", "Please fill in all required fields");
      return;
    }

    const _id = id;
    const CXDetailsInput = {
      cxSummary: cxSummary,
      Justified: Justified,
      resolution: resolution,
      coupon: coupon,
      couponValidity: couponValidity,
    };

    try {
      await updateCXDetailsHandler(_id, CXDetailsInput);
      toaster("success", "CX details updated successfully");
      refetch();
    } catch (error) {
      toaster("error", "Failed to update CX details.");
    }
  };

  const handleSubmitFakeReview = async () => {
    const _id = id;
    const isReviewFake = fakeReview;

    try {
      await updateFakeReviewHandler(_id, isReviewFake, sentForRemoval);
      toaster("success", "Data updated successfully.");
      refetch();
      handleToggle();
    } catch (error) {
      toaster("error", "Failed to update data.");
    }
  };

  // Handler for the toggleSentForRemoval
  const toggleSentForRemoval = () => {
    setSentForRemoval((prevState) => !prevState);
  };

  const handleAreaManagerRemarkSubmit = async () => {
    const _id = id;
    const areaManagerInput = areaManagerRemark;

    try {
      await updateAreaManagerInputHandler(_id, areaManagerInput);
      toaster("success", "Area Manager review submitted successfully!");
      refetch();
    } catch (error) {
      toaster("error", "Failed to submit a Review.");
    }
  };

  const handleFinalCXRemarkSubmit = async () => {
    const _id = id;
    const finalCXRemark = finalCXInput;
    const caseMedia = caseMediaAttachmentList;

    try {
      await updateFinalCXRemarkHandler(_id, finalCXRemark, caseMedia);

      toaster("success", "Update successful!");
      refetch();
    } catch (error) {
      toaster("error", "Failed to submit the update.");
    }
  };

  const handleSaveClick = async () => {
    if (
      bookingSource === "Google" ||
      bookingSource === "Goibibo" ||
      bookingSource === "MakeMyTrip"
    ) {
      const reviewId = editData?.reviewId;
      const source = bookingSource;
      const brandValue = brand;

      if (reviewId && replyText) {
        try {
          const requestBody = {
            reviewId: reviewId,
            reply: replyText,
            bookingSource: source,
          };

          if (source === "Goibibo" || source === "MakeMyTrip") {
            requestBody.brand = brandValue;
          }

          const response = await fetch(
            "https://api.thehosteller.com/rest/v1/ota-reviews/reply",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (response.ok) {
            setReviewReply(replyText);
            toaster("success", "Response sent successfully");
            handleToggle();
            await logGuestActionHandler(id);
          } else {
            const errorResponse = await response.json();
            throw new Error(
              JSON.stringify(errorResponse.message) ||
                "Failed to send TH Response"
            );
          }
        } catch (error) {
          toaster("error", error.message || "Error sending TH Response");
        }
      }
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={handleToggle}
    >
      {/* Review Details Section */}
      {bookingSource && (
        <>
          <Row>
            <Col sm="6">
              <FormGroup>
                {bookingSource && (
                  <>
                    {bookingSourceImages[bookingSource] ? (
                      <img
                        src={bookingSourceImages[bookingSource]}
                        alt={bookingSource}
                        width="120"
                        height="60"
                      />
                    ) : (
                      <span>{bookingSource}</span>
                    )}
                  </>
                )}
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="guestName"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Guest Name</strong>
                </Label>
                <br />
                <Label
                  className="form-label"
                  for="guestName"
                  style={{ fontSize: "14px" }}
                >
                  {guestName}
                </Label>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="reviewDate"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Review Date</strong>
                </Label>
                <br />
                <Label
                  className="form-label"
                  for="reviewDate"
                  style={{ fontSize: "14px" }}
                >
                  {new Date(reviewDate).toLocaleDateString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  })}
                </Label>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="Rating"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Rating</strong>
                </Label>
                <br />
                <Label className="form-label" for="Rating">
                  <strong style={{ display: "flex", alignItems: "center" }}>
                    {renderRating()}
                  </strong>
                </Label>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="hostelName"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Hostel Name</strong>
                </Label>
                <br />
                <Label
                  className="form-label"
                  for="hostelName"
                  style={{ fontSize: "14px" }}
                >
                  {hostelName}
                </Label>
              </FormGroup>
            </Col>

            {roomDetails && (
              <Col sm="6">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="roomDetails"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Room Details</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="roomDetails"
                    style={{ fontSize: "14px" }}
                  >
                    {roomDetails}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {noOfNights && (
              <Col sm="6">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="noOfNights"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Number of Nights</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="noOfNights"
                    style={{ fontSize: "14px" }}
                  >
                    {noOfNights}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {groupType && (
              <Col sm="6">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="groupType"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Group Type</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="groupType"
                    style={{ fontSize: "14px" }}
                  >
                    {noOfNights}
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
          <hr />

          <Row>
            <Col sm="12">
              <FormGroup>
                <Label className="form-label" style={{ fontSize: "14px" }}>
                  <strong>Title:</strong> {review}
                </Label>

                <br />

                {(bookingSource === "Booking.com" ||
                  bookingSource === "HostelWorld" ||
                  bookingSource === "Tripadvisor") && (
                  <Button
                    color="primary"
                    onClick={toggleModal}
                    style={{
                      marginTop: "8px",
                      padding: "5px 8px",
                      fontSize: "12px",
                      lineHeight: "1",
                      backgroundImage:
                        "linear-gradient(to right, #ff416c, #ff4b2b)",
                    }}
                  >
                    Shift to "Can't reply"
                  </Button>
                )}

                <Modal isOpen={modal} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>Confirmation</ModalHeader>
                  <ModalBody>
                    Are you sure you want to shift this review to "Can't reply"?
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={handleShiftReview}>
                      Yes, Shift
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </FormGroup>
            </Col>

            {review && reviewId && (
              <Col sm="12">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="review"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Review ID</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="review"
                    style={{ fontSize: "14px" }}
                  >
                    {reviewId}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {positiveReview && (
              <Col sm="12">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="positiveReview"
                    style={{ color: "green", fontSize: "14px" }}
                  >
                    <strong>Positive Feedback</strong>
                  </Label>

                  <br />
                  <Label
                    className="form-label"
                    for="positiveReview"
                    style={{ fontSize: "14px" }}
                  >
                    {positiveReview}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {negativeReview && (
              <Col sm="12">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="negativeReview"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    <strong>Negative Feedback</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="negativeReview"
                    style={{ fontSize: "14px" }}
                  >
                    {negativeReview}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {/* Images */}
            {editData?.images && (
              <Col sm="12">
                <div style={{ display: "flex" }}>
                  {images.map((imageUrl, index) => (
                    <div key={index} style={{ marginRight: "10px" }}>
                      <img
                        src={imageUrl}
                        alt={`Review ${index + 1}`}
                        onClick={() => window.open(imageUrl, "_blank")}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100%",
                          width: "80px",
                          height: "80px",
                          marginBottom: "10px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            )}

            {editData?.reviewReply && (
              <Col sm="12">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="reviewReply"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>TH Response</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="reviewReply"
                    style={{ fontSize: "14px" }}
                  >
                    {reviewReply}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {(bookingSource === "Google" ||
              ((bookingSource === "Goibibo" ||
                bookingSource === "MakeMyTrip") &&
                editData?.review)) &&
              !editData?.reviewReply && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="reviewReply">
                      <strong>TH Response</strong>
                    </Label>
                    <br />

                    {/* Template dropdown */}
                    <Select
                      options={templateOptions}
                      onChange={handleTemplateChange}
                      value={selectedTemplate}
                      defaultValue={null}
                      placeholder="Select Reply Template"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "lightgray",
                          border: "1px solid darkgray",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "gray"
                            : "darkgray",
                          color: "black",
                        }),
                      }}
                      isClearable
                    />
                    <br />
                    <Input
                      type="textarea"
                      id="remark"
                      name="remark"
                      placeholder="Type your remark..."
                      disabled={editData?.reviewReply}
                      value={replyText}
                      rows={6}
                      onChange={(e) => setReplyText(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              )}

            {/* TH Response Submit button */}
            {(bookingSource === "Google" ||
              ((bookingSource === "Goibibo" ||
                bookingSource === "MakeMyTrip") &&
                editData?.review)) &&
              !reviewReply &&
              (userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color="success"
                  onClick={handleSaveClick}
                  disabled={editData?.reviewReply}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                  }}
                >
                  Reply
                </Button>
              )}

            {replyDate && (
              <Col sm="6">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="replyDate"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Reply Date</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="replyDate"
                    style={{ fontSize: "14px" }}
                  >
                    {new Date(replyDate).toLocaleDateString()}
                  </Label>
                </FormGroup>
              </Col>
            )}

            {replyDate && (
              <Col sm="6">
                <FormGroup>
                  <Label
                    className="form-label"
                    for="replyDate"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Reply Time</strong>
                  </Label>
                  <br />
                  <Label
                    className="form-label"
                    for="replyDate"
                    style={{ fontSize: "14px" }}
                  >
                    {new Date(replyDate).toLocaleTimeString()}
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row className="align-items-center">
            <Col sm="6">
              <FormGroup>
                <Label className="form-label" for="isReviewFake">
                  <strong>Is this Review Fake?</strong>
                </Label>
                <Select
                  menuPlacement="top"
                  isClearable={false}
                  isSearchable={true}
                  id="fakeReview"
                  name="fakeReview"
                  value={isReviewFakeDropDown.find(
                    (option) => option.value === fakeReview
                  )}
                  options={isReviewFakeDropDown}
                  onChange={(selectedOption) =>
                    setFakeReview(selectedOption.value)
                  }
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup>
                <Label className="form-label" for="sentForRemoval">
                  <strong>Sent For Removal</strong>
                </Label>
                <CustomInput
                  type="switch"
                  id="sentforRemoval"
                  name="sentForRemoval"
                  checked={sentForRemoval}
                  onChange={toggleSentForRemoval}
                />
              </FormGroup>
            </Col>

            <Button
              color={"dark"}
              onClick={handleSubmitFakeReview}
              className="cursor-pointer"
              style={{
                fontSize: "10px",
                padding: "8px 10px",
                marginTop: "12px",
              }}
            >
              Submit
            </Button>
          </Row>

          <hr />

          {/* guest details */}
          <h5>Guest Basic Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="basicDetails"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Reservation ID </strong>
                </Label>
                <Input
                  type="input"
                  name="description"
                  placeholder="Reservation ID"
                  id="reservationId"
                  value={reservationId}
                  onChange={(e) => setReservationId(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="basicDetails"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Phone Number</strong>
                </Label>
                <Input
                  type="input"
                  name="description"
                  placeholder="Phone Number"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <br />
            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="basicDetails"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Email ID</strong>
                </Label>
                <Input
                  type="input"
                  name="description"
                  placeholder="Email ID"
                  id="email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label
                  className="form-label"
                  for="basicDetails"
                  style={{ fontSize: "14px" }}
                >
                  <strong>Room No</strong>
                </Label>
                <Input
                  type="input"
                  name="description"
                  placeholder="Room Number"
                  id="roomNo"
                  value={roomNo}
                  onChange={(e) => setRoomNo(e.target.value)}
                />
              </FormGroup>
            </Col>

            <div>
              {/* Fetch Guest Data */}
              {(userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color="dark"
                  onClick={handleFetchData}
                  className="ml-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "8px 12px",
                    marginBottom: "8px",
                    backgroundColor: "black",
                    borderColor: "black",
                    color: "white",
                  }}
                >
                  Fetch Guest Details
                </Button>
              )}

              {/* Guest Details Submit button */}
              {(userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color="success"
                  onClick={handleGuestDetailsSubmit}
                  className="ml-2 mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "8px 12px",
                    marginBottom: "8px",
                    color: "black",
                  }}
                >
                  Submit Guest Details
                </Button>
              )}
            </div>
          </Row>

          {/* Added this for displaying the error message if reservation id not found */}
          {webcheckinNotFound && (
            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              Webcheckin data not found for the provided Reservation ID.
            </p>
          )}
          <hr />

          {/* Create a ticket section */}

          <div>
            <h5>Create Ticket</h5>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>
                    <strong>Select Primary User: </strong>
                    {editData?.cxTicketNumber && (
                      <Badge color="dark"> {cxTicketNumber}</Badge>
                    )}
                  </Label>

                  <Select
                    options={userOptions}
                    value={selectedUser}
                    onChange={(selectedOption) =>
                      setSelectedUser(selectedOption)
                    }
                    isDisabled={editData?.ticketDetails !== null}
                    placeholder="Select User"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "lightgray",
                        border: "1px solid darkgray",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "gray" : "darkgray",
                        color: "black",
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup>
                  <Label>
                    <strong>Select CC:</strong>
                  </Label>
                  <Select
                    options={ccOptions}
                    value={selectedCC}
                    onChange={(selectedOptions) =>
                      setSelectedCC(selectedOptions)
                    }
                    isDisabled={editData?.ticketDetails !== null}
                    isMulti
                    placeholder="Select CC"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "lightgray",
                        border: "1px solid darkgray",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "gray" : "darkgray",
                        color: "black",
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {editData?.ticketDetails?.TicketCreationTime && (
                <Col sm="12">
                  <FormGroup>
                    <Label>
                      <strong>
                        Ticket Creation Time:{" "}
                        {new Date(ticketCreationTime).toLocaleDateString(
                          "en-IN",
                          {
                            timeZone: "Asia/Kolkata",
                          }
                        )}{" "}
                        {new Date(ticketCreationTime).toLocaleTimeString()}
                      </strong>
                    </Label>
                  </FormGroup>
                </Col>
              )}

              {(userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color={editData?.ticketDetails ? "secondary" : "success"}
                  onClick={handleCreateTicket}
                  disabled={editData?.ticketDetails}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                  }}
                >
                  Create
                </Button>
              )}
            </Row>
            <hr />
          </div>

          {/* Hostel Feedback Details */}

          <h5>Hostel Feedback Details</h5>

          <Row>
            <Col sm="12">
              <FormGroup>
                <Label className="form-label" for="hostelDetails">
                  <strong>Did you know about this instance?</strong>
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="hostelDetails"
                  name="hostelDetails"
                  value={detailsOptions.find(
                    (option) => option.value === knowAboutThisInstance
                  )}
                  isDisabled={editData?.hostelFeedbackDetails !== null}
                  options={detailsOptions}
                  required
                  // onChange={(selectedOption) =>
                  //   setKnowAboutThisInstance(selectedOption.value)
                  // }
                  onChange={(selectedOption) => {
                    setKnowAboutThisInstance(selectedOption.value);

                    // Resetting resolveInTime & discription if knowAboutThisInstance is "No"
                    if (selectedOption.value === "No") {
                      setResolveInTime("");
                      setDescription("");
                    }
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {(knowAboutThisInstance === "Yes" ||
              editData?.knowAboutThisInstance === "Yes" ||
              editData?.hostelFeedbackDetails?.knowAboutThisInstance) && (
              <Col sm="12">
                <FormGroup>
                  <Label className="form-label" for="hostelDetails">
                    <strong>Did you resolve it in time?</strong>
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="hostelDetails"
                    name="hostelDetails"
                    value={detailsOptions.find(
                      (option) => option.value === resolveInTime
                    )}
                    options={detailsOptions}
                    isDisabled={editData?.hostelFeedbackDetails !== null}
                    onChange={(selectedOption) =>
                      setResolveInTime(selectedOption.value)
                    }
                    required
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
            )}

            {(knowAboutThisInstance === "Yes" ||
              editData?.hostelFeedbackDetails?.knowAboutThisInstance) && (
              <Col sm="12">
                <FormGroup>
                  <Label for="description">
                    <strong>
                      {resolveInTime === "Yes"
                        ? "How did you resolve it?"
                        : resolveInTime && resolveInTime === "No"
                        ? "Why could there be no resolution?"
                        : "Resolution"}
                    </strong>
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Type your remark..."
                    id="description"
                    value={description}
                    disabled={editData?.hostelFeedbackDetails}
                    // isDisabled={editData?.hostelFeedbackDetails !== null}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}

            <Col sm="12">
              <FormGroup>
                <Label className="form-label" for="hostelDetails">
                  <strong>Ticket responded by</strong>
                </Label>

                <Input
                  type="text"
                  name="ticketRespondedBy"
                  placeholder="Enter your name..."
                  id="ticketRespondedBy"
                  value={ticketRespondedBy}
                  disabled={editData?.hostelFeedbackDetails}
                  required
                  onChange={(e) => setTicketRespondedBy(e.target.value)}
                />
              </FormGroup>
            </Col>

            {editData?.hostelFeedbackDetails?.TicketResponseTime && (
              <Col sm="12">
                <FormGroup>
                  <Label>
                    <strong>
                      Hostel Response Time:{" "}
                      {new Date(hostelTicketResponseTime).toLocaleDateString(
                        "en-IN",
                        {
                          timeZone: "Asia/Kolkata",
                        }
                      )}{" "}
                      {new Date(hostelTicketResponseTime).toLocaleTimeString()}
                    </strong>
                  </Label>
                </FormGroup>
              </Col>
            )}

            {(userRole === "Customer Experience" ||
              userRole === "Super Admin" ||
              userRole === "Ground Staff" ||
              userRole === "Area Manager") && (
              <Button
                color={
                  editData?.hostelFeedbackDetails ? "secondary" : "success"
                }
                onClick={handleHostelFeedbackDetailsSubmit}
                disabled={editData?.hostelFeedbackDetails !== null}
                className="ml-auto mr-2 cursor-pointer"
                style={{
                  fontSize: "12px",
                  padding: "10px 16px",
                }}
              >
                Submit Hostel Details
              </Button>
            )}
          </Row>

          <hr />

          {/* Guest Feedback Details */}
          {/* {!(userRole === "Ground Staff" || userRole === "Area Manager") && ( */}
          <div>
            <h5>Guest Feedback Details</h5>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label className="form-label" for="guestDetails">
                    <strong>Was communication established? </strong>
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="guestDetails"
                    name="guestDetails"
                    value={detailsOptions.find(
                      (option) => option.value === communicationStablished
                    )}
                    options={detailsOptions}
                    // onChange={(selectedOption) =>
                    //   setCommunicationStablished(selectedOption.value)
                    // }
                    onChange={(selectedOption) => {
                      setCommunicationStablished(selectedOption.value);
                      if (selectedOption.value === "No") {
                        setDidTheyInform("");
                        setWereTheySatisfied("");
                      }
                    }}
                    isDisabled={editData?.guestFeedbackDetails}
                    classNamePrefix="select"
                    required
                  />
                </FormGroup>
              </Col>
              {(communicationStablished === "Yes" ||
                editData?.guestFeedbackDetails?.communicationStablished) && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="guestDetails">
                      <strong>Did the guest inform?</strong>
                    </Label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="guestDetails"
                      name="guestDetails"
                      value={detailsOptions.find(
                        (option) => option.value === didTheyInform
                      )}
                      options={detailsOptions}
                      onChange={(selectedOption) =>
                        setDidTheyInform(selectedOption.value)
                      }
                      isDisabled={editData?.guestFeedbackDetails}
                      classNamePrefix="select"
                      required
                    />
                  </FormGroup>
                </Col>
              )}
              {((communicationStablished === "Yes" &&
                didTheyInform === "Yes") ||
                editData?.guestFeedbackDetails?.wereTheySatisfied) && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="guestDetails">
                      <strong>Was the guest satisfied?</strong>
                    </Label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="guestDetails"
                      name="guestDetails"
                      value={detailsOptions.find(
                        (option) => option.value === wereTheySatisfied
                      )}
                      options={detailsOptions}
                      onChange={(selectedOption) =>
                        setWereTheySatisfied(selectedOption.value)
                      }
                      isDisabled={editData?.guestFeedbackDetails}
                      classNamePrefix="select"
                      required
                    />
                  </FormGroup>
                </Col>
              )}
              {(userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color={
                    editData?.guestFeedbackDetails ? "secondary" : "success"
                  }
                  onClick={handleGuestFeedbackDetailsSubmit}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                  }}
                  disabled={editData?.guestFeedbackDetails}
                >
                  Submit Guest Details
                </Button>
              )}
            </Row>
            <hr />

            {/* CX Summary Details */}
            <h5>CX Summary</h5>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label className="form-label" for="cxSummary">
                    <strong>Justified</strong>
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="cxSummary"
                    name="cxSummary"
                    value={cxSummaryOptions.find(
                      (option) => option.value === Justified
                    )}
                    isDisabled={editData?.cxDetails !== null}
                    options={cxSummaryOptions}
                    onChange={(selectedOption) =>
                      setJustified(selectedOption.value)
                    }
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup>
                  <Label className="form-label" for="cxSummary">
                    <strong>Summary </strong>
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Type your remark..."
                    id="cxSummary"
                    value={cxSummary}
                    disabled={editData?.cxDetails?.cxSummary}
                    onChange={(e) => setCXSummary(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Resolution */}
            <Label className="form-label" for="cxSummary">
              <strong>Resolution </strong>
            </Label>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Select
                    menuPlacement="top"
                    isClearable={false}
                    isSearchable={true}
                    id="resolution"
                    name="resolution"
                    value={resolutionDropDown.find(
                      (option) => option.value === resolution
                    )}
                    // isDisabled={editData?.cxDetails !== null}
                    isDisabled={editData?.cxDetails?.resolution === "Coupon"}
                    // when the reservationId is not presernt or if is empty,
                    // undefined or not present then we dont have to display the coupon in the dropdown
                    options={resolutionDropDown.filter(
                      (option) =>
                        option.value !== "Coupon" ||
                        (reservationId !== null &&
                          reservationId !== undefined &&
                          reservationId !== "")
                    )}
                    onChange={(selectedOption) =>
                      setResolution(selectedOption.value)
                    }
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              {/* dropdown only when in resolution coupon is selected */}
              {resolution === "Coupon" && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="Coupon">
                      <strong>Coupon</strong>
                    </Label>
                    <Select
                      menuPlacement="top"
                      isClearable={false}
                      isSearchable={true}
                      id="Coupon"
                      name="Coupon"
                      value={couponDropDown.find(
                        (option) => option.value === coupon
                      )}
                      isDisabled={editData?.cxDetails !== null}
                      options={couponDropDown}
                      onChange={(selectedOption) =>
                        setCoupon(selectedOption.value)
                      }
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              )}

              {resolution === "Coupon" && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="couponValidity">
                      <strong>Coupon validity</strong>
                    </Label>
                    <Select
                      menuPlacement="top"
                      isClearable={false}
                      isSearchable={true}
                      id="couponValidity"
                      name="couponValidity"
                      value={couponValidityDropDown.find(
                        (option) => option.value === couponValidity
                      )}
                      isDisabled={editData?.cxDetails !== null}
                      options={couponValidityDropDown}
                      onChange={(selectedOption) =>
                        setCouponValidity(selectedOption.value)
                      }
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              )}

              {editData?.cxDetails?.cxTicketResponseTime && (
                <Col sm="12">
                  <FormGroup>
                    <Label>
                      <strong>
                        CX Response Time:{" "}
                        {new Date(cxTicketResponseTime).toLocaleDateString(
                          "en-IN",
                          {
                            timeZone: "Asia/Kolkata",
                          }
                        )}{" "}
                        {new Date(cxTicketResponseTime).toLocaleTimeString()}
                      </strong>
                    </Label>
                  </FormGroup>
                </Col>
              )}

              {(userRole === "Customer Experience" ||
                userRole === "Super Admin") && (
                <Button
                  color={editData?.cxDetails ? "secondary" : "success"}
                  onClick={handleCXDetailsSubmit}
                  disabled={editData?.cxDetails}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                    marginBottom: "20px",
                  }}
                >
                  Submit CX Details
                </Button>
              )}
            </Row>
            <hr />

            <h5>AM Feedback Details</h5>
            {(userRole === "Customer Experience" ||
              userRole === "Super Admin" ||
              userRole === "Area Manager" ||
              userRole === "Regional Manager" ||
              userRole === "Ground Staff") && (
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label className="form-label" for="cxSummary">
                      <strong>
                        What steps are taken to avoid such issue from not
                        happening in the future?
                      </strong>
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      placeholder="Type your remark..."
                      id="areaManagerInput"
                      value={areaManagerRemark}
                      disabled={editData?.areaManagerRemark}
                      onChange={(e) => setAreaManagerRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Button
                  color={editData?.areaManagerRemark ? "secondary" : "success"}
                  onClick={handleAreaManagerRemarkSubmit}
                  // disabled={editData?.areaManagerRemark}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                  }}
                >
                  Submit Area Manager Remark
                </Button>
              </Row>
            )}

            <hr />

            {(userRole === "Customer Experience" ||
              userRole === "Super Admin") && (
              <Row>
                <h5>Additional Details</h5>
                <Col sm="12">
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="description"
                      id="finalCXInput"
                      value={finalCXInput}
                      // disabled={editData?.areaManagerRemark}
                      onChange={(e) => setFinalCXInput(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <Label className="form-label" for="poAttachmentList">
                    <strong> Case Investigation Media</strong>
                  </Label>
                  <AttachmentComponent
                    imageList={caseMediaAttachmentList}
                    setImageList={setcaseMediaAttachmentList}
                    editData={dummyStateCaseMedia}
                    uploadType={uploadTypeCaseMedia}
                  />
                </Col>

                <Button
                  color={"success"}
                  onClick={handleFinalCXRemarkSubmit}
                  className="ml-auto mr-2 cursor-pointer"
                  style={{
                    fontSize: "12px",
                    padding: "10px 16px",
                  }}
                >
                  Submit Detail
                </Button>
              </Row>
            )}
          </div>
          {/* )} */}

          <>
            {editData?.logs && (
              <Col sm="12">
                <hr />
                <FormGroup className="w-100">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Label className="form-label" for="logs">
                      <strong>Logs:</strong>
                    </Label>
                    <Button color="primary" size="sm" onClick={toggleLogs}>
                      {isLogsOpen ? "Hide Logs" : "Show Logs"}
                    </Button>
                  </div>
                  <Collapse isOpen={isLogsOpen}>
                    <ul style={{ listStylePosition: "outside" }}>
                      {editData.logs.map((log, index) => (
                        <li key={index}>
                          <Label className="form-label">{log}</Label>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </FormGroup>
              </Col>
            )}
          </>
        </>
      )}
    </SlideModal>
  );
};

export default AddEditModal;
