import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import AddEditModal from "./AddEditModal";
import UserRequestTable from "../../../@core/components/tables/UserRequestTable";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import {
  useHostelList,
  useRequestNameList,
  useAllUserRequest,
  useGetHostelsNameandId,
  useGenerateUserRequestExport,
} from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import { Button } from "reactstrap";
import { CSVLink } from "react-csv";

const UserRequest = () => {
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const [RequestNameList, setRequestNameList] = useState("");
  const [requestCategory, setRequestCategory] = useState(null);
  const [requestName, setRequestName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [requestStatus, setRequestStatus] = useState(null);
  // const [requestStartDate, setRequestStartDate] = useState(null);
  // const [requestEndDate, setRequestEndDate] = useState(null);
  const currentDate = new Date();
  const dayDifference = new Date(currentDate);
  dayDifference.setDate(dayDifference.getDate() - 2);

  const [requestStartDate, setRequestStartDate] = useState(dayDifference);
  const [requestEndDate, setRequestEndDate] = useState(currentDate);

  const [requestNumber, setRequestNumber] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [exportTableData, setExportTableData] = useState([]);
  const [filterParams, setFilterParams] = useState(null);

  // const { data } = useHostelList();
  const { data } = useGetHostelsNameandId();
  const { data: requestNameList } = useRequestNameList(
    requestCategory?.value.length > 0 ? requestCategory?.value : null
  );

  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  const { data: exportData } = useAllUserRequest(
    filterParams?.hostel.length > 0
      ? filterParams?.hostel.map((hostel) => hostel.value)
      : JSON.parse(localStorage.getItem("hostels")) !== null
      ? JSON.parse(localStorage.getItem("hostels"))
      : [],
    filterParams?.requestCategory?.value.length > 0
      ? filterParams?.requestCategory?.value
      : undefined,
    filterParams?.requestStatus?.value.length > 0
      ? filterParams?.requestStatus?.value
      : undefined,
    filterParams?.requestName?.length > 0
      ? filterParams?.requestName
      : undefined,
    filterParams?.roomNumber?.length > 0 ? filterParams?.roomNumber : undefined,
    filterParams?.requestStartDate,
    filterParams?.requestEndDate,
    filterParams?.requestNumber?.length > 0
      ? filterParams?.requestNumber
      : undefined,
    filterParams?.reservationId?.length > 0
      ? filterParams?.reservationId
      : undefined
  );

  const tableHeaderDataObject = [
    { label: "Request Number", key: "requestNumber" },
    { label: "Guest Name", key: "guestName" },
    { label: "Request Category", key: "requestCategory" },
    { label: "Request Name", key: "requestName" },
    { label: "Hostel Name", key: "hostel" },
    { label: "Request time", key: "requestTime" },
    { label: "Request date", key: "requestDate" },
    { label: "Reservation Id", key: "reservationId" },
    { label: "Request Status", key: "requestStatus" },
    { label: "TAT(in minutes)", key: "tat" },
    { label: "Completion Time (in minutes)", key: "completionTime" },
    { label: "Room No", key: "roomNo" },
    { label: "Staff Remark", key: "staffRemark" },
    { label: "Rating", key: "rating" },
    { label: "Difference", key: "difference" },
    { label: "Critical", key: "critical" },
  ];

  function completionTimeDifference(updatedAt, createdAt) {
    const updatedAtDate = new Date(updatedAt);
    const createdAtDate = new Date(createdAt);
    const timeDifference = updatedAtDate - createdAtDate;
    const timeDifferenceInMinutes = Math.ceil(timeDifference / (1000 * 60));
    return timeDifferenceInMinutes;
  }

  //for export data
  useEffect(() => {
    if (exportData) {
      let userRequestList = exportData?.getAllUserRequestList?.data?.map(
        (item) => {
          const completionTime = ["Close", "Accept", "Reject"].includes(
            item?.requestStatus
          )
            ? completionTimeDifference(item?.updatedAt, item?.createdAt)
            : "-";

          return {
            id: item?._id,
            requestNumber: item?.requestNumber,
            // requestTime: item?.createdAt,
            requestTime: new Date(item?.createdAt).toLocaleTimeString(),
            requestDate: new Date(item?.createdAt).toLocaleDateString("en-CA"),
            reservationId: item?.reservationId,
            requestCategory: item?.requestCategory,
            requestName: item?.request?.requestName,
            hostel: item?.ezeebookings?.hostelName,
            guestName: item?.ezeebookings?.GuestName,
            requestStatus: item?.requestStatus,
            room: item?.ezeebookings?.Room,
            roomNo: item?.ezeebookings?.RoomNo,
            tat: item?.request?.tat,
            isCritical: item?.isCritical,
            rating: item?.rating,
            staffRemark: item?.staffRemark,
            completionTime: completionTime,
            difference: completionTime - item?.request?.tat,
            critical: item?.isCritical,
          };
        }
      );
      setExportTableData(userRequestList);
    }
  }, [exportData]);

  // useEffect(() => {
  //   const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
  //   if (data?.getHostelList?.length) {
  //     const filteredHostels =
  //       allowedHostel !== null && allowedHostel?.length !== 0
  //         ? allowedHostel?.map((hostelID) => {
  //             return data?.getHostelList?.find((item) => item._id === hostelID);
  //           })
  //         : data?.getHostelList;
  //     let HostelsList = filteredHostels?.map((item) => {
  //       return { label: item?.name, value: item?._id };
  //     });
  //     setHostelsList(HostelsList);
  //   }
  // }, [data?.getHostelList]);

  // const [generateUserRequestExportHandler, { data: userRequestDataForExport }] =
  //   useGenerateUserRequestExport();

  // console.log(userRequestDataForExport, "userRequestDataForExport");
  // useEffect(() => {
  //   if (userRequestDataForExport?.generateEventsExport?.data) {
  //     console.log(userRequestDataForExport, "eventsDataForExport");
  //     if (userRequestDataForExport?.generateEventsExport?.data.length > 0) {
  //       const byteArray = new Uint8Array(
  //         userRequestDataForExport?.generateEventsExport?.data
  //       );
  //       const blob = new Blob([byteArray], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `GLU_Events_Export.xlsx`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(a);
  //     }
  //   }
  // }, [userRequestDataForExport]);

  // const exportInputFilter = {
  //   filterParams,
  // };

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
    if (data?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelID) => {
              return data?.getHostelsNameandId?.find(
                (item) => item._id === hostelID
              );
            })
          : data?.getHostelsNameandId;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [data?.getHostelsNameandId]);

  useEffect(() => {
    if (requestNameList?.getRequestNameListByRequestCategory?.length) {
      let RequestNameList =
        requestNameList?.getRequestNameListByRequestCategory?.map((item) => {
          return { label: item?.requestName, value: item?._id };
        });
      setRequestNameList(RequestNameList);
    }
  }, [requestNameList]);

  const RequestCategoryList = [
    { label: "Housekeeping", value: "Housekeeping" },
    { label: "Booking", value: "Booking" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "Other requests", value: "Other requests" },
  ];

  const RequestStatusList = [
    { label: "Open", value: "Open" },
    { label: "Close", value: "Close" },
    { label: "Accept", value: "Accept" },
    { label: "Reject", value: "Reject" },
    { label: "Work in progress", value: "Work in progress" },
    { label: "Critical", value: "isCritical" },
  ];

  const submitHandler = (e) => {
    const data = {
      hostel,
      requestCategory,
      requestStatus,
      requestName: requestName?.label,
      roomNumber,
      // requestStartDate,
      // requestEndDate,
      requestNumber,
      reservationId,
    };
    setFilterParams(data);
  };

  const refreshHandler = () => {
    setHostel([]);
    setRequestCategory(null);
    setRequestName("");
    setRoomNumber("");
    setRequestStatus(null);
    setRequestStartDate(null);
    setRequestEndDate(null);
    setRequestNumber("");
    setFilterParams(null);
    setReservationId("");
  };

  const role = localStorage.getItem("role");

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong> Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestCategory">
                <strong> Request Category</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="requestCategory"
                name="requestCategory"
                value={requestCategory}
                options={RequestCategoryList}
                onChange={(value) => {
                  setRequestCategory(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestName">
                <strong> Request Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="requestName"
                name="requestName"
                value={requestName}
                options={RequestNameList}
                onChange={(value) => {
                  setRequestName(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestStatus">
                <strong>Request Status</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                // isMulti
                id="requestStatus"
                name="requestStatus"
                value={requestStatus}
                options={RequestStatusList}
                onChange={(value) => {
                  setRequestStatus(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="expiry date">
                <strong>Request Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                onChange={(date) => {
                  setRequestStartDate(new Date(date[0]));
                  setRequestEndDate(new Date(date[1]));
                }}
                value={[requestStartDate, requestEndDate]}
                id="requestDate"
                placeholder="Request Date"
                options={{
                  mode: "range",
                  ...options,
                }}
                required
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestNumber">
                <strong> Request ID</strong>
              </Label>
              <Input
                type="text"
                id="requestNumber"
                name="requestNumber"
                placeholder="Type here..."
                value={requestNumber}
                onChange={(e) => {
                  setRequestNumber(e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="roomNumber">
                <strong> Room Number</strong>
              </Label>
              <Input
                type="text"
                id="roomNumber"
                name="roomNumber"
                placeholder="Type here..."
                value={roomNumber}
                onChange={(e) => {
                  setRoomNumber(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationId">
                <strong> Reservation ID</strong>
              </Label>
              <Input
                type="text"
                id="reservationId"
                name="reservationId"
                placeholder="Type here..."
                value={reservationId}
                onChange={(e) => {
                  setReservationId(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col>
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                submitHandler();
              }}
            >
              Search
            </Button>
            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                refreshHandler();
              }}
            >
              Reset
            </Button>

            {/* Have added the condition because we have to disable the export button for Ground Operation */}
            {role !== "Ground Staff" && (
              <CSVLink
                headers={tableHeaderDataObject}
                data={exportTableData}
                filename={`requests_list.csv`}
              >
                <Button.Ripple
                  style={{
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            )}

            {/* {role !== "Ground Staff" && (
              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="success"
                appearance="success"
                type="button"
                onClick={() =>
                  generateUserRequestExportHandler(exportInputFilter)
                }
              >
                Export
              </Button>
            )} */}
          </Col>
        </Row>
        <UserRequestTable
          AddEditModal={AddEditModal}
          filterParams={filterParams}
          requestStartDate={requestStartDate}
          requestEndDate={requestEndDate}
        />
      </div>
    )
  );
};

export default UserRequest;
