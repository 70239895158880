// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";

import { useCreateAdminUsers, useUpdateAdminUsers } from "../../api/dataSource";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import SwitchIcon from "@components/common/switch-icon";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { selectThemeColors } from "@utils";
import { useHostelList } from "../../api/dataSource";

const AddEditModal = ({
  open,
  handleToggle,
  refetch,
  editData,
  DepartmentList,
  StaffRoleList,
}) => {
  const [departmentName, setDepartmentName] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [status, setStatus] = useState(false);
  const [arrModules, setArrModules] = useState(null);
  const [arrHostels, setArrHostels] = useState(null);
  const [arrHeads, setArrHeads] = useState(null);
  // const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);
  const [createAdminUsersHandler, { data }] = useCreateAdminUsers();
  const [updateAdminUsersHandler, { data: updatedAdminUsersData }] =
    useUpdateAdminUsers();
  const [hostelOptions, setHostelOptions] = useState([]);

  const { data: hostelListData } = useHostelList();

  const modules = [
    { label: "Packages", value: "Packages" },
    { label: "CMS", value: "CMS" },
    { label: "Blogs", value: "Blogs" },
    { label: "Instant Communication", value: "Instant Communication" },
    // { label: "Complaints list in ORM", value: "In-stay feedback" },
    { label: "ORM Configuration", value: "ORM Configuration" },
    { label: "ORM Reports", value: "ORM Reports" },
    { label: "Coupon Admin", value: "Coupon Admin" },
    { label: "Glu Configuration", value: "Glu Configuration" },
    { label: "Glu Reports", value: "Glu Reports" },
    { label: "Reports", value: "Reports" },
    { label: "Employee Profile", value: "Employee Profile" },
    { label: "Employee Attendance", value: "Employee Attendance" },
    { label: "Loyalty", value: "Loyalty" },
    { label: "Stepout", value: "Stepout" },
    { label: "Admin Users", value: "Admin Users" },
    { label: "Website Users", value: "Website Users" },
    { label: "Wallet", value: "Wallet" },
    { label: "Operations", value: "Operations" },
    { label: "Unbox Configuration", value: "Unbox Configuration" },
    { label: "Unbox Reports", value: "Unbox Reports" },
    { label: "Ezee Meals", value: "Ezee Meals" },
    { label: "Day Book", value: "Day Book" },
    { label: "Payment Request", value: "Payment Request" },
    { label: "Purchase Request", value: "Purchase Request" },
    { label: "Rate Gain", value: "Rate Gain" },
    { label: "Merchandise Store", value: "Merchandise Store" },
    { label: "Booking Dashboard", value: "Booking Dashboard" },
  ];

  const headDropdown = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
  ];

  useEffect(() => {
    if (hostelListData) {
      const arrHostels = hostelListData?.getHostelList;
      const hostelList = arrHostels?.map((hostel) => {
        return { label: hostel?.name, value: hostel?._id };
      });
      setHostelOptions(hostelList);
    }
  }, [hostelListData]);

  useEffect(() => {
    if (updatedAdminUsersData) {
      refetch();
      // handleToggle();
      toaster("success", "User updated successfully");
    }
  }, [refetch, updatedAdminUsersData]);

  useEffect(() => {
    if (data?.createAdminUsers?.message === "User already exists") {
      toaster("error", `${data?.createAdminUsers?.message}`);
    } else if (data?.createAdminUsers?.message === "User added successfully") {
      toaster("success", `${data?.createAdminUsers?.message}`);
    }
  }, [data]);

  useEffect(() => {
    if (editData?._id) {
      setDepartmentName({
        label: editData?.department,
        value: editData?.department,
      });
      setStaffName(editData?.name);
      setStaffEmail(editData?.email);
      setStaffPassword(editData?.password);
      setStaffRole({ label: editData?.role, value: editData?.role });
      setStatus(editData?.status);
      // setLogs(editData?.logs ? editData?.logs : []);
      const selectedHostels = editData?.hostels?.map((element) => {
        const filterdHostels = hostelOptions?.find((i) => i.value === element);
        return filterdHostels;
      });
      const selectedModules = editData?.modules?.map((i) => ({
        label: i,
        value: i,
      }));
      const selectedHeads = editData?.head?.map((i) => ({
        label: i,
        value: i,
      }));
      setArrHostels(selectedHostels === undefined ? [] : selectedHostels);
      setArrModules(selectedModules === undefined ? [] : selectedModules);
      setArrHeads(selectedHeads === undefined ? [] : selectedHeads);
    } else {
      setDepartmentName("");
      setStaffName("");
      setStaffEmail("");
      setStaffPassword("");
      setStaffRole("");
      setStatus(false);
      // setLogs([]);
      setArrHostels(null);
      setArrModules(null);
      setArrHeads(null);
    }
  }, [
    editData?._id,
    editData?.department,
    editData?.email,
    editData?.hostels,
    editData?.modules,
    editData?.head,
    editData?.name,
    editData?.password,
    editData?.role,
    editData?.status,
    // editData?.logs,
    hostelOptions,
  ]);

  const onSubmitHandler = () => {
    const data = {
      department: departmentName.value,
      name: staffName,
      email: staffEmail.toLowerCase(),
      password: staffPassword,
      role: staffRole?.value,
      modules: arrModules?.map((module) => module.value),
      head: arrHeads?.map((head) => head.value),
      hostels: arrHostels?.map((hostel) => hostel.value),
      status: status,
      // logs: [
      //   ...logs,
      //   `${localStorage.getItem("name")} ${
      //     editData ? "modified" : "created"
      //   } this profile on ${new Date().toLocaleString("en-IN", {
      //     hour12: false,
      //   })}`,
      // ],
    };

    if (departmentName && staffName && staffEmail && staffRole) {
      if (editData?._id) {
        const _id = editData?._id;
        updateAdminUsersHandler(_id, {
          department: departmentName.value,
          name: staffName,
          email: staffEmail.toLowerCase(),
          // password: staffPassword,
          role: staffRole.value,
          modules: arrModules.map((module) => module.value),
          head: arrHeads?.map((head) => head.value),
          hostels: arrHostels.map((hostel) => hostel.value),
          status: status,
          // logs: [
          //   ...logs,
          //   `${localStorage.getItem("name")} ${
          //     editData ? "modified" : "created"
          //   } this profile on ${new Date().toLocaleString("en-IN", {
          //     hour12: false,
          //   })}`,
          // ],
        });
        handleToggle(false);
      } else {
        createAdminUsersHandler(data);
        handleToggle(false);
      }
      // toaster("success", "User added successfully");
      refetch();
      setStaffName("");
      setStaffEmail("");
      setStaffPassword("");
      setStaffRole("");
      setStatus(false);
      // setLogs([]);
      setArrHostels(null);
      setArrModules(null);
      setArrHeads(null);
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <Row>
        {/* // ! Name of Department*/}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="departmentName">
              Department Name*
            </Label>
            <Select
              isClearable={false}
              id="departmentName"
              name="departmentName"
              value={departmentName}
              options={DepartmentList}
              onChange={(value) => setDepartmentName(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        {/* // ! Staff's Name */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="staffName">
              Staff's Name*
            </Label>
            <Input
              autoFocus={true}
              type="text"
              value={staffName}
              id="staffName"
              name="staffName"
              placeholder="Name"
              onChange={(e) => setStaffName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Staff's Email */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="staffEmail">
              Staff's Email*
            </Label>
            <Input
              autoFocus={true}
              type="email"
              value={staffEmail}
              id="staffEmail"
              name="staffEmail"
              placeholder="Email"
              onChange={(e) => setStaffEmail(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! Staff's Password */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="staffPassword">
              Staff's Password*
            </Label>
            <Input
              type="password"
              value={staffPassword}
              id="staffPassword"
              name="staffPassword"
              placeholder="Password"
              onChange={(e) => setStaffPassword(e.target.value)}
              disabled={editData?.password?.length}
            />
          </FormGroup>
        </Col>

        {/* // ! Staff's Role */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="staffRole">
              Staff's Role*
            </Label>
            <Select
              isClearable={false}
              id="staffRole"
              name="staffRole"
              value={staffRole}
              options={StaffRoleList}
              onChange={(value) => setStaffRole(value)}
              classNamePrefix="select"
            />
          </FormGroup>

          <FormGroup className="w-100">
            <Label className="form-label" for="staffRole">
              Select Modules*
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              options={modules}
              value={arrModules}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(module) => {
                setArrModules(module);
              }}
            />
          </FormGroup>

          <FormGroup className="w-100">
            <Label className="form-label" for="head">
              Head{" "}
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              options={headDropdown}
              value={arrHeads}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(module) => {
                setArrHeads(module);
              }}
            />
          </FormGroup>

          <FormGroup className="w-100">
            <Label className="form-label" for="staffRole">
              Select Hostel
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              value={arrHostels}
              options={hostelOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(hostel) => {
                setArrHostels(hostel);
              }}
            />
          </FormGroup>
        </Col>

        {/* // ! Status */}
        <Col sm="12">
          <SwitchIcon
            checked={status}
            id="status"
            name="status"
            label="Active"
            handleChecked={(value) => setStatus(value)}
          />
        </Col>

        {/* // ! Staff's Phone Number
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffPhoneNumber">
                Staff's Phone Number*
              </Label>
              <Input
                autoFocus={true}
                type="number"
                value={staffPhoneNumber}
                id="staffPhoneNumber"
                name="staffPhoneNumber"
                placeholder="Phone Number"
                onChange={(e) => setStaffPhoneNumber(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {editData?.logs && (
          <Col sm="12">
            {/* <hr/> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {editData?.logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name">
                      {/* <p >{log}.</p> */}
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        )}
      </Row>
    </SlideModal>
  );
};

export default AddEditModal;
