import React, { useEffect, useState, Fragment } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import toaster from "../../@core/components/common/Toaster";
import { useUploadImage, useHostelList } from "../../api/dataSource";
import RefundRequestSlideModal from "./RefundRequestSlideModal";
import RefudRequestAttachmentComponent from "./RefudRequestAttachmentComponent";
import { useGetRefundGuestDataByReservationId } from "../../api/RefundRequestPostStay/Queries";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const userDepartment = localStorage.getItem("department");
  const userRole = localStorage.getItem("role");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const [comment, setComment] = useState("");
  const [reservationID, setReservationID] = useState("");
  const [guestData, setGuestData] = useState({
    bookingAmount: null,
    guestName: "",
    phoneNumber: "",
    email: "",
    bookingSource: "",
  });
  const { loading, error, data, fetchData } =
    useGetRefundGuestDataByReservationId(reservationID);

  useEffect(() => {
    if (data?.getRefundGuestDataByReservationId) {
      const fetchedData = data.getRefundGuestDataByReservationId;
      setGuestData({
        reservationID: fetchedData.reservationID,
        bookingAmount: fetchedData.bookingAmount,
        guestName: fetchedData.guestName,
        phoneNumber: fetchedData.phoneNumber,
        email: fetchedData.email,
        bookingSource: fetchedData.bookingSource,
      });
      console.log("Fetched Guest Data:", fetchedData);
    }
  }, [data]);

  const handleFetchGuestData = () => {
    if (reservationID) {
      fetchData({
        variables: {
          reservationID,
        },
      });

      toaster("success", "Guest data fetched successfully");
    }
    if (!reservationID) {
      toaster("info", "Please enter a Reservation ID.");
      return;
    }
    if (error) {
      console.log("No data available; check if reservationID is valid.");
      toaster(
        "error",
        "Failed to fetch guest data. Please check the reservation ID."
      );
    }
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;
    if (value > guestData.bookingAmount) {
      toaster("error", "Refund amount cannot exceed the booking amount.");
    } else {
      setRefundAmount(value);
    }
  };

  const [reason, setReason] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [attachmentList, setAttachmentList] = useState([]);
  const [dummyStateCaseMedia] = useState(null);
  const uploadAttachmentMedia = [{ label: "imageURL", value: "photo" }];
  const [video, setVideo] = useState([]);
  const [dummyVideoState] = useState(null);
  const uploadVideoType = [{ label: "mediaURL", value: "video" }];

  const requestReasonOptions = [
    { label: "R&M", value: "R&M" },
    { label: "Cleanliness", value: "Cleanliness" },
    { label: "Safety", value: "Safety" },
    { label: "Staff misconduct", value: "Staff misconduct" },
    { label: "Co-traveler misconduct", value: "Co-traveler misconduct" },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Blacklist", value: "Blacklist" },
    { label: "Others", value: "Others" },
  ];

  const checkoutOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  return (
    <RefundRequestSlideModal
      open={open}
      handleToggle={handleToggle}
      //   submitDataHandler={() => onSubmitHandler()}
      editData={editData}
      userRole={userRole}
      userDepartment={userDepartment}
    >
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              Hostel name*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostel"
              name="hostel"
              options={hostelsList}
              value={hostel}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        {/* <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationID">
              Reservation ID*
            </Label>
            <Input
              type="text"
              value={reservationID}
              id="reservationID"
              name="reservationID"
              placeholder="Reservation ID"
              onChange={(e) => setReservationID(e.target.value)}
            />
          </FormGroup>
        </Col> */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationID">
              Reservation ID*
            </Label>
            <Input
              type="text"
              value={reservationID}
              id="reservationID"
              name="reservationID"
              placeholder="Reservation ID"
              onChange={(e) => setReservationID(e.target.value)}
            />
            <Button
              onClick={handleFetchGuestData}
              color="primary"
              size="sm"
              className="mt-2"
              disabled={loading}
            >
              {loading ? "Fetching..." : "Fetch"}
            </Button>
            {error && toaster("error", "Error fetching guest data.")}
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="guestName">
              Guest Name
            </Label>
            <Input type="text" value={guestData.guestName || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="phoneNumber">
              Phone Number
            </Label>
            <Input type="text" value={guestData.phoneNumber || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="email">
              Email
            </Label>
            <Input type="email" value={guestData.email || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingSource">
              Booking Source
            </Label>
            <Input type="text" value={guestData.bookingSource || ""} readOnly />
          </FormGroup>
        </Col>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="bookingAmount">
              Booking Amount
            </Label>
            <Input
              type="number"
              value={guestData.bookingAmount || 0}
              readOnly
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <hr />
          <FormGroup className="w-100">
            <Label className="form-label" for="refundAmount">
              Refund Amount
            </Label>
            <Input
              type="number"
              value={refundAmount || ""}
              id="refundAmount"
              name="refundAmount"
              placeholder="Enter refund amount"
              onChange={handleRefundAmountChange}
              onWheel={(e) => e.target.blur()}
            />
            {refundAmount > guestData.bookingAmount && (
              <div style={{ color: "red" }}>
                Refund amount cannot be more than the booking amount!
              </div>
            )}
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reason">
              Reason*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="reason"
              name="reason"
              options={requestReasonOptions}
              value={reason}
              onChange={(value) => {
                setReason(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="comment">
              Comment*
            </Label>
            <Input
              type="textarea"
              value={comment}
              id="comment"
              name="comment"
              rows="1"
              placeholder="Add comment"
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="checkout">
              Check-out (Y/N)*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="checkout"
              name="checkout"
              options={checkoutOptions}
              value={checkout}
              onChange={(value) => {
                setCheckout(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <Label className="form-label" for="attachmentList">
            Upload Image*
          </Label>
          <RefudRequestAttachmentComponent
            imageList={attachmentList}
            setImageList={setAttachmentList}
            editData={dummyStateCaseMedia}
            uploadType={uploadAttachmentMedia}
          />
        </Col>

        <Col sm="12">
          <Label className="form-label" for="video">
            Upload Video* (Max file size: 10MB)
          </Label>
          <RefudRequestAttachmentComponent
            imageList={video}
            setImageList={setVideo}
            editData={dummyVideoState}
            uploadType={uploadVideoType}
          />
        </Col>
      </Row>
    </RefundRequestSlideModal>
  );
};
export default AddEditModal;
