import React from "react";
import { Card, CardHeader, CardTitle, Button, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit } from "react-feather";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const headers = [
  "Action",
  "Request Date",
  "Refund ID",
  "Hostel Name",
  "Reservation ID",
  "Guest Name",
  "Refund Amount",
  "Reason",
  "Check out",
  "Status",
  "Rejection Reason",
  "Initiated by",
];

const ReviewsTable = ({ openModal, setId, data, dataCount }) => {
  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Post-Stay Refunds - {dataCount} Requests</CardTitle>
          <Button.Ripple
            color="light"
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
          >
            Add Refund Request
          </Button.Ripple>
        </CardHeader>
      </Card>

      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table hover>
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <tr>
              {headers.map((headerItem) => (
                <th key={uuidv4()}>{headerItem}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id || index}>
                <td>
                  <Button
                    href="#"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      setId(item._id);
                      openModal();
                    }}
                  >
                    <Edit
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReviewsTable;
