import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { startLoading, stopLoading } from "@store/loader/loader.action";
import { POST_CREATE_LANDING_PAGE } from "./Landing/Mutation";
import {
  GET_HOSTEL_LIST,
  GET_TRIP_LIST,
  GET_DESTINATION_LIST,
  GET_BLOG_LIST,
  GET_HOSTELS_NAME_AND_ID,
  GET_ALL_HOSTEL_FOOD_CHARGES,
} from "./List/Queries";
import { USER_LOGIN, USER_OTP } from "./Login/Mutation";
import { useDispatch } from "react-redux";
import {
  POST_ADD_HOSTEL,
  POST_ADD_TRIP,
  POST_ADD_WORKATION,
  ADD_RECOMMENDATION,
  UPDATE_HOSTEL,
  UPDATE_TRIP,
  UPDATE_WORKATION,
  UPLOAD_HOSTEL_IMAGE,
  UPLOAD_TRIP_IMAGE,
  UPDATE_RECOMMENDATION,
  CREATE_AMENITIES,
  UPDATE_AMENITIES,
  DELETE_AMENITY,
  DELETE_HOSTEL,
  DELETE_TRIP,
  DELETE_WORKATION,
  SIGNED_URL,
  IMAGE_UPLOAD,
  HOSTEL_IMAGE,
  UPDATE_LANDINGPAGE_DETAILS,
  POST_ADD_DESTINATION, // ! createDestination added by Ashwin
  UPDATE_DESTINATION, // !updateDestination added by Ashwin
  UPDATE_HOMEPAGE_DETAILS, // ! updateHomePage added by Ashwin
  UPDATE_WORKATION_LANDINGPAGE_DETAILS, // ! updateWorkationLandingPage added by Ashwin
  CREATE_TRAVEL_PACKAGES,
  UPDATE_TRAVEL_PACKAGES,
  CREATE_HOMEPAGE,
  UPDATE_HOSTEL_FOOD_CHARGE,
} from "./Packages/Mutation";
import {
  GET_HOSTEL_PACKAGES,
  GET_TRIPS_PACKAGES,
  GET_WORKATION_PACKAGES,
  GET_RECOMMENDATION_BY_ID,
  GET_AMENITIES,
  GET_HOSTEL_BY_ID,
  GET_ALL_ROOMTYPE,
  GET_TRIP_BY_ID,
  GET_RECOMMENDATION_BY_PRODUCT_TYPE,
  GET_LANDINGPAGE_DETAILS,
  GET_DESTINATION_PACKAGES, //!useGetDestination added by Ashwin
  GET_DESTINATION_BY_ID, // ! useGetDestinationById added by Ashwin
  GET_HOMEPAGE_DETAILS, // ! useGetHomePageDetails added by Ashwin
  GET_WORKATION_LANDINGPAGE_DETAILS,
  GET_ALL_TRAVEL_PACKAGES,
  GET_PAGINATED_HOMEPAGE_LIST, // ! useGetWorkationLandingPageDetails added by Ashwin
} from "./Packages/Queries";
import { GET_SEO_BY_ID, GET_SEO_BY_TYPE } from "./Seo/Queries";
import { UPDATE_SEO } from "./Seo/Mutations";
import { GET_POLICIES_BY_TYPE } from "./Policies/Queries";
import { UPDATE_POLICIES } from "./Policies/Mutations";
import { GET_OFFERS_PAGE } from "./OffersPage/Queries";
import { UPDATE_OFFERS_PAGE } from "./OffersPage/Mutations";
import {
  GET_ALL_CANCEL_CONFIG,
  GET_CANCEL_CONFIG_BY_ID,
} from "./Cancellation/Queries";
import {
  CREATE_CANCEL_CONFIG,
  UPDATE_CANCEL_CONFIG,
} from "./Cancellation/Mutations";
import { GET_ALL_OFFER, GET_OFFER_BY_ID } from "./Offer/Queries";
import { CREATE_OFFER, UPDATE_OFFER } from "./Offer/Mutations";
import {
  GET_ALL_WORKATION_PACKAGES,
  GET_WORKATION_PACKAGE_BY_ID,
} from "./WorkationPackage/Queries";
import { UPDATE_WORKATION_PACKAGE } from "./WorkationPackage/Mutations";
import {
  GET_FEEDBACK,
  GET_ALL_FEEDBACK_LIST_FOR_EXPORT,
} from "./Feedback/Queries"; //added by Nitish
import { UPDATE_PARTNER } from "./Partner/Mutation";
import { GET_PARTNER } from "./Partner/Queries";
import { UPDATE_ABOUT } from "./About/Mutation";
import { GET_ABOUT } from "./About/Queries";
import { GET_FEEDBACK_BY_ID } from "./FeedbackForm/Queries";
import { UPDATE_FEEDBACK_RATING } from "./FeedbackForm/Mutation";
import {
  UPDATE_STATUS_REMARK,
  UPDATE_FEEDBACK_STATUS,
  UPDATE_FINAL_REMARK,
  UPDATE_FEEDBACK_TICKET,
} from "./FeedbackResponse/Mutation";
// ! Coded by Ashwin for Coupon
import {
  GET_COUPON_BY_ID,
  GET_PAGINATED_COUPON_LIST,
  GET_PAGINATED_COUPON_GENERATOR_LIST,
} from "./Coupons/Queries";
import {
  CREATE_COUPON,
  UPDATE_COUPON,
  UPDATE_COUPON_GENERATOR,
} from "./Coupons/Mutation";
// ! Coded by Ashwin for Staff
import {
  GET_PAGINATED_STAFF_LIST,
  GET_HOSTEL_NAMES_BY_STAFF_EMAIL,
} from "./Staffs/Queries";
import { CREATE_STAFF, UPDATE_STAFF, DELETE_STAFF } from "./Staffs/Mutation";

import {
  GET_UNPAID_BOOKINGS,
  GET_UNPAID_BOOKING_LIST,
  GET_ALL_UNPAID_BOOKINGS_LIST,
} from "./UnpaidReservation/Queries"; // ! Added by Ashwin for UnpaidReservation Table

import {
  GET_CHATBOT_BOOKING,
  GET_CHATBOT_BOOKINGS_FOR_EXPORT,
} from "./ChatbotBooking/Queries"; //? Added by Gaurav for Chatbot Bookings Table

import { GET_WHATSAPP_MESSAGES_REPORT } from "./WhatsappMessages/Queries";

import { GET_COUPON_APPLIED_BOOKINGS } from "./CouponBooking/Queries";
import { GET_PAGINATED_PARTNERNEW } from "./PartnerNew/Queries";
import { CREATE_PARTNERNEW, UPDATE_PARTNERNEW } from "./PartnerNew/Mutation";

import {
  CREATE_WHATSNEW,
  UPDATE_ALL_WHATSNEW,
  UPDATE_WHATSNEW,
} from "./WhatsNew/Mutation"; // ! Added by Ashwin
import { GET_PAGINATED_WHATSNEW } from "./WhatsNew/Queries"; // ! Added by Ashwin

import {
  CREATE_PRESS_MENTION,
  UPDATE_PRESS_MENTION,
} from "./PressMention/Mutation"; // ! Added by Ashwin
import { GET_PAGINATED_PRESS_MENTION } from "./PressMention/Queries"; // ! Added by Ashwin

import {
  GET_PAGINATED_ADMIN_USERS_LIST,
  GET_ALL_ADMIN_USERS_LIST,
  CHECK_ACCESS,
} from "./AdminUsers/Queries";
import {
  CREATE_ADMIN_USERS,
  UPDATE_ADMIN_USERS,
  RESET_ADMIN_USERS_PASSWORD,
  FORGET_PASSWORD,
  UPDATE_FIREBASE_TOKEN,
} from "./AdminUsers/Mutation";

import { GET_MEDIA_DETAILS } from "./Media/Queries"; // ! Added by Ashwin
import { UPDATE_MEDIA_DETAILS } from "./Media/Mutation"; // ! Added by Ashwin

import { GET_SALES_DATA_HOSTEL_WISE } from "./BookingReport/Queries";
import { GET_DAILY_BOOKINGS } from "./DailyBookings/Queries";
import { GET_BOOKING_BY_STAY_DATE } from "./StaydateBooking/Queries";
import {
  GET_ALL_FEEDBACK_STATUS_DATA,
  GET_HOSTELWISE_FEEDBACK_STATUS_DATA,
  GET_ALL_FINAL_STATUS_DATA,
  GET_HOSTEL_WISE_AVG_TAT,
  GET_TOTAL_CUSTOMER_FEEDBACK_STATUS_DATA,
  GET_TOTAL_BAD_REMARK_WORD_COUNT_AND_DATA,
  GET_CX_UPDATED_BY_AVG_TAT_AND_COUNT,
} from "./FeedbackReport/Queries";
import { GET_SOURCE_WISE_REPORT } from "./SourceReport/Queries";
import { GET_CONTRIBUTION_ANALYSIS_REPORT } from "./CarReport/Queries";

import {
  GET_CATEGORY_BLOGS,
  GET_BLOGS,
  GET_BLOG_BY_ID,
  GET_BLOG_CATEGORY_LIST,
  GET_SIMILAR_BLOGS_LIST,
} from "./Blogs/Queries";
import {
  POST_ADD_BLOG_Category,
  UPDATE_BLOG_CATEGORY,
  POST_ADD_BLOGS_BLOG,
  UPDATE_BLOGS_BLOG,
} from "./Blogs/Mutation";

import { GET_HOTELS_FOR_HOTEL_CENTER } from "./HotelCenter/Queries";
import { WEBSITE_HOURLY_SALES_REPORT } from "./HourlySales/Queries";
import { GET_EXTRA_CHARGES_REPORT } from "./ExtraChargesReport/Queries";

import {
  GET_PAGINATED_WEB_CHECKIN_LIST,
  GET_ALL_WEB_CHECKIN_LIST,
  GET_WEBCHECKIN_DATA_BY_RESERVATION_ID,
} from "./WebCheckin/Queries";
import {
  CREATE_WEBCHECKIN,
  GENERATE_WEBCHECKIN_EXCEL_DATA,
  GET_WEBCHECKIN_ANALYSIS_REPORT,
  UPDATE_WEBCHECKIN,
} from "./WebCheckin/Mutation";
import { GET_SOURCE_LIST } from "./List/Queries";
import { GET_PAGINATED_DISPLAY_ADS } from "./DisplayAds/Queries";
import { CREATE_DISPLAY_ADS, UPDATE_DISPLAY_ADS } from "./DisplayAds/Mutation";
import {
  GET_MESSAGE_TEMPLATE_LIST,
  GET_MESSAGE_TEMPLATE_BY_NAME,
  GET_COUNT_OF_MESSAGE_RECIEVER,
} from "./Communications/Queries";
import {
  POST_ADD_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE,
  POST_ADD_MESSAGE_CONFIGURATION,
  UPDATE_MESSAGE_CONFIGURATION,
} from "./Communications/Mutation";
import { GET_PAGINATED_MESSAGE_CONFIGURATION } from "./Communications/Queries";
import {
  GET_GUEST_DATABASE,
  GET_GUEST_DETAILS_WITH_SAME_NUMBER_OR_EMAIL,
} from "./GuestDatabase/Queries"; //Added by Nitish
import { GET_OCCUPANCY_TRACKER_REPORT } from "./OccupancyTracker/Queries";
import { GET_MEMBERSHIPS, GET_ALL_MEMBERSHIPS } from "./Memberships/Queries";
import {
  GET_PAGINATED_REQUESTS_LIST,
  GET_REQUEST_ID,
  GET_REQUEST_HOSTEL_ID,
  GET_REQUEST_NAME_LIST,
} from "./WebApp/Requests/Queries";
import {
  CREATE_AND_UPDATE_REQUEST,
  POST_ADD_WEB_APP_REQUEST,
  UPDATE_WEB_APP_REQUEST,
} from "./WebApp/Requests/Mutations";
import { GET_MESSAGETYPE_LIST } from "./List/Queries";
import { GET_ADD_ON_REPORT, GET_ADD_ON_LIST } from "./AddOn/Queries";
import {
  GET_PAGINATED_USER_REQUESTS_LIST,
  GET_ALL_USER_REQUESTS_LIST,
} from "./WebApp/UserRequest/Queries";
import {
  GENERATE_USER_REQUEST_EXPORT_DATA,
  UPDATE_WEB_APP_USER_REQUEST,
} from "./WebApp/UserRequest/Mutation";
import {
  GET_CONTACTLESS_CHECKOUT_LIST,
  GET_ALL_CONTACTLESS_CHECKOUT_DATA,
} from "./WebApp/ContactlessCheckout/Queries";
import { GET_GLU_BANNER } from "./WebApp/GluBanner/Queries";
import {
  CREATE_GLU_BANNER,
  DELETE_GLU_BANNER,
  UPDATE_GLU_BANNER,
} from "./WebApp/GluBanner/Mutation";
import {
  SEND_PAYMENT_LINK,
  UPDATE_EZEE_BOOKING_REMARKS,
} from "./UnpaidReservation/Mutation";
import {
  GET_EVENTS_AND_ACTIVITIES_LISt,
  GET_EVENTS_AND_ACTIVITIES_BY_ID,
} from "./WebApp/EventsAndActivities/Queries";
import {
  CREATE_EVENTS_AND_ACTIVITIES,
  UPDATE_EVENTS_AND_ACTIVITIES,
} from "./WebApp/EventsAndActivities/Mutation";
import {
  GET_PAGINATED_EVENT_BOOKINGS,
  GET_ALL_EVENT_BOOKINGS_FOR_EXPORT,
  GET_EVENTS_INOVOICE_ON_SUCCESS,
} from "./eventBookings/Queries";
import {
  GENERATE_EVENTS_EXPORT_DATA,
  UPDATE_EVENT_STATUS_OR_REMARK,
} from "./eventBookings/Mutation";
import { GET_PAGINATED_SERVICE_LIST } from "./WebApp/ServiceConfig/Queries";
import {
  CREATE_SERVICE,
  UPDATE_SERVICE,
} from "./WebApp/ServiceConfig/Mutation";
import {
  GET_PAGINATED_EMPLOYEE_PROFILE_LIST,
  GET_EMPLOYEE_PROFILE_BY_ID,
  GET_EMPLOYEE_PROFILE_BY_HOSTEL_ID,
  GET_EMPLOYEE_LEAVE_BALANCE_BY_HOSTEL_ID,
  GET_EMPLOYEE_LEAVE_BALANCE_BY_EMPLOYEE_ID,
  GET_EMPLOYEE_ATTENDANCE_BY_HOSTEL_ID,
  GET_HOSTELWISE_LEAVE_BALANCE,
  GET_EMPLOYEE_PAYSLIPS,
  GET_EMPLOYEE_PROFILE_LIST,
  GET_EMP_PROFILE_FOR_ATTENDANCE,
} from "./HR/Queries";
import {
  CREATE_EMPLOYEE_PROFILE,
  UPDATE_EMPLOYEE_PROFILE,
  CREATE_EMPLOYEE_ATTENDANCE,
  CREATE_EMPLOYEE_LEAVE_BALANCE,
  UPDATE_EMPLOYEE_LEAVE_BALANCE,
  UPDATE_EMPLOYEE_TRANSFER_DETAILS,
  SAVE_EMPLOYEE_PAYSLIP,
  SAVE_MULTIPLE_PAYSLIP,
  UPLOAD_XLSX_FILE_EMPLOYEE_PROFILE,
  UPLOAD_XLSX_FILE_EMPLOYEE_TRAINING_SCORE,
  UPLOAD_XLSX_FILE_EMPLOYEE_PERFORMANCE_EVAL,
  GET_XLSX_FORMAT_FOR_EMPLOYEE,
  UPLOAD_XLSX_FILE_EMPLOYEE_SALARY_STRUCTURE,
  GET_ATTENDANCE_DATA_EXPORT,
  UPLOAD_XLSX_EMPLOYEE_MONTHLY_VALUES,
  GET_PROCESSED_SALARIES_EXPORT,
  UPLOAD_BULK_EMPLOYEE_DOCUMENTS,
} from "./HR/Mutation";

import {
  GET_PAGINATED_ADDONS_BOOKINGS,
  GET_ALL_ADDONS_BOOKINGS,
  GET_SERVICE_INVOICE_ON_SUCCESS,
} from "./WebApp/AddonBookings/Queries";
import {
  GENERATE_SERVICE_EXPORT_DATA,
  UPDATE_ADDONS_BOOKING,
} from "./WebApp/AddonBookings/Mutation";
import { GET_REFUNDS } from "./Refund/Queries";
import { XLSX_UPLOAD } from "./FileUpload/Mutation";
import { UPDATE_REVIEWS_LINKS } from "./ORM/Mutation";
import { GET_STRIP_CONFIG } from "./stripconfig/Queries";
import {
  CREATE_STRIP_CONFIG,
  DELETE_STRIP_CONFIG,
  UPDATED_STRIP_CONFIG,
} from "./stripconfig/Mutation";
import {
  GET_AVG_RATING_OTA_SUMMARY,
  GET_CORPORATE_REPORT_DATA,
  GET_CX_REPUTATION_REPORT,
  GET_HOSTEL_WISE_DATA_FOR_CORPORATE_REPORT,
  GET_OTA_REVIEWS_REPORT,
  GET_OTA_SUMMARY,
  GET_OVERALL_AVERAGE_RATING,
  GET_PAGINATED_OTA_REVIEWS,
  GET_REVIEW_SENTIMENT_ANALYSIS,
  GET_ORM_TICKETS_REPORT,
  GET_AVG_RATING_OTA_REVIEWS,
  GET_NEGATIVE_KEY_CLASSIFICATION,
  GET_HOSTEL_REVIEW_SUMMARY,
} from "./OrmReviews/Queries";
import {
  GET_PAGINATED_STEP_OUT_CATEGORY_LIST,
  GET_STEP_OUT_CATEGORY_BY_ID,
  GET_STEP_OUT_CATEGORY_LIST,
} from "./StepOut/StepOutCategory/Queries";
import {
  CREATE_STEP_OUT_CATEGORY,
  UPDATE_STEP_OUT_CATEGORY,
} from "./StepOut/StepOutCategory/Mutation";
import {
  GET_PAGINATED_STEP_OUT_PRODUCT_LIST,
  GET_STEP_OUT_PRODUCT_BY_ID,
  GET_STEP_OUT_PRODUCT_LIST,
} from "./StepOut/StepOutProduct/Queries";
import {
  CREATE_STEP_OUT_PRODUCT,
  UPDATE_STEP_OUT_PRODUCT,
} from "./StepOut/StepOutProduct/Mutation";
import {
  CREATE_NEW_ORM_REVIEW,
  DELETE_ORM_REVIEW,
  GENERATE_ORM_EXPORT_DATA,
  LOG_GUEST_ACTION,
  SHIFT_REVIEW_TO_CANT_REPLY,
  UPDATE_AREA_MANAGER_INPUT,
  UPDATE_BASIC_GUEST_DETAILS,
  UPDATE_CX_DETAILS,
  UPDATE_CX_TICKET,
  UPDATE_FAKE_REVIEW,
  UPDATE_FINAL_CX_REMARK,
  UPDATE_GUEST_FEEDBACK_DETAILS,
  UPDATE_HOSTEL_FEEDBACK_DETAILS,
} from "./OrmReviews/Mutation";

import { UPDATE_STEP_OUT_HOMEPAGE_DETAILS } from "./StepOut/StepOutHomepage/Mutation";
import { GET_STEP_OUT_HOMEPAGE_DETAILS } from "./StepOut/StepOutHomepage/Queries";
import { GET_WEBSITE_BOOKING } from "./WebsiteBooking/Queries";
import { GET_WEBSITE_BOOKING_EXPORT } from "./WebsiteBooking/Mutation";

import { GET_STEP_OUT_POLICIES_BY_TYPE } from "./StepOut/StepOutPolicies/Queries";
import { UPDATE_STEP_OUT_POLICIES } from "./StepOut/StepOutPolicies/Mutation";

import { GET_PAGINATED_STEP_OUT_CONTACT_US_LIST } from "./StepOut/StepOutContactUs/Queries";

import {
  GET_STEP_OUT_COUPON_BY_ID,
  GET_PAGINATED_STEP_OUT_COUPON_LIST,
} from "./StepOut/StepOutCoupons/Queries";

import {
  CREATE_STEP_OUT_COUPON,
  UPDATE_STEP_OUT_COUPON,
} from "./StepOut/StepOutCoupons/Mutation";

import {
  GET_ALL_REPLY_TEMPLATE,
  GET_PAGINATED_REPLY_TEMPLATE,
} from "./ReplyTemplate/Queries";
import {
  CREATE_REPLY_TEMPLATE,
  UPDATE_REPLY_TEMPLATE,
} from "./ReplyTemplate/Mutation";

import {
  GET_PAGINATED_STEP_OUT_LOCATION_LIST,
  GET_STEP_OUT_LOCATION_BY_ID,
  GET_STEP_OUT_LOCATION_LIST,
} from "./StepOut/StepOutLocation/Queries";
import {
  CREATE_STEP_OUT_LOCATION,
  UPDATE_STEP_OUT_LOCATION,
} from "./StepOut/StepOutLocation/Mutation";
import { GET_PAGINATED_MEMBERSHIPS_LIST } from "./Memberships/Queries";
import {
  GET_LOYALTY_REPORT,
  GET_PAGINATED_LOYALTY_COINS,
} from "./LoyaltyReport/Queries";
import {
  CREATE_LOYALTY_COINS,
  UPDATE_LOYALTY_COINS,
} from "./LoyaltyReport/Mutation";

import {
  GET_PAGINATED_USER_LIST,
  GET_USER_BY_ID,
  GET_PAGINATED_USERS_BOOKINGS_LIST,
} from "./User/Queries";
import { CREATE_USER, UPDATE_USER } from "./User/Mutation";
import {
  GET_PAGINATED_STEP_OUT_BLOG_CATEGORY_LIST,
  GET_STEP_OUT_BLOG_CATEGORY_LIST,
} from "./StepOut/StepOutBlogCategory/Queries";
import {
  ADD_STEP_OUT_BLOG_CATEGORY_DATA,
  UPDATE_STEP_OUT_BLOG_CATEGORY_DATA,
} from "./StepOut/StepOutBlogCategory/Mutation";
import {
  GET_ALL_STEPOUT_BLOGS,
  GET_PAGINATED_STEPOUT_BLOG,
  GET_STEPOUT_BLOG_BY_ID,
} from "./StepOut/StepOutBlog/Queries";
import {
  CREATE_STEPOUT_BLOG,
  UPDATE_STEPOUT_BLOG,
} from "./StepOut/StepOutBlog/Mutation";

import {
  GET_PAGINATED_WALLET,
  GET_ALL_WALLET,
  GET_WALLET_BALANCE_REPORT,
  GET_WALLET_BALANCE_REPORT_LIST,
} from "./Wallet/Queries";
import { CREATE_WALLET, UPDATE_WALLET } from "./Wallet/Mutation";
import { GET_PAGINATED_STEPOUT_USERS } from "./StepOut/StepOutUsers/Queries";
import { GET_ALL_WALL_OF_LOVE_IMAGES } from "./StepOut/StepOutWallOfLove/Queries";
import {
  CREATE_WALL_OF_LOVE_IMAGE,
  DELETE_STEP_OUT_WALL_OF_LOVE,
  UPDATE_WALL_OF_LOVE_IMAGE,
} from "./StepOut/StepOutWallOfLove/Mutation";

import { GET_STEP_OUT_PAGINATED_BOOKING } from "./StepOut/StepOutBooking/Queries";
import {
  CREATE_STEP_OUT_BOOKING,
  UPDATE_STEP_OUT_BOOKING,
} from "./StepOut/StepOutBooking/Mutation";

import {
  GET_PAGINATED_OPS_FINANCE_LIST,
  GET_OPS_FINANCE_REPORT,
  GET_ALL_DAY_BOOK_DATA_FOR_EXPORT,
  GET_HOSTEL_WISE_CLOSING_BALANCE,
} from "./OpsFinance/Queries";
import {
  CREATE_OPS_FINANCE,
  UPDATE_OPS_FINANCE,
  UPDATE_INVOICE_PENDING_CLARIFICATION,
  GENERATE_DAY_BOOK_EXPORT_DATA,
} from "./OpsFinance/Mutation";
import {
  ADD_COMMENT_ON_POST,
  ADD_REPLY_ON_COMMENT,
  DELETE_CONNECT_COMMENT,
  DELETE_CONNECT_POST,
  START_CONVERSATION,
} from "./Connect/Mutation";
import {
  GET_ALL_POSTS,
  GET_ALL_POST_REPLY,
  GET_ALL_REPLY,
  GET_ALL_USER_LIST_TO_TAG,
  GET_POST_DETAIL,
} from "./Connect/Queries";
import { GET_ALL_SEARCHES_IN_DATE_RANGE } from "./SearchReport/Mutation";
import { GENERATE_CHATBOT_EXCEL } from "./ChatbotBooking/Mutation";
import { GET_APPLIED_COUPON_LIST_EXPORT } from "./CouponBooking/Queries";
import { GET_EZEE_MEAL_REPORT } from "./Ezee/EzeeMeals/Queries";
import { UPDATE_EZEE_MEALS } from "./Ezee/EzeeMeals/Mutation";
import {
  GET_COMPETITORS_BY_FILTER,
  GET_DATE_WISE_OCCUPANCY,
  GET_HOURLY_REPORT_OTA,
  GET_LATEST_LAST_UPDATED_AT,
  GET_MAX_DATE_FOR_DATA,
  GET_ROOM_NAMES,
} from "./CompetitorRates/Queries"; //Added by Ashish
import {
  _GET_GUEST_DATABASE,
  GET_BOOKING_CALENDER,
  GET_ROOM_TYPES,
  GET_WEB_CHECKIN_DATA_BY_RESERVATION_ID,
} from "./BookingDashboard/Queries";
import { CHECKIN, CHECKOUT, ROOM_ASSIGN } from "./BookingDashboard/Mutations";
import { GET_ALL_COMPETITORS_NAME } from "./HostelComparisons/Queries";
import { DELETE_HOSTEL_COMPARISON } from "./HostelComparisons/Mutation";
import {
  GET_SERVICE_CATEGORY_WISE_REVENUE,
  GET_SERVICE_EVENTS_SALES_REPORT,
  GET_SERVICE_TITLE_WISE_REVENUE,
} from "./WebApp/HostelWiseRevenue/Queries";

export const useGetPartner = () => {
  const { loading, error, data, refetch } = useQuery(GET_PARTNER);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAbout = () => {
  const { loading, error, data, refetch } = useQuery(GET_ABOUT);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdatePartner = () => {
  const [updatePartner, { loading, error, data, reset }] =
    useMutation(UPDATE_PARTNER);

  useLoadingHandler(loading);

  const updatePartnerHandler = (PartnerInput) => {
    updatePartner({
      variables: { PartnerInput },
    });
  };

  return [updatePartnerHandler, { loading, error, data, reset }];
};

export const useUpdateAbout = () => {
  const [updateAbout, { loading, error, data, reset }] =
    useMutation(UPDATE_ABOUT);

  useLoadingHandler(loading);

  const updateAboutHandler = (AboutInput) => {
    updateAbout({
      variables: { AboutInput },
    });
  };

  return [updateAboutHandler, { loading, error, data, reset }];
};

export const useGetAllWorkationPackages = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_WORKATION_PACKAGES,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetWorkationPackageById = (id) => {
  const { loading, error, data } = useQuery(GET_WORKATION_PACKAGE_BY_ID, {
    variables: { id },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useUpdateWorkationPackage = () => {
  const [updateWorkationPackage, { loading, error, data, reset }] = useMutation(
    UPDATE_WORKATION_PACKAGE
  );

  useLoadingHandler(loading);

  const updateWorkationPackageHandler = (id, workationPackageUpdate) => {
    updateWorkationPackage({
      variables: { id, workationPackageUpdate },
    });
  };

  return [updateWorkationPackageHandler, { loading, error, data, reset }];
};

export const useUpdateOffersPage = () => {
  const [updateOffersPage, { loading, error, data, reset }] =
    useMutation(UPDATE_OFFERS_PAGE);

  useLoadingHandler(loading);

  const updateOffersPageHandler = (OffersPageUpdate) => {
    updateOffersPage({
      variables: { OffersPageUpdate },
    });
  };

  return [updateOffersPageHandler, { loading, error, data, reset }];
};

export const useGetOffersPage = (type) => {
  const { loading, error, data } = useQuery(GET_OFFERS_PAGE, {
    variables: { type },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useUpdatePolicy = () => {
  const [updatePolicy, { loading, error, data, reset }] =
    useMutation(UPDATE_POLICIES);

  useLoadingHandler(loading);

  const updatePolicyHandler = (type, PolicyUpdate) => {
    updatePolicy({
      variables: { type, PolicyUpdate },
    });
  };

  return [updatePolicyHandler, { loading, error, data, reset }];
};

export const useGetPolicies = (type) => {
  const { loading, error, data } = useQuery(GET_POLICIES_BY_TYPE, {
    variables: { type },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useLoadingHandler = (loading) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loading ? dispatch(startLoading()) : dispatch(stopLoading());
  }, [dispatch, loading]);
};

export const useLoginUser = () => {
  const [loginUser, { loading, error, data, refetch }] =
    useMutation(USER_LOGIN);

  useLoadingHandler(loading);

  const loginUserHandler = (email, password, otp) => {
    loginUser({
      variables: {
        email,
        password,
        otp,
      },
    });
  };

  return [loginUserHandler, { loading, error, data, refetch }];
};

export const useOtpUser = () => {
  const [otpUser, { loading, error, data, refetch }] = useMutation(USER_OTP);

  useLoadingHandler(loading);

  const otpUserHandler = (email, password) => {
    otpUser({
      variables: {
        email,
        password,
      },
    });
  };

  return [otpUserHandler, { loading, error, data, refetch }];
};

export const useUpdateLandingPageDetails = () => {
  const [updateLandingPageDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_LANDINGPAGE_DETAILS);

  useLoadingHandler(loading);

  const updateLandingPageDetailsHandler = (slug, landingPageUpdate) => {
    updateLandingPageDetails({
      variables: { slug, landingPageUpdate },
    });
  };

  return [updateLandingPageDetailsHandler, { loading, error, data, reset }];
};

export const useGetLandingPageDetails = (slug) => {
  const { loading, error, data } = useQuery(GET_LANDINGPAGE_DETAILS, {
    variables: { slug },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useHostelList = () => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useTripList = () => {
  const { loading, error, data, refetch } = useQuery(GET_TRIP_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetTrip = (hostelId, limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_TRIPS_PACKAGES, {
    variables: {
      filter: {
        hostelId,
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetHostel = (limit, page, filter) => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_PACKAGES, {
    variables: {
      filter: {
        limit,
        page,
        ...filter,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
export const useGetWorkation = (hostelId, limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_WORKATION_PACKAGES, {
    variables: {
      filter: {
        hostelId,
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useAddWorkation = () => {
  const [addWorkation, { loading, error, data, refetch }] =
    useMutation(POST_ADD_WORKATION);

  useLoadingHandler(loading);

  const addWorkationHandler = (workationInput) => {
    addWorkation({
      variables: { workationInput },
    });
  };

  return [addWorkationHandler, { loading, error, data, refetch }];
};

export const useAddHostel = () => {
  const [addHostel, { loading, error, data, refetch }] =
    useMutation(POST_ADD_HOSTEL);

  useLoadingHandler(loading);

  const addHostelHandler = (hostelInput) => {
    addHostel({
      variables: { hostelInput },
    });
  };

  return [addHostelHandler, { loading, error, data, refetch }];
};

export const useAddTrip = () => {
  const [addTrip, { loading, error, data, refetch }] =
    useMutation(POST_ADD_TRIP);

  useLoadingHandler(loading);

  const addTripHandler = (tripInput) => {
    addTrip({
      variables: { tripInput },
    });
  };

  return [addTripHandler, { loading, error, data, refetch }];
};

// ! Create destination added by Ashwin
export const useAddDestination = () => {
  const [addDestination, { loading, error, data, refetch }] =
    useMutation(POST_ADD_DESTINATION);

  useLoadingHandler(loading);

  const addDestinationHandler = (destinationInput) => {
    addDestination({
      variables: { destinationInput },
    });
  };

  return [addDestinationHandler, { loading, error, data, refetch }];
};

// ! getPaginatedDestination added by Ashwin
export const useGetDestination = (limit, page, filter) => {
  const { loading, error, data, refetch } = useQuery(GET_DESTINATION_PACKAGES, {
    variables: {
      filter: {
        limit,
        page,
        ...filter,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! getDestinationById added by Ashwin
export const useGetDestinationById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_DESTINATION_BY_ID, {
    variables: {
      destinationId: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! updateDestination added by Ashwin
export const useUpdateDestination = () => {
  const [updateDestination, { loading, error, data, reset }] =
    useMutation(UPDATE_DESTINATION);

  useLoadingHandler(loading);

  const updateDestinationHandler = (id, destinationUpdate) => {
    updateDestination({
      variables: { id, destinationUpdate },
    });
  };

  return [updateDestinationHandler, { loading, error, data, reset }];
};

export const useAddLandingPage = () => {
  const [addLandingPage, { loading, error, data, refetch }] = useMutation(
    POST_CREATE_LANDING_PAGE
  );

  useLoadingHandler(loading);

  const addLandingPageHandler = (LandingPageInput) => {
    addLandingPage({
      variables: { LandingPageInput },
    });
  };

  return [addLandingPageHandler, { loading, error, data, refetch }];
};

export const useUpdateHostel = () => {
  const [updateHostel, { loading, error, data, reset }] =
    useMutation(UPDATE_HOSTEL);

  useLoadingHandler(loading);

  const updateHostelHandler = (id, hostelUpdate) => {
    updateHostel({
      variables: { id, hostelUpdate },
    });
  };

  return [updateHostelHandler, { loading, error, data, reset }];
};

export const useUpdateTrip = () => {
  const [updateTrip, { loading, error, data, reset }] =
    useMutation(UPDATE_TRIP);

  useLoadingHandler(loading);

  const updateTripHandler = (id, tripUpdate) => {
    updateTrip({
      variables: { id, tripUpdate },
    });
  };

  return [updateTripHandler, { loading, error, data, reset }];
};

export const useUpdateWorkation = () => {
  const [updateWorkation, { loading, error, data, refetch }] =
    useMutation(UPDATE_WORKATION);

  useLoadingHandler(loading);

  const updateWorkationHandler = (id, workationUpdate) => {
    updateWorkation({
      variables: { id, workationUpdate },
    });
  };

  return [updateWorkationHandler, { loading, error, data, refetch }];
};

export const useUploadTripImage = () => {
  const [uploadImage, { loading, error, data, reset }] =
    useMutation(UPLOAD_TRIP_IMAGE);

  useLoadingHandler(loading);

  const uploadImageHandler = (files) => {
    uploadImage({
      variables: { files },
    });
  };

  return [uploadImageHandler, { loading, error, data, reset }];
};

export const useUploadHostelImage = () => {
  const [uploadImage, { loading, error, data, refetch }] =
    useMutation(UPLOAD_HOSTEL_IMAGE);

  useLoadingHandler(loading);

  const uploadImageHandler = (files, type, hostelId) => {
    uploadImage({
      variables: { files, type, hostelId },
    });
  };

  return [uploadImageHandler, { loading, error, data, refetch }];
};

export const useAddRecommendations = () => {
  const [recommendation, { loading, error, data, refetch }] =
    useMutation(ADD_RECOMMENDATION);

  useLoadingHandler(loading);

  const addRecommendationHandler = (recommendationInput) => {
    recommendation({
      variables: {
        recommendationInput,
      },
    });
  };

  return [addRecommendationHandler, { loading, error, data, refetch }];
};

export const useGetRecommendationById = (id, productType) => {
  const { loading, error, data, refetch } = useQuery(GET_RECOMMENDATION_BY_ID, {
    variables: {
      id,
      productType,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetRecommendationByProductType = (productType) => {
  const { loading, error, data } = useQuery(
    GET_RECOMMENDATION_BY_PRODUCT_TYPE,
    { variables: { productType } }
  );

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useGetTripById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_TRIP_BY_ID, {
    variables: { id },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateRecommendation = () => {
  const [updateRecommendation, { loading, error, data, refetch }] = useMutation(
    UPDATE_RECOMMENDATION
  );

  useLoadingHandler(loading);

  const updateRecommendationHandler = (
    id,
    siteType,
    productType,
    recommendationUpdate
  ) => {
    updateRecommendation({
      variables: {
        id,
        siteType,
        productType,
        recommendationUpdate,
      },
    });
  };
  return [updateRecommendationHandler, { loading, error, data, refetch }];
};

export const useAllAmenities = (limit, page, name) => {
  const { loading, error, data, refetch } = useQuery(GET_AMENITIES, {
    variables: {
      filter: {
        limit,
        page,
        name,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useDeleteHostel = () => {
  const [deleteHostelById, { loading, error, data }] =
    useMutation(DELETE_HOSTEL);

  useLoadingHandler(loading);

  const deleteHostelByIdHandler = (id) => {
    deleteHostelById({
      variables: {
        id,
      },
    });
  };

  return [deleteHostelByIdHandler, { loading, error, data }];
};

export const useDeleteTrip = () => {
  const [deleteTripById, { loading, error, data }] = useMutation(DELETE_TRIP);

  useLoadingHandler(loading);

  const deleteTripByIdHandler = (id) => {
    deleteTripById({
      variables: {
        id,
      },
    });
  };

  return [deleteTripByIdHandler, { loading, error, data }];
};

export const useDeleteWorkation = () => {
  const [deleteWorkationById, { loading, error, data }] =
    useMutation(DELETE_WORKATION);

  useLoadingHandler(loading);

  const deleteWorkationByIdHandler = (id) => {
    deleteWorkationById({
      variables: {
        id,
      },
    });
  };

  return [deleteWorkationByIdHandler, { loading, error, data }];
};

export const useDeleteAmenity = () => {
  const [deleteAmenity, { loading, error, data, refetch }] =
    useMutation(DELETE_AMENITY);

  useLoadingHandler(loading);

  const deleteAmenityHandler = (id) => {
    deleteAmenity({
      variables: {
        id,
      },
    });
  };

  return [deleteAmenityHandler, { loading, error, data, refetch }];
};

export const useUpdateAmenity = () => {
  const [updateAmenity, { loading, error, data, refetch }] =
    useMutation(UPDATE_AMENITIES);

  useLoadingHandler(loading);

  const updateAmenityHandler = (id, aminityUpdate) => {
    updateAmenity({
      variables: {
        id,
        aminityUpdate,
      },
    });
  };

  return [updateAmenityHandler, { loading, error, data, refetch }];
};

export const useCreateAmenities = () => {
  const [createAmenities, { loading, error, data, refetch }] =
    useMutation(CREATE_AMENITIES);

  useLoadingHandler(loading);

  const createAmenitiesHandler = (body) => {
    createAmenities({
      variables: {
        body,
      },
    });
  };

  return [createAmenitiesHandler, { loading, error, data, refetch }];
};

export const useSignedUrl = () => {
  const [signedUrl, { loading, error, data, refetch }] =
    useMutation(SIGNED_URL);

  useLoadingHandler(loading);

  const signedUrlHandler = (fileName) => {
    signedUrl({
      variables: {
        fileName,
      },
    });
  };
  return [signedUrlHandler, { loading, error, data, refetch }];
};

export const useUploadImage = () => {
  const [uploadImage, { loading, error, data, reset }] = useMutation(
    IMAGE_UPLOAD,
    {
      onCompleted(data) {
        return data;
      },
    }
  );

  useLoadingHandler(loading);

  const uploadImageHandler = async (file, keyname) => {
    return await uploadImage({
      variables: {
        file,
        keyname,
      },
    });
  };

  return [uploadImageHandler, { loading, error, data, reset }];
};

export const useHostelImage = () => {
  const [hostelImage, { loading, error, data, refetch }] =
    useMutation(HOSTEL_IMAGE);

  useLoadingHandler(loading);

  const hostelImageHandler = (id, imageData) => {
    hostelImage({
      variables: { id, imageData },
    });
  };

  return [hostelImageHandler, { loading, error, data, refetch }];
};

export const useGetHostelById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_BY_ID, {
    variables: {
      hostelId: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllRooms = (hostelId, skip) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_ROOMTYPE, {
    variables: {
      hostelId: hostelId,
    },
    skip,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useSeoById = (productType, limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_SEO_BY_ID, {
    variables: {
      filter: {
        productType,
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useSeoByType = (productType, limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_SEO_BY_TYPE, {
    variables: {
      filter: {
        productType,
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateSeo = (productType, limit, page) => {
  const [updateSeo, { loading, error, data, refetch }] = useMutation(
    UPDATE_SEO,
    {
      variables: {
        filter: {
          productType,
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  const updteSeoHandler = (id, body) => {
    updateSeo({
      variables: { id, body },
    });
  };

  return [updteSeoHandler, { loading, error, data, refetch }];
};

//==> Cancellation

export const useAddCancelConfig = () => {
  const [createCancelConfig, { loading, error, data, refetch }] =
    useMutation(CREATE_CANCEL_CONFIG);

  useLoadingHandler(loading);

  const addCancelConfigHandler = (CancelConfigInput) => {
    createCancelConfig({
      variables: { CancelConfigInput },
    });
  };

  return [addCancelConfigHandler, { loading, error, data, refetch }];
};

export const useUpdateCancelConfig = () => {
  const [updateCancelConfig, { loading, error, data, refetch }] =
    useMutation(UPDATE_CANCEL_CONFIG);

  useLoadingHandler(loading);

  const updateCancelConfigHandler = (id, CancelConfigUpdate) => {
    updateCancelConfig({
      variables: { id, CancelConfigUpdate },
    });
  };

  return [updateCancelConfigHandler, { loading, error, data, refetch }];
};

export const useCancelConfigById = (id) => {
  const { loading, error, data, refetch } = useMutation(
    GET_CANCEL_CONFIG_BY_ID,
    {
      variables: {
        id: id,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllCancelConfig = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_CANCEL_CONFIG, {
    variables: {
      filter: {
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//==>> Offer

export const useAddOffer = () => {
  const [createOffer, { loading, error, data, refetch }] =
    useMutation(CREATE_OFFER);

  useLoadingHandler(loading);

  const addOfferHandler = (OfferInput) => {
    createOffer({
      variables: { OfferInput },
    });
  };

  return [addOfferHandler, { loading, error, data, refetch }];
};

export const useUpdateOffer = () => {
  const [updateOffer, { loading, error, data, refetch }] =
    useMutation(UPDATE_OFFER);

  useLoadingHandler(loading);

  const updateOfferHandler = (id, OfferUpdate) => {
    updateOffer({
      variables: { id, OfferUpdate },
    });
  };

  return [updateOfferHandler, { loading, error, data, refetch }];
};

export const useOfferById = (id) => {
  const { loading, error, data, refetch } = useMutation(GET_OFFER_BY_ID, {
    variables: {
      id: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllOffer = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_OFFER, {
    variables: {
      filter: {
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// export const useAllFeedback = (
//   limit,
//   page,
//   hostelName,
//   customerFeedback,
//   feedbackStatus,
//   finalStatus,
//   searchKeyword,
//   fromDate,
//   toDate
// ) => {
//   const { loading, error, data, refetch } = useQuery(GET_FEEDBACK, {
//     variables: {
//       filter: {
//         limit,
//         page,
//         hostelName,
//         customerFeedback,
//         feedbackStatus,
//         finalStatus,
//         searchKeyword,
//         fromDate,
//         toDate,
//       },
//     },
//   });

//   useLoadingHandler(loading);

//   return { loading, error, data, refetch };
// };

export const useAllFeedback = (filter) => {
  const { loading, error, data, refetch } = useQuery(GET_FEEDBACK, {
    variables: {
      filter,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//==>> Coupon

//! Coded by Ashwin

export const useGetCouponById = (
  name,
  status,
  promoCode,
  product,
  subCategory,
  discount,
  expirationDate,
  maxDiscountValue,
  duration,
  quantity,
  numberOfUsage,
  minBookingAmount,
  minimumNights,
  minimumAdults
) => {
  const { loading, error, data, refetch } = useQuery(GET_COUPON_BY_ID, {
    variables: {
      name,
      status,
      promoCode,
      product,
      subCategory,
      discount,
      expirationDate,
      maxDiscountValue,
      duration,
      quantity,
      numberOfUsage,
      minBookingAmount,
      minimumNights,
      minimumAdults,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateCoupon = () => {
  const [createCoupon, { loading, error, data, refetch }] =
    useMutation(CREATE_COUPON);

  useLoadingHandler(loading);

  const createCouponsHandler = (couponInput) => {
    console.log(couponInput);
    createCoupon({
      variables: {
        couponInput,
      },
    });
  };

  return [createCouponsHandler, { loading, error, data, refetch }];
};

export const useUpdateCoupon = () => {
  const [updateCoupon, { loading, error, data, reset }] =
    useMutation(UPDATE_COUPON);

  useLoadingHandler(loading);

  const updateCouponHandler = (id, couponUpdate) => {
    updateCoupon({
      variables: { id, couponUpdate },
    });
  };

  return [updateCouponHandler, { loading, error, data, reset }];
};

export const useUpdateCouponGenerator = () => {
  const [updateCoupon, { loading, error, data, reset }] = useMutation(
    UPDATE_COUPON_GENERATOR
  );

  useLoadingHandler(loading);

  const updateCouponGeneratorHandler = (couponUpdate) => {
    updateCoupon({
      variables: { couponUpdate },
    });
  };

  return [updateCouponGeneratorHandler, { loading, error, data, reset }];
};

// ! Coded by Ashwin
export const useGetAllPaginatedCoupons = (
  limit,
  page,
  status,
  searchKeyword,
  privateStatus,
  product,
  subCategory,
  expirationStartDate,
  expirationEndDate
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_COUPON_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          status: status,
          searchKeyword: searchKeyword,
          private: privateStatus,
          product,
          subCategory,
          expirationStartDate,
          expirationEndDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Added by Ashwin
export const useGetAllPaginatedCouponsGenerator = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_COUPON_GENERATOR_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// export const useGetAllCoupons = (coupons) => {
//   const { loading, error, data, refetch } = useQuery(GET_COUPON_LIST, {
//     variables: {
//       coupons,
//     },
//   });

//   useLoadingHandler(loading);

//   return { loading, error, data, refetch };
// };

//==>> Staff

export const useCreateStaff = () => {
  const [createStaff, { loading, error, data, refetch }] =
    useMutation(CREATE_STAFF);

  useLoadingHandler(loading);

  const createStaffsHandler = (staffInput) => {
    createStaff({
      variables: {
        staffInput,
      },
    });
  };

  return [createStaffsHandler, { loading, error, data, refetch }];
};

export const useUpdateStaff = () => {
  const [updateStaff, { loading, error, data, reset }] =
    useMutation(UPDATE_STAFF);

  useLoadingHandler(loading);

  const updateStaffHandler = (id, staffUpdate) => {
    updateStaff({
      variables: { id, staffUpdate },
    });
  };

  return [updateStaffHandler, { loading, error, data, reset }];
};

export const useDeleteStaff = () => {
  const [deleteStaff, { loading, error, data, refetch }] =
    useMutation(DELETE_STAFF);

  useLoadingHandler(loading);

  const deleteStaffHandler = (id) => {
    deleteStaff({
      variables: {
        id,
      },
    });
  };

  return [deleteStaffHandler, { loading, error, data, refetch }];
};

export const useGetAllPaginatedStaffs = (limit, page, hostelName) => {
  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_STAFF_LIST, {
    variables: {
      filter: {
        limit,
        page,
        hostelName,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
export const useGetFeedbackByReservationId = (reservationId) => {
  const { loading, error, data, refetch } = useQuery(GET_FEEDBACK_BY_ID, {
    variables: {
      reservationId: reservationId,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateFeedbackRating = () => {
  const [updateFeedbackRating, { loading, error, data, reset }] = useMutation(
    UPDATE_FEEDBACK_RATING
  );

  useLoadingHandler(loading);

  const updateFeedbackRatingHandler = ({
    reservationId,
    feedbackRatingDetails: { customerFeedback, customerRemark },
    feedbackTime,
  }) => {
    updateFeedbackRating({
      variables: {
        reservationId,
        feedbackRatingDetails: { customerFeedback, customerRemark },
        feedbackTime,
      },
    });
  };

  return [updateFeedbackRatingHandler, { loading, error, data, reset }];
};

export const useUpdateStatusRemark = () => {
  const [updateStatusRemark, { loading, error, data, reset }] =
    useMutation(UPDATE_STATUS_REMARK);

  useLoadingHandler(loading);

  const updateStatusRemarkHandler = (_id, statusRemarkDetails) => {
    console.log(statusRemarkDetails, "statusRemarkDetails");
    updateStatusRemark({
      variables: {
        _id,
        statusRemarkDetails: {
          feedbackStatus: statusRemarkDetails?.feedbackStatus,
          propertyRemark: statusRemarkDetails?.propertyRemark,
        },
      },
    });
  };

  return [updateStatusRemarkHandler, { loading, error, data, reset }];
};

// ! Coded by Ashwin
export const useUpdateFeedbackStatus = () => {
  const [updateFeedbackStatus, { loading, error, data, reset }] = useMutation(
    UPDATE_FEEDBACK_STATUS
  );

  useLoadingHandler(loading);

  const updateFeedbackStatusHandler = (
    reservationId,
    customerFeedbackStatus
  ) => {
    const feedbackStatus = customerFeedbackStatus.feedbackStatus;

    updateFeedbackStatus({
      variables: {
        reservationId,
        feedbackStatus,
      },
    });
  };

  return [updateFeedbackStatusHandler, { loading, error, data, reset }];
};

// ! Coded by Ashwin
export const useGetUnpaidBookings = (
  Source,
  ReservationNo,
  GuestName,
  ArrivalDate,
  DepartureDate,
  ReservationDate,
  Mobile,
  Address,
  DueAmount,
  hostelName
) => {
  const { loading, error, data, refetch } = useQuery(GET_UNPAID_BOOKINGS, {
    variables: {
      Source,
      ReservationNo,
      GuestName,
      ArrivalDate,
      DepartureDate,
      ReservationDate,
      Mobile,
      Address,
      DueAmount,
      hostelName,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Added by Ashwin
export const useGetUnpaidBookingsList = (
  limit,
  page,
  hostelIds,
  paymentStatus,
  checkinStartDate,
  checkinEndDate
) => {
  const { loading, error, data, refetch } = useQuery(GET_UNPAID_BOOKING_LIST, {
    variables: {
      filter: {
        limit,
        page,
        hostelIds,
        paymentStatus,
        checkinStartDate,
        checkinEndDate,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! updateHomePage added by Ashwin
export const useUpdateHomePageDetails = () => {
  const [updateHomePageDetails, { loading, error, data, reset }] = useMutation(
    UPDATE_HOMEPAGE_DETAILS
  );

  useLoadingHandler(loading);

  const updateHomePageDetailsHandler = (id, homePageUpdate) => {
    updateHomePageDetails({
      variables: { id, homePageUpdate },
    });
  };

  return [updateHomePageDetailsHandler, { loading, error, data, reset }];
};

// !getHomePageDetails added by Ashwin
export const useGetHomePageDetails = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_HOMEPAGE_DETAILS, {
    variables: { id },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// !getDestinationList added by Ashwin
export const useDestinationList = () => {
  const { loading, error, data, refetch } = useQuery(GET_DESTINATION_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// ! updateWorkationLandingPage added by Ashwin
export const useUpdateWorkationLandingPageDetails = () => {
  const [updateWorkationLandingPageDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_WORKATION_LANDINGPAGE_DETAILS);

  useLoadingHandler(loading);

  const updateWorkationLandingPageDetailsHandler = (
    slug,
    workationLandingPageUpdate
  ) => {
    updateWorkationLandingPageDetails({
      variables: { slug, workationLandingPageUpdate },
    });
  };

  return [
    updateWorkationLandingPageDetailsHandler,
    { loading, error, data, reset },
  ];
};

// !getWorkationLandingPageDetails added by Ashwin
export const useGetWorkationLandingPageDetails = (slug) => {
  const { loading, error, data } = useQuery(GET_WORKATION_LANDINGPAGE_DETAILS, {
    variables: { slug },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

// Added by Nitish
export const useUpdateFinalRemark = () => {
  const [updateFinalRemark, { loading, error, data, reset }] =
    useMutation(UPDATE_FINAL_REMARK);

  useLoadingHandler(loading);

  const updateFinalRemarkHandler = (_id, finalRemarkDetails) => {
    const updatedBy = localStorage.getItem("email");

    updateFinalRemark({
      variables: {
        _id,
        finalRemarkDetails: {
          finalStatus: finalRemarkDetails.finalStatus,
          finalRemark: finalRemarkDetails.finalRemark,
          updatedBy: updatedBy,
        },
      },
    });
  };

  return [updateFinalRemarkHandler, { loading, error, data, reset }];
};

//?Added By Gaurav
export const useGetChatbotBooking = (
  limit,
  page,
  fromDate,
  toDate,
  bookingSource
) => {
  const { loading, error, data, refetch } = useQuery(GET_CHATBOT_BOOKING, {
    variables: {
      filter: {
        limit,
        page,
        fromDate,
        toDate,
        bookingSource,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useGetAllPaginatedPartnerNew added by Ashwin
export const useGetAllPaginatedPartnerNew = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_PARTNERNEW, {
    variables: {
      filter: {
        limit,
        page,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreatePartnerNew added by Ashwin
export const useCreatePartnerNew = () => {
  const [createPartnerNew, { loading, error, data, refetch }] =
    useMutation(CREATE_PARTNERNEW);

  useLoadingHandler(loading);

  const createPartnerNewHandler = (partnerNewInput) => {
    createPartnerNew({
      variables: {
        partnerNewInput,
      },
    });
  };

  return [createPartnerNewHandler, { loading, error, data, refetch }];
};

export const useUpdatePartnerNew = () => {
  const [updatePartnerNew, { loading, error, data, reset }] =
    useMutation(UPDATE_PARTNERNEW);

  useLoadingHandler(loading);

  const updatePartnerNewHandler = (id, partnerNewUpdate) => {
    updatePartnerNew({
      variables: { id, partnerNewUpdate },
    });
  };

  return [updatePartnerNewHandler, { loading, error, data, reset }];
};
//?Added By Gaurav
export const useGetWhatsappMessagesReport = (limit, page, fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(
    GET_WHATSAPP_MESSAGES_REPORT,
    {
      variables: {
        filter: {
          limit,
          page,
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//?Added By Gaurav
export const useGetCouponAppliedBookings = (
  limit,
  page,
  fromDate,
  toDate,
  hostelIds,
  searchKeyword
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_COUPON_APPLIED_BOOKINGS,
    {
      variables: {
        filter: {
          limit,
          page,
          fromDate,
          toDate,
          hostelIds,
          searchKeyword,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreateWhatsNew added by Ashwin
export const useCreateWhatsNew = () => {
  const [createWhatsNew, { loading, error, data, refetch }] =
    useMutation(CREATE_WHATSNEW);

  useLoadingHandler(loading);

  const createWhatsNewHandler = (whatsNewInput) => {
    createWhatsNew({
      variables: {
        whatsNewInput,
      },
    });
  };

  return [createWhatsNewHandler, { loading, error, data, refetch }];
};

// ! useUpdateWhatsNew added by Ashwin
export const useUpdateWhatsNew = () => {
  const [updateWhatsNew, { loading, error, data, reset }] =
    useMutation(UPDATE_WHATSNEW);

  useLoadingHandler(loading);

  const updateWhatsNewHandler = (id, whatsNewUpdate) => {
    updateWhatsNew({
      variables: { id, whatsNewUpdate },
    });
  };

  return [updateWhatsNewHandler, { loading, error, data, reset }];
};

// ! useGetAllPaginatedWhatsNew added by Ashwin
export const useGetAllPaginatedWhatsNew = (limit, page, filterData) => {
  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_WHATSNEW, {
    variables: {
      filter: {
        limit,
        page,
        filterData,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreatePressMention added by Ashwin
export const useCreatePressMention = () => {
  const [createPressMention, { loading, error, data, refetch }] =
    useMutation(CREATE_PRESS_MENTION);

  useLoadingHandler(loading);

  const createPressMentionHandler = (pressMentionInput) => {
    createPressMention({
      variables: {
        pressMentionInput,
      },
    });
  };

  return [createPressMentionHandler, { loading, error, data, refetch }];
};

// ! useUpdatePressMention added by Ashwin
export const useUpdatePressMention = () => {
  const [updatePressMention, { loading, error, data, reset }] =
    useMutation(UPDATE_PRESS_MENTION);

  useLoadingHandler(loading);

  const updatePressMentionHandler = (id, pressMentionUpdate) => {
    updatePressMention({
      variables: { id, pressMentionUpdate },
    });
  };

  return [updatePressMentionHandler, { loading, error, data, reset }];
};

// ! useGetAllPaginatedPressMention added by Ashwin
export const useGetAllPaginatedPressMention = (limit, page, filterData) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_PRESS_MENTION,
    {
      variables: {
        filter: {
          limit,
          page,
          filterData,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useGetAllPaginatedAdminUsers added by Ashwin
export const useGetAllPaginatedAdminUsers = (limit, page, filteredData) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_ADMIN_USERS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          ...filteredData,
        },
      },
      skip: !filteredData,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreateAdminUsers added by Ashwin
export const useCreateAdminUsers = () => {
  const [createAdminUsers, { loading, error, data, refetch }] =
    useMutation(CREATE_ADMIN_USERS);

  useLoadingHandler(loading);

  const createAdminUsersHandler = (adminUserInput) => {
    createAdminUsers({
      variables: {
        adminUserInput,
      },
    });
  };

  return [createAdminUsersHandler, { loading, error, data, refetch }];
};

// ! useUpdateAdminUsers added by Ashwin
export const useUpdateAdminUsers = () => {
  const [updateAdminUsers, { loading, error, data, reset }] =
    useMutation(UPDATE_ADMIN_USERS);

  useLoadingHandler(loading);

  const updateAdminUsersHandler = (id, adminUserUpdate) => {
    updateAdminUsers({
      variables: { id, adminUserUpdate },
    });
  };

  return [updateAdminUsersHandler, { loading, error, data, reset }];
};

// ! useResetAdminUserPassword added by Ashwin
export const useResetAdminUserPassword = () => {
  const [resetAdminUserPassword, { loading, error, data, reset }] = useMutation(
    RESET_ADMIN_USERS_PASSWORD
  );

  useLoadingHandler(loading);

  const resetAdminUserPasswordHandler = (
    email,
    oldPassword,
    newPassword,
    confirmNewPassword
  ) => {
    resetAdminUserPassword({
      variables: { email, oldPassword, newPassword, confirmNewPassword },
    });
  };

  return [resetAdminUserPasswordHandler, { loading, error, data, reset }];
};

// ! updateMedia added by Ashwin
export const useUpdateMediaDetails = () => {
  const [updateMediaDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_MEDIA_DETAILS);

  useLoadingHandler(loading);

  const updateMediaDetailsHandler = (slug, mediaUpdate) => {
    updateMediaDetails({
      variables: { slug, mediaUpdate },
    });
  };

  return [updateMediaDetailsHandler, { loading, error, data, reset }];
};

// !getMediaPageDetails added by Ashwin
export const useGetMediaDetails = (slug) => {
  const { loading, error, data } = useQuery(GET_MEDIA_DETAILS, {
    variables: { slug },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

//
export const useGetSalesDataHostelWise = (limit, page, fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(
    GET_SALES_DATA_HOSTEL_WISE,
    {
      variables: {
        filter: {
          limit,
          page,
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//
export const useGetDailyBookings = (fromDate, toDate, hostelIds) => {
  const { loading, error, data, refetch } = useQuery(GET_DAILY_BOOKINGS, {
    variables: {
      filter: {
        fromDate,
        toDate,
        hostelIds,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStayDateBooking = (date, hostelName, status) => {
  const { loading, error, data, refetch } = useQuery(GET_BOOKING_BY_STAY_DATE, {
    variables: {
      date,
      hostelName,
      status,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetSourceWiseReport = (fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(GET_SOURCE_WISE_REPORT, {
    variables: {
      fromDate,
      toDate,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetContributionAnalysisReport = (
  fromDate,
  toDate,
  isHostel
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CONTRIBUTION_ANALYSIS_REPORT,
    {
      variables: {
        fromDate,
        toDate,
        isHostel,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for all Feedback Report
export const useGetAllFeedbackStatusData = (fromDate, toDate, hostelName) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_FEEDBACK_STATUS_DATA,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          hostelName,
        },
      },
    }
  );
  //
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for Hostel Wise Feedback Report
export const useGetHostelWiseFeedbackStatusData = (
  fromDate,
  toDate,
  hostelName
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTELWISE_FEEDBACK_STATUS_DATA,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          hostelName,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for all Final Status Report
export const useGetAllFinalStatusData = (fromDate, toDate, hostelName) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_FINAL_STATUS_DATA,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          hostelName,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for hostel wise avg TAT
export const useGetHostelWiseAvgTat = (fromDate, toDate, hostelName) => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_WISE_AVG_TAT, {
    variables: {
      filter: {
        fromDate,
        toDate,
        hostelName,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for All Feedback Status Data
export const useGetTotalCustomerFeedbackStatusData = (
  fromDate,
  toDate,
  hostelName
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_TOTAL_CUSTOMER_FEEDBACK_STATUS_DATA,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          hostelName,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! getPaginatedBlogCategoryList added by Ashwin
export const useGetBlogsCategory = (limit, page, filterData) => {
  const { loading, error, data, refetch } = useQuery(GET_CATEGORY_BLOGS, {
    variables: {
      filter: {
        limit,
        page,
        filterData,
      },
    },
  });

  useLoadingHandler(loading);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Create blogCategory added by Ashwin
export const useAddBlogCategory = () => {
  const [BlogCategory, { loading, error, data, refetch }] = useMutation(
    POST_ADD_BLOG_Category
  );

  useLoadingHandler(loading);

  const addBlogCategoryHandler = (blogCategoryInput) => {
    BlogCategory({
      variables: { blogCategoryInput },
    });
  };

  return [addBlogCategoryHandler, { loading, error, data, refetch }];
};

// ! Update blogCategory added by Ashwin
export const useUpdateBlogCategory = () => {
  const [updateBlogCategory, { loading, error, data, reset }] =
    useMutation(UPDATE_BLOG_CATEGORY);

  useLoadingHandler(loading);

  const updateBlogCategoryHandler = (id, blogCategoryUpdate) => {
    updateBlogCategory({
      variables: { id, blogCategoryUpdate },
    });
  };

  return [updateBlogCategoryHandler, { loading, error, data, reset }];
};

// ! getPaginatedBlogList added by Ashwin
export const useGetBlogsBlog = (limit, page, filterData) => {
  const { loading, error, data, refetch } = useQuery(GET_BLOGS, {
    variables: {
      filter: {
        limit,
        page,
        filterData,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! getBlogsById added by Ashwin
export const useGetBlogById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_BLOG_BY_ID, {
    variables: {
      blogId: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Create blogsBlog added by Ashwin
export const useAddBlogsBlog = () => {
  const [Blog, { loading, error, data, refetch }] =
    useMutation(POST_ADD_BLOGS_BLOG);

  useLoadingHandler(loading);

  const addBlogsBlogHandler = (blogInput) => {
    Blog({
      variables: { blogInput },
    });
  };

  return [addBlogsBlogHandler, { loading, error, data, refetch }];
};

// ! Update blogsBlog added by Ashwin
export const useUpdateBlogsBlog = () => {
  const [updateBlogsBlog, { loading, error, data, reset }] =
    useMutation(UPDATE_BLOGS_BLOG);

  useLoadingHandler(loading);

  const updateBlogsBlogHandler = (id, blogUpdate) => {
    updateBlogsBlog({
      variables: { id, blogUpdate },
    });
  };

  return [updateBlogsBlogHandler, { loading, error, data, reset }];
};

// ! Get Blog Category added by Ashwin
export const useBlogCategoryList = () => {
  const { loading, error, data, refetch } = useQuery(GET_BLOG_CATEGORY_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// ! Get Similar Blogs added by Ashwin
export const useSimilarBlogsList = () => {
  const { loading, error, data, refetch } = useQuery(GET_SIMILAR_BLOGS_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// Added by Nitish for updateFeedbackTicket
export const useUpdateFeedbackTicket = () => {
  const [updateFeedbackTicket, { loading, error, data, reset }] = useMutation(
    UPDATE_FEEDBACK_TICKET
  );

  useLoadingHandler(loading);

  const updateFeedbackTicketHandler = (reservationId) => {
    updateFeedbackTicket({
      variables: {
        reservationId,
      },
    });
  };

  return [updateFeedbackTicketHandler, { loading, error, data, reset }];
};

// Added by Nitish for Total Bad Remark Word Count and Data
export const useGetTotalBadRemarkWordCountAndData = (
  fromDate,
  toDate,
  hostelName
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_TOTAL_BAD_REMARK_WORD_COUNT_AND_DATA,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          hostelName,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetHostelsForHotelCenter = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOTELS_FOR_HOTEL_CENTER
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useWebsiteHourlySalesReport = (fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(
    WEBSITE_HOURLY_SALES_REPORT,
    {
      variables: {
        DateRange: {
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetExtraChargesReport = (
  fromDate,
  toDate,
  hostelIds,
  extraChargeType
) => {
  const { loading, error, data, refetch } = useQuery(GET_EXTRA_CHARGES_REPORT, {
    variables: {
      filter: {
        fromDate,
        toDate,
        hostelIds,
        extraChargeType,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish, GET_HOSTEL_NAMES_BY_STAFF_EMAIL
export const useGetHostelNamesByStaffEmail = (staffEmail) => {
  // const staffEmail =
  //   localStorage.getItem("email") === undefined
  //     ? ""
  //     : localStorage.getItem("email");
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTEL_NAMES_BY_STAFF_EMAIL,
    {
      variables: {
        filter: {
          staffEmail,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for hostel wise avg TAT
export const useGetCxUpdatedByAvgTatAndCount = (fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CX_UPDATED_BY_AVG_TAT_AND_COUNT,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreateWebCheckin added by Ashwin
export const useCreateWebCheckin = () => {
  const [createWebCheckin, { loading, error, data, refetch }] =
    useMutation(CREATE_WEBCHECKIN);

  useLoadingHandler(loading);

  const createWebCheckinHandler = (webCheckinInput) => {
    createWebCheckin({
      variables: {
        webCheckinInput,
      },
    });
  };

  return [createWebCheckinHandler, { loading, error, data, refetch }];
};

// ! useUpdateWebCheckin added by Ashwin
export const useUpdateWebCheckin = () => {
  const [updateWebCheckin, { loading, error, data, reset }] =
    useMutation(UPDATE_WEBCHECKIN);

  useLoadingHandler(loading);

  const updateWebCheckinHandler = (id, webCheckinUpdate) => {
    updateWebCheckin({
      variables: { id, webCheckinUpdate },
    });
  };

  return [updateWebCheckinHandler, { loading, error, data, reset }];
};

// ! getPaginatedWebCheckinList added by Ashwin
export const useGetAllPaginatedWebCheckin = (
  inputFilter
  // checkinDateFrom,
  // checkinDateTo
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_WEB_CHECKIN_LIST,
    {
      variables: {
        inputFilter,
        // checkinDateFrom,
        // checkinDateTo,
      },
      // skip: inputFilter.filteredData !== undefined || null || {} ? true : false
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useSourcesList = () => {
  const { loading, error, data, refetch } = useQuery(GET_SOURCE_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//Added By Nitish
export const useAllGeustDatabase = (searchKeyword) => {
  const { loading, error, data, refetch } = useQuery(GET_GUEST_DATABASE, {
    variables: {
      filter: {
        searchKeyword,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
//Added By Nitish
export const useAllGuestDetailsWithSameNumberOrEmail = (Email, Number) => {
  const { loading, error, data, refetch } = useQuery(
    GET_GUEST_DETAILS_WITH_SAME_NUMBER_OR_EMAIL,
    {
      variables: {
        filter: {
          Email,
          Number,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//without pagination api for export
export const useAllFeedbackListForExport = (
  hostelName,
  customerFeedback,
  feedbackStatus,
  finalStatus,
  searchKeyword,
  fromDate,
  toDate
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_FEEDBACK_LIST_FOR_EXPORT,
    {
      variables: {
        filter: {
          hostelName,
          customerFeedback,
          feedbackStatus,
          finalStatus,
          searchKeyword,
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish to export all the bookings data
export const useGetChatbotBookingsForExport = (fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CHATBOT_BOOKINGS_FOR_EXPORT,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useGetPaginatedDisplayAdsList added by Ashwin
export const useGetPaginatedDisplayAdsList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_DISPLAY_ADS,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! useCreateDisplayAds added by Ashwin
export const useCreateDisplayAds = () => {
  const [createDisplayAds, { loading, error, data, refetch }] =
    useMutation(CREATE_DISPLAY_ADS);

  useLoadingHandler(loading);

  const createDisplayAdsHandler = (displayAdsInput) => {
    createDisplayAds({
      variables: {
        displayAdsInput,
      },
    });
  };

  return [createDisplayAdsHandler, { loading, error, data, refetch }];
};

// ! useUpdateDisplayAds added by Ashwin
export const useUpdateDisplayAds = () => {
  const [updateDisplayAds, { loading, error, data, reset }] =
    useMutation(UPDATE_DISPLAY_ADS);

  useLoadingHandler(loading);

  const updateDisplayAdsHandler = (id, displayAdsUpdate) => {
    updateDisplayAds({
      variables: { id, displayAdsUpdate },
    });
  };

  return [updateDisplayAdsHandler, { loading, error, data, reset }];
};

// !getBlogList added by Ashwin
export const useBlogList = () => {
  const { loading, error, data, refetch } = useQuery(GET_BLOG_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// ! getAllMessageTemplateList added by Ashwin
export const useGetAllMessageTemplateList = () => {
  const { loading, error, data, refetch } = useQuery(GET_MESSAGE_TEMPLATE_LIST);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Create MessageTemplate added by Ashwin
export const useAddMessageTemplate = () => {
  const [MessageTemplate, { loading, error, data, refetch }] = useMutation(
    POST_ADD_MESSAGE_TEMPLATE
  );

  useLoadingHandler(loading);

  const addMessageTemplateHandler = (messageTemplateInput) => {
    MessageTemplate({
      variables: { messageTemplateInput },
    });
  };

  return [addMessageTemplateHandler, { loading, error, data, refetch }];
};

// ! Update MessageTemplate added by Ashwin
export const useUpdateMessageTemplate = () => {
  const [updateMessageTemplate, { loading, error, data, reset }] = useMutation(
    UPDATE_MESSAGE_TEMPLATE
  );

  useLoadingHandler(loading);

  const updateMessageTemplateHandler = (id, messageTemplateUpdate) => {
    updateMessageTemplate({
      variables: { id, messageTemplateUpdate },
    });
  };

  return [updateMessageTemplateHandler, { loading, error, data, reset }];
};

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ! useGetPaginatedMessageConfigurationList added by Ashwin
export const useGetPaginatedMessageConfigurationList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_MESSAGE_CONFIGURATION,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// ! Create MessageConfiguration added by Ashwin
export const useAddMessageConfiguration = () => {
  const [MessageConfiguration, { loading, error, data, refetch }] = useMutation(
    POST_ADD_MESSAGE_CONFIGURATION
  );

  useLoadingHandler(loading);

  const addMessageConfigurationHandler = (messageConfigurationInput) => {
    MessageConfiguration({
      variables: { messageConfigurationInput },
    });
  };

  return [addMessageConfigurationHandler, { loading, error, data, refetch }];
};

// ! Update MessageConfiguration added by Ashwin
export const useUpdateMessageConfiguration = () => {
  const [updateMessageConfiguration, { loading, error, data, reset }] =
    useMutation(UPDATE_MESSAGE_CONFIGURATION);

  useLoadingHandler(loading);

  const updateMessageConfigurationHandler = (
    id,
    messageConfigurationUpdate
  ) => {
    updateMessageConfiguration({
      variables: { id, messageConfigurationUpdate },
    });
  };

  return [updateMessageConfigurationHandler, { loading, error, data, reset }];
};

// ! getMessageTemplateByName added by Ashwin
export const useGetMessageTemplateByName = (templateName) => {
  const { loading, error, data, refetch } = useQuery(
    GET_MESSAGE_TEMPLATE_BY_NAME,
    {
      variables: {
        templateName,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetOccupancyTrackerReport = (date, hostelIds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_OCCUPANCY_TRACKER_REPORT,
    {
      variables: {
        date,
        hostelIds,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetMemberShips = (strainer) => {
  const { loading, error, data, refetch } = useQuery(GET_MEMBERSHIPS, {
    variables: {
      strainer: {
        ...strainer,
      },
      skip: !strainer ? true : false,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// ! Added by Ashwin
export const useGetAllPaginatedRequests = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_REQUESTS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//! Coded by Ashwin

export const useGetRequestById = (requestId) => {
  const { loading, error, data, refetch } = useQuery(GET_REQUEST_ID, {
    variables: { requestId },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Create webAppRequest added by Ashwin
export const useAddWebAppRequest = () => {
  const [WebAppRequest, { loading, error, data, refetch }] = useMutation(
    POST_ADD_WEB_APP_REQUEST
  );

  useLoadingHandler(loading);

  const addWebAppRequestHandler = (requestsInput) => {
    WebAppRequest({
      variables: { requestsInput },
    });
  };

  return [addWebAppRequestHandler, { loading, error, data, refetch }];
};

// ! Update webAppRequest added by Ashwin
export const useUpdateWebAppRequest = () => {
  const [updateWebAppRequest, { loading, error, data, reset }] = useMutation(
    UPDATE_WEB_APP_REQUEST
  );

  useLoadingHandler(loading);

  const updateWebAppRequestHandler = (id, requestsUpdate) => {
    updateWebAppRequest({
      variables: { id, requestsUpdate },
    });
  };

  return [updateWebAppRequestHandler, { loading, error, data, reset }];
};

// ! getRequestByHostelId added by Ashwin
export const useGetRequestByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_REQUEST_HOSTEL_ID, {
    variables: {
      hostelId: hostelId,
    },
    skip: !hostelId,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateHostelRequest = () => {
  const [createHostelRequest, { loading, error, data, refetch }] = useMutation(
    CREATE_AND_UPDATE_REQUEST
  );

  const createHostelRequestHandler = ({ hostelRequestInput }) => {
    createHostelRequest({
      variables: { hostelRequestInput },
    });
  };

  return [createHostelRequestHandler, { loading, error, data, refetch }];
};

export const useGetMessageType = () => {
  const { loading, error, data, refetch } = useQuery(GET_MESSAGETYPE_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetAllTravelPackages = (limit, page, hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_TRAVEL_PACKAGES, {
    variables: {
      filter: {
        limit,
        page,
        hostelId,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateTravelPackage = () => {
  const [createTravelPackage, { loading, error, data, refetch }] = useMutation(
    CREATE_TRAVEL_PACKAGES
  );

  useLoadingHandler(loading);

  const createTravelPackagesHandler = (travelPackageInput) => {
    createTravelPackage({
      variables: {
        travelPackageInput,
      },
    });
  };

  return [createTravelPackagesHandler, { loading, error, data, refetch }];
};

export const useUpdateTravelPackage = () => {
  const [updateTravelPackage, { loading, error, data, refetch }] = useMutation(
    UPDATE_TRAVEL_PACKAGES
  );

  useLoadingHandler(loading);

  const updateTravelPackagesHandler = (id, travelPackageInput) => {
    updateTravelPackage({
      variables: {
        id,
        travelPackageInput,
      },
    });
  };

  return [updateTravelPackagesHandler, { loading, error, data, refetch }];
};

export const useGetAddOnReport = (
  limit,
  page,
  hostelIds,
  checkinStartDate,
  checkinEndDate,
  reservationId,
  addOn,
  purchaseStartDate,
  purchaseEndDate
) => {
  const { loading, error, data, refetch } = useQuery(GET_ADD_ON_REPORT, {
    variables: {
      filter: {
        limit,
        page,
        hostelIds,
        checkinStartDate,
        checkinEndDate,
        reservationId,
        addOn,
        purchaseStartDate,
        purchaseEndDate,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllPaginatedUserRequest = (
  limit,
  page,
  hostel,
  requestCategory,
  requestStatus,
  requestName,
  roomNumber,
  requestStartDate,
  requestEndDate,
  requestNumber,
  reservationId
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_USER_REQUESTS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          hostelIdArray: hostel,
          requestCategory,
          requestStatus,
          requestName,
          roomNumber,
          requestStartDate,
          requestEndDate,
          requestNumber,
          reservationId,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// ! Update webAppRequest added by Ashwin
export const useUpdateWebAppUserRequest = () => {
  const [updateWebAppUserRequest, { loading, error, data, reset }] =
    useMutation(UPDATE_WEB_APP_USER_REQUEST);

  useLoadingHandler(loading);

  const updateWebAppUserRequestHandler = (id, userRequestUpdate) => {
    updateWebAppUserRequest({
      variables: { id, userRequestUpdate },
    });
  };

  return [updateWebAppUserRequestHandler, { loading, error, data, reset }];
};

//Added by Nitish - checkoutlist
export const useGetContactlessCheckoutList = (inputFilter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CONTACTLESS_CHECKOUT_LIST,
    {
      variables: {
        inputFilter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useAddOnList = () => {
  const { loading, error, data, refetch } = useQuery(GET_ADD_ON_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//added by prakash
export const useGetGluBanner = () => {
  const { loading, data, refetch } = useQuery(GET_GLU_BANNER);
  useLoadingHandler(loading);
  return { loading, data, refetch };
};

//added by prakash
export const useUpdateGluBanner = () => {
  const [updateBanner, { loading, data, refetch }] =
    useMutation(UPDATE_GLU_BANNER);
  useLoadingHandler(loading);

  const updateGluBannerHandler = (id, gluBannerUpdate) => {
    updateBanner({
      variables: { id, gluBannerUpdate: gluBannerUpdate },
    });
  };

  return [updateGluBannerHandler, { loading, data, refetch }];
};

//added by prakash
export const useCreateGluBanner = () => {
  const [createBanner, { loading, data, refetch }] =
    useMutation(CREATE_GLU_BANNER);
  useLoadingHandler(loading);

  const createBannerHandler = (data) => {
    createBanner({
      variables: data,
    });
  };

  return [createBannerHandler, { loading, data, refetch }];
};

//added by prakash
export const useDeleteGluBanner = () => {
  const [deleteBanner, { loading, data, refetch }] =
    useMutation(DELETE_GLU_BANNER);
  useLoadingHandler(loading);
  const deleteBannerHandler = (id) => {
    deleteBanner({
      variables: { id },
    });
  };
  return [deleteBannerHandler, { loading, data, refetch }];
};

export const useRequestNameList = (requestCategory) => {
  const { loading, error, data, refetch } = useQuery(GET_REQUEST_NAME_LIST, {
    variables: {
      requestCategory,
    },
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useSendPaymentLink = () => {
  const [sendPaymentLinktoUnpaidBooking, { loading, error, data, reset }] =
    useMutation(SEND_PAYMENT_LINK);

  useLoadingHandler(loading);
  const sendPaymentLinktoUnpaidBookingHandler = (uniqueId) => {
    sendPaymentLinktoUnpaidBooking({
      variables: { uniqueId },
    });
  };
  return [
    sendPaymentLinktoUnpaidBookingHandler,
    { loading, error, data, reset },
  ];
};

export const useGetPaginatedEventsAndActivitiesList = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EVENTS_AND_ACTIVITIES_LISt,
    {
      variables: {
        // filter: {
        //   limit,
        //   page,
        // },
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useForgetPassword = () => {
  const [handleForgetPassword, { loading, error, data, reset }] =
    useMutation(FORGET_PASSWORD);

  useLoadingHandler(loading);

  const forgetPasswordHandler = (email) => {
    handleForgetPassword({
      variables: { email },
    });
  };

  return [forgetPasswordHandler, { loading, error, data, reset }];
};

// ! Create EventsAndActivities added by Ashwin
export const useCreateEventsAndActivities = () => {
  const [CreateEventsAndActivities, { loading, error, data, refetch }] =
    useMutation(CREATE_EVENTS_AND_ACTIVITIES);

  useLoadingHandler(loading);

  const createEventsAndActivitiesHandler = (eventsAndActivitiesInput) => {
    CreateEventsAndActivities({
      variables: { eventsAndActivitiesInput },
    });
  };

  return [createEventsAndActivitiesHandler, { loading, error, data, refetch }];
};

// ! Update EventsAndActivities added by Ashwin
export const useUpdateEventsAndActivities = () => {
  const [updateEventsAndActivities, { loading, error, data, reset }] =
    useMutation(UPDATE_EVENTS_AND_ACTIVITIES);

  useLoadingHandler(loading);

  const updateEventsAndActivitiesHandler = (id, eventsAndActivitiesUpdate) => {
    updateEventsAndActivities({
      variables: { id, eventsAndActivitiesUpdate },
    });
  };

  return [updateEventsAndActivitiesHandler, { loading, error, data, reset }];
};

export const useGetEventsAndActivitiesById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EVENTS_AND_ACTIVITIES_BY_ID,
    {
      variables: {
        eventsAndActivitiesId: id,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//added by nitish for export
export const useAllUserRequest = (
  hostel,
  requestCategory,
  requestStatus,
  requestName,
  roomNumber,
  requestStartDate,
  requestEndDate,
  requestNumber,
  reservationId
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_USER_REQUESTS_LIST,
    {
      variables: {
        filter: {
          hostelIdArray: hostel,
          requestCategory,
          requestStatus,
          requestName,
          roomNumber,
          requestStartDate,
          requestEndDate,
          requestNumber,
          reservationId,
        },
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//added by nitish for export
export const useGetAllWebCheckin = (inputFilter) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_WEB_CHECKIN_LIST, {
    variables: {
      inputFilter,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// export const useGetEventBookings = () => {
//   const { loading, error, data, refetch } = useQuery(GET_EVENT_BOOKINGS);

//   useLoadingHandler(loading);

//   return { loading, error, data, refetch };
// };

export const usePaginatedEventBookings = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_EVENT_BOOKINGS,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish - for export data
export const useGetAllContactlessCheckoutData = (inputFilter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_CONTACTLESS_CHECKOUT_DATA,
    {
      variables: {
        inputFilter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Service config - added by Nitish
export const useGetPaginatedServiceList = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_SERVICE_LIST,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateService = () => {
  const [createService, { loading, error, data, refetch }] =
    useMutation(CREATE_SERVICE);

  useLoadingHandler(loading);

  const createServiceHandler = (serviceInput) => {
    createService({
      variables: { serviceInput },
    });
  };

  return [createServiceHandler, { loading, error, data, refetch }];
};

export const useUpdateService = () => {
  const [updateService, { loading, error, data, reset }] =
    useMutation(UPDATE_SERVICE);

  useLoadingHandler(loading);

  const updateServiceHandler = (id, serviceInput) => {
    updateService({
      variables: { id, serviceInput },
    });
  };

  return [updateServiceHandler, { loading, error, data, reset }];
};

export const useGetPaginatedEmployeeProfileList = (
  limit,
  page,
  filteredData
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_EMPLOYEE_PROFILE_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          ...filteredData,
        },
        skip: !filteredData ? true : false,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateEmployeeProfile = () => {
  const [CreateEmployeeProfile, { loading, error, data, refetch }] =
    useMutation(CREATE_EMPLOYEE_PROFILE);

  useLoadingHandler(loading);

  const createEmployeeProfileHandler = (employeeProfileInput) => {
    CreateEmployeeProfile({
      variables: { employeeProfileInput },
    });
  };

  return [createEmployeeProfileHandler, { loading, error, data, refetch }];
};

export const useUpdateEmployeeProfile = () => {
  const [updateEmployeeProfile, { loading, error, data, reset }] = useMutation(
    UPDATE_EMPLOYEE_PROFILE
  );

  useLoadingHandler(loading);

  const updateEmployeeProfileHandler = async (id, employeeProfileUpdate) => {
    return await updateEmployeeProfile({
      variables: { id, employeeProfileUpdate },
    });
  };

  return [updateEmployeeProfileHandler, { loading, error, data, reset }];
};

export const useUpdateEmployeeTransferDetails = () => {
  const [updateEmployee, { loading, error, data, reset }] = useMutation(
    UPDATE_EMPLOYEE_TRANSFER_DETAILS
  );

  useLoadingHandler(loading);

  const updateEmployeeHandler = async (
    id,
    employeeTransferUpdate,
    fromAppraisal,
    fromTransfer
  ) => {
    return await updateEmployee({
      variables: { id, employeeTransferUpdate, fromAppraisal, fromTransfer },
    });
  };

  return [updateEmployeeHandler, { loading, error, data, reset }];
};

export const useGetEmployeeProfileById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_PROFILE_BY_ID,
    {
      variables: {
        id,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
//Addon bookings data - added by Nitish
export const useGetPaginatedAddOnsBookings = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_ADDONS_BOOKINGS,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Update Addon bookings status and remark - added by Nitish

export const useUpdateAddOnsBooking = () => {
  const [updateAddOnsBooking, { loading, error, data, reset }] = useMutation(
    UPDATE_ADDONS_BOOKING
  );

  useLoadingHandler(loading);

  const updateAddOnsBookingHandler = (id, UpdateAddonInput) => {
    console.log(UpdateAddonInput);
    updateAddOnsBooking({
      variables: {
        id,
        UpdateAddonInput: {
          status: UpdateAddonInput.status,
          remark: UpdateAddonInput.remark,
          logs: UpdateAddonInput.logs,
        },
      },
    });
  };

  return [updateAddOnsBookingHandler, { loading, error, data, reset }];
};

export const useGetRefunds = () => {
  const { loading, error, data, refetch } = useQuery(GET_REFUNDS, {});

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetEmployeeProfileByHostelId = (
  hostelId,
  attendanceMonth,
  attendanceYear
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_PROFILE_BY_HOSTEL_ID,
    {
      variables: { hostelId },
      skip: !hostelId,
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetEmployeeProfileForAttendance = (
  hostelId,
  attendanceMonth,
  attendanceYear
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMP_PROFILE_FOR_ATTENDANCE,
    {
      variables: { hostelId, attendanceMonth, attendanceYear },
      skip: !(hostelId && attendanceMonth && attendanceYear),
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateEmployeeAttendance = () => {
  const [createEmployeeAttendance, { loading, error, data, reset }] =
    useMutation(CREATE_EMPLOYEE_ATTENDANCE);
  useLoadingHandler(loading);
  const createEmployeeAttendanceHandler = (employeeAttendanceInput) => {
    createEmployeeAttendance({
      variables: {
        employeeAttendanceInput,
      },
    });
  };

  return [createEmployeeAttendanceHandler, { loading, error, data, reset }];
};

//Update Event bookings status and remark - added by Nitish

export const useUpdateEventStatusOrRemark = () => {
  const [updateAddOnsBooking, { loading, error, data, reset }] = useMutation(
    UPDATE_EVENT_STATUS_OR_REMARK
  );

  useLoadingHandler(loading);

  const updateEventStatusOrRemarkHandler = (id, UpdateEventInput) => {
    console.log(UpdateEventInput);
    updateAddOnsBooking({
      variables: {
        id,
        UpdateEventInput: {
          status: UpdateEventInput.status,
          remark: UpdateEventInput.remark,
          logs: UpdateEventInput.logs,
        },
      },
    });
  };

  return [updateEventStatusOrRemarkHandler, { loading, error, data, reset }];
};

export const useUpdateFirebaseToken = () => {
  const [updateFirebaseToken, { loading, error, data, reset }] = useMutation(
    UPDATE_FIREBASE_TOKEN
  );

  useLoadingHandler(loading);

  const updateFirebaseTokenHandler = (email, token) => {
    updateFirebaseToken({
      variables: {
        email,
        token,
      },
    });
  };
  return [updateFirebaseTokenHandler, { loading, error, data, reset }];
};

export const useCheckAccess = () => {
  const { loading, error, data, refetch } = useQuery(CHECK_ACCESS);
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetEmployeeLeaveBalanceByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_LEAVE_BALANCE_BY_HOSTEL_ID,
    {
      variables: { hostelId },
      skip: !hostelId,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetEmployeeLeaveBalanceByEmployeeId = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_LEAVE_BALANCE_BY_EMPLOYEE_ID,
    {
      variables: { id },
      skip: !id,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateEmployeeLeaveBalance = () => {
  const [CreateEmployeeLeaveBalance, { loading, error, data, refetch }] =
    useMutation(CREATE_EMPLOYEE_LEAVE_BALANCE);

  useLoadingHandler(loading);

  const createEmployeeLeaveBalanceHandler = (employeeLeaveBalanceInput) => {
    CreateEmployeeLeaveBalance({
      variables: { employeeLeaveBalanceInput },
    });
  };

  return [createEmployeeLeaveBalanceHandler, { loading, error, data, refetch }];
};

//Addon bookings data - added by Nitish
export const useGetAllAddOnsBookings = (filter) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_ADDONS_BOOKINGS, {
    variables: {
      filter,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllEventBookingsForExport = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_EVENT_BOOKINGS_FOR_EXPORT,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateEmployeeLeaveBalance = () => {
  const [updateEmployeeLeaveBalance, { loading, error, data, reset }] =
    useMutation(UPDATE_EMPLOYEE_LEAVE_BALANCE);
  const updateEmployeeLeaveBalanceHandler = (
    id,
    employeeLeaveBalanceUpdate
  ) => {
    updateEmployeeLeaveBalance({
      variables: { id, employeeLeaveBalanceUpdate },
    });
  };

  return [updateEmployeeLeaveBalanceHandler, { loading, error, data, reset }];
};

//added by nitish - invoice download
export const useGetServiceInvoiceOnSuccess = (bookingId) => {
  const { loading, data } = useQuery(GET_SERVICE_INVOICE_ON_SUCCESS, {
    variables: {
      bookingId,
    },
    skip: !bookingId ? true : false,
  });
  return { loading, data };
};

//added by nitish - invoice download
export const useGetEventsInvoiceOnSuccess = (bookingId) => {
  const { loading, data } = useQuery(GET_EVENTS_INOVOICE_ON_SUCCESS, {
    variables: {
      bookingId,
    },
    skip: !bookingId ? true : false,
  });
  return { loading, data };
};

export const useUploadXlsx = () => {
  const [uploadXlsxFile, { loading, error, data, reset }] = useMutation(
    XLSX_UPLOAD,
    {
      onCompleted(data) {
        return data;
      },
    }
  );
  useLoadingHandler(loading);

  const uploadXlsxHandler = async (file) => {
    return await uploadXlsxFile({
      variables: {
        file,
      },
    });
  };

  return [uploadXlsxHandler, { loading, error, data, reset }];
};

export const useGetEmployeeAttendanceByHostelId = (hostelId, month, year) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_ATTENDANCE_BY_HOSTEL_ID,
    {
      variables: { hostelId, month, year },
      skip: !(month && year),
    }
  );
  return { loading, error, data, refetch };
};

export const useGetHostelWiseLeaveBalance = (hostelId, year, month) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTELWISE_LEAVE_BALANCE,
    {
      variables: { hostelId, year, month },
      skip: !hostelId,
    }
  );
  return { loading, error, data, refetch };
};

export const useGetEmployeePaySlips = (getPaySlipInput) => {
  const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_PAYSLIPS, {
    variables: {
      getPaySlipInput,
    },
    skip: !getPaySlipInput,
  });
  return { loading, error, data, refetch };
};

export const useSaveEmployeePaySlip = () => {
  const [uploadPayslipHandler, { loading, error, data, reset }] = useMutation(
    SAVE_EMPLOYEE_PAYSLIP
  );
  const saveEmployeePaySlipHandler = (paySlipInput) => {
    return uploadPayslipHandler({
      variables: { paySlipInput },
    });
  };

  return [saveEmployeePaySlipHandler, { loading, error, data, reset }];
};

export const useGetStripConfigData = () => {
  const { loading, error, data, refetch } = useQuery(GET_STRIP_CONFIG, {
    skip: !GET_STRIP_CONFIG,
  });

  return { loading, error, data, refetch };
};

export const useCreateStripConfig = () => {
  const [createStripConfig, { data, refetch, error, loading }] =
    useMutation(CREATE_STRIP_CONFIG);
  useLoadingHandler(loading);
  const createStripConfigHandler = (InputStripConfig) => {
    createStripConfig({
      variables: { InputStripConfig },
    });
  };
  return [createStripConfigHandler, { data, refetch, error, loading }];
};

export const useUpdateStripConfig = () => {
  const [updateStripConfig, { loading, data, refetch }] =
    useMutation(UPDATED_STRIP_CONFIG);
  useLoadingHandler(loading);

  const updateStripConfigHandler = (id, InputStripConfig) => {
    updateStripConfig({
      variables: {
        id: id,
        InputStripConfig: InputStripConfig,
      },
    });
  };
  return [updateStripConfigHandler, { loading, data, refetch }];
};

export const useDeleteStripConfig = () => {
  const [deleteStrip, { loading, data, refetch }] =
    useMutation(DELETE_STRIP_CONFIG);
  useLoadingHandler(loading);
  const deleteStripHandler = (id) => {
    deleteStrip({
      variables: {
        id: id,
      },
    });
  };

  return [deleteStripHandler, { loading, data, refetch }];
};

export const useEmployeeProfileList = () => {
  const { loading, error, data, refetch } = useQuery(GET_EMPLOYEE_PROFILE_LIST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useAllUnpaidBookingsList = (
  limit,
  page,
  hostelIds,
  paymentStatus,
  checkinStartDate,
  checkinEndDate
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_UNPAID_BOOKINGS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          hostelIds,
          paymentStatus,
          checkinStartDate,
          checkinEndDate,
        },
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useUpdateReviewsLinks = () => {
  const [updateReviewsLinks, { loading, error, data, reset }] =
    useMutation(UPDATE_REVIEWS_LINKS);

  useLoadingHandler(loading);

  const updateReviewsLinksHandler = (hostelId, reviewsLinksUpdate) => {
    updateReviewsLinks({
      variables: { hostelId, reviewsLinksUpdate },
    });
  };

  return [updateReviewsLinksHandler, { loading, error, data, reset }];
};

//ORM Reviews data - added by Nitish
export const useGetPaginatedOtaReviews = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_OTA_REVIEWS,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedStepOutCategoryList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_CATEGORY_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStepOutCategoryById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_CATEGORY_BY_ID,
    {
      variables: {
        id,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllStepOutCategoryList = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_CATEGORY_LIST
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateStepOutCategory = () => {
  const [CreateStepOutCategory, { loading, error, data, refetch }] =
    useMutation(CREATE_STEP_OUT_CATEGORY);

  useLoadingHandler(loading);

  const createStepOutCategoryHandler = (stepOutCategoryInput) => {
    CreateStepOutCategory({
      variables: { stepOutCategoryInput },
    });
  };

  return [createStepOutCategoryHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutCategory = () => {
  const [updateStepOutCategory, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_CATEGORY
  );

  useLoadingHandler(loading);

  const updateStepOutCategoryHandler = (id, stepOutCategoryUpdate) => {
    updateStepOutCategory({
      variables: { id, stepOutCategoryUpdate },
    });
  };

  return [updateStepOutCategoryHandler, { loading, error, data, reset }];
};

export const useGetPaginatedStepOutProductList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_PRODUCT_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStepOutProductById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_PRODUCT_BY_ID,
    {
      variables: {
        id,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllStepOutProductList = () => {
  const { loading, error, data, refetch } = useQuery(GET_STEP_OUT_PRODUCT_LIST);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateStepOutProduct = () => {
  const [CreateStepOutProduct, { loading, error, data, refetch }] = useMutation(
    CREATE_STEP_OUT_PRODUCT
  );

  useLoadingHandler(loading);

  const createStepOutProductHandler = (createStepOutInput) => {
    CreateStepOutProduct({
      variables: { createStepOutInput },
    });
  };

  return [createStepOutProductHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutProduct = () => {
  const [updateStepOutProduct, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_PRODUCT
  );

  useLoadingHandler(loading);

  const updateStepOutProductHandler = (id, stepOutProductUpdate) => {
    updateStepOutProduct({
      variables: { id, stepOutProductUpdate },
    });
  };

  return [updateStepOutProductHandler, { loading, error, data, reset }];
};

//ORM Reviews Report - added by Nitish
export const useGetOtaReviewsReport = (
  hostelID,
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(GET_OTA_REVIEWS_REPORT, {
    variables: {
      hostelID,
      reviewDateFrom,
      reviewDateTo,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//ORM Reviews sentiment - added by Nitish
export const useGetReviewSentimentAnalysis = (
  hostelID,
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_REVIEW_SENTIMENT_ANALYSIS,
    {
      variables: {
        hostelID,
        reviewDateFrom,
        reviewDateTo,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//ORM Overall Average Rating - added by Nitish
export const useGetOverallAverageRating = (
  hostelID,
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_OVERALL_AVERAGE_RATING,
    {
      variables: {
        hostelID,
        reviewDateFrom,
        reviewDateTo,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// Update Basic Guest Details in ORM - added by Nitish
export const useUpdateBasicGuestDetails = () => {
  const [updateBasicGuestDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_BASIC_GUEST_DETAILS);

  useLoadingHandler(loading);

  const updateBasicGuestDetailsHandler = (_id, guestDetailsInput) => {
    updateBasicGuestDetails({
      variables: {
        _id,
        guestDetailsInput: {
          reservationId: guestDetailsInput.reservationId,
          phoneNumber: guestDetailsInput.phoneNumber,
          emailId: guestDetailsInput.emailId,
          roomNo: guestDetailsInput.roomNo,
          noOfNights: guestDetailsInput.noOfNights,
        },
      },
    });
  };

  return [updateBasicGuestDetailsHandler, { loading, error, data, reset }];
};

// added by Nitish to get all admin users list
export const useGetAllAdminUsersList = (filteredData) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_ADMIN_USERS_LIST, {
    variables: {
      filter: {
        ...filteredData,
      },
      skip: !filteredData ? true : false,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useUpdateStepOutHomePageDetails = () => {
  const [updateStepOutHomePageDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_STEP_OUT_HOMEPAGE_DETAILS);

  useLoadingHandler(loading);

  const updateStepOutHomePageDetailsHandler = (slug, stepOutHomepageUpdate) => {
    updateStepOutHomePageDetails({
      variables: { slug, stepOutHomepageUpdate },
    });
  };

  return [updateStepOutHomePageDetailsHandler, { loading, error, data, reset }];
};

export const useGetStepOutHomePageDetails = (slug) => {
  const { loading, error, data } = useQuery(GET_STEP_OUT_HOMEPAGE_DETAILS, {
    variables: { slug },
  });

  useLoadingHandler(loading);

  return { loading, error, data };
};

export const useGetWebsiteBooking = (
  limit,
  page,
  promoCode,
  status,
  hostelIds,
  fromDate,
  toDate,
  guestName,
  mobile,
  providerRefId,
  paymentMode,
  bookingSource,
  isBlackListed,
  UTM
) => {
  const { loading, error, data, refetch } = useQuery(GET_WEBSITE_BOOKING, {
    variables: {
      filter: {
        limit,
        page,
        promoCode,
        status,
        hostelIds,
        fromDate,
        toDate,
        guestName,
        mobile,
        providerRefId,
        paymentMode,
        bookingSource,
        isBlackListed,
        UTM,
      },
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetWebsiteBookingForExport = () => {
  const [getWebsiteBookingForExportData, { loading, error, data, refetch }] =
    useMutation(GET_WEBSITE_BOOKING_EXPORT);
  useLoadingHandler(loading);
  const getWebsiteBookingExportDataHandler = (filter) => {
    getWebsiteBookingForExportData({
      variables: {
        filter,
      },
    });
  };
  return [
    getWebsiteBookingExportDataHandler,
    { data, refetch, loading, error },
  ];
};

// Update Guest Feedback Details in ORM - added by Nitish
export const useUpdateGuestFeedbackDetails = () => {
  const [updateGuestFeedbackDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_GUEST_FEEDBACK_DETAILS);

  useLoadingHandler(loading);

  const updateGuestFeedbackDetailsHandler = (
    _id,
    guestFeedbackDetailsInput
  ) => {
    updateGuestFeedbackDetails({
      variables: {
        _id,
        guestFeedbackDetailsInput,
      },
    });
  };

  return [updateGuestFeedbackDetailsHandler, { loading, error, data, reset }];
};

// Update hostel Feedback Details in ORM - added by Nitish
export const useUpdateHostelFeedbackDetails = () => {
  const [updateHostelFeedbackDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_HOSTEL_FEEDBACK_DETAILS);

  useLoadingHandler(loading);

  const updateHostelFeedbackDetailsHandler = (
    _id,
    hostelFeedbackDetailsInput
  ) => {
    updateHostelFeedbackDetails({
      variables: {
        _id,
        hostelFeedbackDetailsInput,
      },
    });
  };

  return [updateHostelFeedbackDetailsHandler, { loading, error, data, reset }];
};

// Update CX Details in ORM - added by Nitish
export const useUpdateCXDetails = () => {
  const [updateCXDetails, { loading, error, data, reset }] =
    useMutation(UPDATE_CX_DETAILS);

  useLoadingHandler(loading);

  const updateCXDetailsHandler = (_id, cxDetailsInput) => {
    updateCXDetails({
      variables: {
        _id,
        cxDetailsInput,
      },
    });
  };

  return [updateCXDetailsHandler, { loading, error, data, reset }];
};

export const useGetLoyaltyReport = (limit, page, email) => {
  const { loading, data, error, refetch } = useQuery(GET_LOYALTY_REPORT, {
    variables: {
      filter: {
        limit,
        page,
        ...email,
      },
      skip: !email ? true : false,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// CX Ticket in ORM - added by Nitish
export const useUpdateCXTicket = () => {
  const [updateCXTicket, { loading, error, data, reset }] =
    useMutation(UPDATE_CX_TICKET);

  useLoadingHandler(loading);

  const updateCXTicketHandler = (_id, ticketInput) => {
    console.log(ticketInput, "biibj");

    updateCXTicket({
      variables: {
        _id,
        ticketInput,
      },
    });
  };

  return [updateCXTicketHandler, { loading, error, data, reset }];
};

export const useGetHostelsNameandId = () => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTELS_NAME_AND_ID);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetOtaSummary = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_OTA_SUMMARY, {
    variables: {
      hostelId,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetAvgRatingOtaSummary = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_AVG_RATING_OTA_SUMMARY
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetCxReputationReport = (hostelId, fromDate, toDate) => {
  const { loading, error, data, refetch } = useQuery(GET_CX_REPUTATION_REPORT, {
    variables: {
      hostelId,
      fromDate,
      toDate,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetStepOutPolicies = (type) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_POLICIES_BY_TYPE,
    {
      variables: { type },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateStepOutPolicies = () => {
  const [updateStepOutPolicies, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_POLICIES
  );

  useLoadingHandler(loading);

  const updateStepOutPoliciesHandler = (type, StepOutPoliciesUpdate) => {
    updateStepOutPolicies({
      variables: { type, StepOutPoliciesUpdate },
    });
  };

  return [updateStepOutPoliciesHandler, { loading, error, data, reset }];
};

export const useGetPaginatedStepOutContactUsList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_CONTACT_US_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStepOutCouponById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_COUPON_BY_ID,
    {
      variables: {
        id,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedStepOutCouponList = (limit, page, filterFields) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_COUPON_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateStepOutCoupon = () => {
  const [createStepOutCoupon, { loading, error, data, refetch }] = useMutation(
    CREATE_STEP_OUT_COUPON
  );

  useLoadingHandler(loading);

  const createStepOutCouponsHandler = (stepOutCouponInput) => {
    createStepOutCoupon({
      variables: {
        stepOutCouponInput,
      },
    });
  };

  return [createStepOutCouponsHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutCoupon = () => {
  const [updateStepOutCoupon, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_COUPON
  );

  useLoadingHandler(loading);

  const updateStepOutCouponHandler = (id, stepOutCouponUpdate) => {
    updateStepOutCoupon({
      variables: { id, stepOutCouponUpdate },
    });
  };

  return [updateStepOutCouponHandler, { loading, error, data, reset }];
};

export const useGetAllPaginatedReplyTemplate = (limit, page, filteredData) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_REPLY_TEMPLATE,
    {
      variables: {
        filter: {
          limit,
          page,
          ...filteredData,
        },
        skip: !filteredData ? true : false,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateReplyTemplate = () => {
  const [createReplyTemplate, { loading, error, data, refetch }] = useMutation(
    CREATE_REPLY_TEMPLATE
  );

  useLoadingHandler(loading);

  const createReplyTemplateHandler = (replyTemplateInput) => {
    createReplyTemplate({
      variables: {
        replyTemplateInput,
      },
    });
  };

  return [createReplyTemplateHandler, { loading, error, data, refetch }];
};

export const useUpdateReplyTemplate = () => {
  const [updateReplyTemplate, { loading, error, data, reset }] = useMutation(
    UPDATE_REPLY_TEMPLATE
  );

  useLoadingHandler(loading);

  const updateReplyTemplateHandler = (id, replyTemplateUpdate) => {
    updateReplyTemplate({
      variables: { id, replyTemplateUpdate },
    });
  };

  return [updateReplyTemplateHandler, { loading, error, data, reset }];
};

export const useGetAllReplyTemplate = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_REPLY_TEMPLATE);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedStepOutLocationList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_LOCATION_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStepOutLocationById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_LOCATION_BY_ID,
    {
      variables: {
        id,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllStepOutLocationList = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_LOCATION_LIST
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateStepOutLocation = () => {
  const [CreateStepOutLocation, { loading, error, data, refetch }] =
    useMutation(CREATE_STEP_OUT_LOCATION);

  useLoadingHandler(loading);

  const createStepOutLocationHandler = (stepOutLocationInput) => {
    CreateStepOutLocation({
      variables: { stepOutLocationInput },
    });
  };

  return [createStepOutLocationHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutLocation = () => {
  const [updateStepOutLocation, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_LOCATION
  );

  useLoadingHandler(loading);

  const updateStepOutLocationHandler = (id, stepOutLocationUpdate) => {
    updateStepOutLocation({
      variables: { id, stepOutLocationUpdate },
    });
  };

  return [updateStepOutLocationHandler, { loading, error, data, reset }];
};

export const useGetAllStepoutBlog = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_STEPOUT_BLOGS);
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetPaginatedStepoutBlog = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    // YOU CANNOT USE THE SAME REDUX AS BLOG

    GET_PAGINATED_STEPOUT_BLOG,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetStepoutBlogById = (Id) => {
  const { loading, error, data, refetch } = useQuery(GET_STEPOUT_BLOG_BY_ID, {
    variables: {
      Id,
    },
  });
  return { loading, error, data, refetch };
};

export const useCreateStepOutBlog = () => {
  const [createStepoutBlog, { loading, error, data, refetch }] =
    useMutation(CREATE_STEPOUT_BLOG);

  useLoadingHandler(loading);
  const createStepoutBlogHandler = (StepOutBlogInput) => {
    createStepoutBlog({
      variables: {
        StepOutBlogInput,
      },
    });
  };
  return [createStepoutBlogHandler, { loading, error, data, refetch }];
};

export const useUpdateStepoutBlog = () => {
  const [updateStepoutBlog, { loading, error, data, refetch }] =
    useMutation(UPDATE_STEPOUT_BLOG);
  useLoadingHandler(loading);
  const updateStepoutBlogHandler = (id, StepOutBlogInput) => {
    updateStepoutBlog({
      variables: {
        id,
        StepOutBlogInput,
      },
    });
  };
  return [updateStepoutBlogHandler, { loading, error, data, refetch }];
};

// export const useSimilarBlogsList = () => {
//   const { loading, error, data, refetch } = useQuery(GET_SIMILAR_BLOGS_LIST);

//   useLoadingHandler(loading);
//   return { loading, error, data, refetch };
// };

export const useGetCorporateReportData = (reviewDateFrom, reviewDateTo) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CORPORATE_REPORT_DATA,
    {
      variables: {
        reviewDateFrom,
        reviewDateTo,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetHostelWiseDataForCorporateReport = (
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTEL_WISE_DATA_FOR_CORPORATE_REPORT,
    {
      variables: {
        reviewDateFrom,
        reviewDateTo,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetWebcheckinDataByReservationId = (reservationID) => {
  const { loading, error, data, refetch } = useQuery(
    GET_WEBCHECKIN_DATA_BY_RESERVATION_ID,
    {
      variables: {
        reservationID,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetORMTicketsReport = (
  hostelID,
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(GET_ORM_TICKETS_REPORT, {
    variables: {
      hostelID,
      reviewDateFrom,
      reviewDateTo,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedMemberShipsList = (limit, page, filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_MEMBERSHIPS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          ...filter,
        },
        skip: !filter ? true : false,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedLoyaltyCoins = (limit, page, email) => {
  const { loading, data, error, refetch } = useQuery(
    GET_PAGINATED_LOYALTY_COINS,
    {
      variables: {
        filter: {
          limit,
          page,
          ...email,
        },
        skip: !email ? true : false,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateLoyaltyCoins = () => {
  const [CreateLoyaltyCoins, { loading, error, data, refetch }] =
    useMutation(CREATE_LOYALTY_COINS);

  useLoadingHandler(loading);

  const createLoyaltyCoinsHandler = (loyaltyCoinsInput) => {
    CreateLoyaltyCoins({
      variables: { loyaltyCoinsInput },
    });
  };

  return [createLoyaltyCoinsHandler, { loading, error, data, refetch }];
};

export const useUpdateLoyaltyCoins = () => {
  const [updateLoyaltyCoins, { loading, error, data, reset }] =
    useMutation(UPDATE_LOYALTY_COINS);

  useLoadingHandler(loading);

  const updateLoyaltyCoinsHandler = async (id, loyaltyCoinsUpdate) => {
    return await updateLoyaltyCoins({
      variables: { id, loyaltyCoinsUpdate },
    });
  };

  return [updateLoyaltyCoinsHandler, { loading, error, data, reset }];
};

export const useGetAllMembershipList = (strainer) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_MEMBERSHIPS, {
    variables: {
      strainer: {
        ...strainer,
      },
      skip: !strainer ? true : false,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//Added by Nitish
export const useGetAvgRatingOtaReviews = (
  hostelID,
  reviewDateFrom,
  reviewDateTo
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_AVG_RATING_OTA_REVIEWS,
    {
      variables: {
        hostelID,
        reviewDateFrom,
        reviewDateTo,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateUser = () => {
  const [createUser, { loading, error, data, refetch }] =
    useMutation(CREATE_USER);

  useLoadingHandler(loading);

  const createUserHandler = (body) => {
    createUser({
      variables: {
        body,
      },
    });
  };

  return [createUserHandler, { loading, error, data, refetch }];
};

export const useUpdateUser = () => {
  const [updateUser, { loading, error, data, refetch }] =
    useMutation(UPDATE_USER);

  useLoadingHandler(loading);

  const updateUserHandler = (id, userUpdate) => {
    updateUser({
      variables: {
        id,
        userUpdate,
      },
    });
  };
  return [updateUserHandler, { loading, error, data, refetch }];
};

export const useGetUserById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_USER_BY_ID, {
    variables: {
      id,
    },
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedUserList = (limit, page, email) => {
  console.log(limit, page, email, "Check Email");
  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_USER_LIST, {
    variables: {
      filter: {
        limit,
        page,
        ...email,
      },
      skip: !email ? true : false,
    },
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetStepOutBlogCategoryList = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_BLOG_CATEGORY_LIST
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPaginatedStepOutBlogCategoryList = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEP_OUT_BLOG_CATEGORY_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useAddStepOutBlogCategory = () => {
  const [addStepOutBlogCategory, { loading, error, data, refetch }] =
    useMutation(ADD_STEP_OUT_BLOG_CATEGORY_DATA);

  useLoadingHandler(loading);

  const addStepOutBlogCategoryHandler = (StepOutBlogCategoryInput) => {
    addStepOutBlogCategory({
      variables: {
        StepOutBlogCategoryInput,
      },
    });
  };

  return [addStepOutBlogCategoryHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutBlogCategory = () => {
  const [updateStepOutBlogCategory, { loading, error, data, reset }] =
    useMutation(UPDATE_STEP_OUT_BLOG_CATEGORY_DATA);

  useLoadingHandler(loading);

  const updateStepOutBlogCategoryHandler = (id, StepOutBlogUpdate) => {
    //console.log(id, StepOutBlogUpdate);
    updateStepOutBlogCategory({
      variables: {
        id,
        StepOutBlogUpdate,
      },
    });
  };

  return [updateStepOutBlogCategoryHandler, { loading, error, data, reset }];
};

//added by nitish
export const useDeleteOrmReview = () => {
  const [deleteOrmReview, { loading, error, data }] =
    useMutation(DELETE_ORM_REVIEW);

  useLoadingHandler(loading);

  const deleteOrmReviewHandler = (id) => {
    deleteOrmReview({
      variables: {
        id,
      },
    });
  };

  return [deleteOrmReviewHandler, { loading, error, data }];
};

export const useGetPaginatedWallet = (limit, page, email) => {
  const { loading, data, error, refetch } = useQuery(GET_PAGINATED_WALLET, {
    variables: {
      filter: {
        limit,
        page,
        ...email,
      },
      skip: !email ? true : false,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateWallet = () => {
  const [CreateWallet, { loading, error, data, refetch }] =
    useMutation(CREATE_WALLET);

  useLoadingHandler(loading);

  const createWalletHandler = (walletInput) => {
    CreateWallet({
      variables: { walletInput },
    });
  };

  return [createWalletHandler, { loading, error, data, refetch }];
};

export const useUpdateWallet = () => {
  const [updateWallet, { loading, error, data, reset }] =
    useMutation(UPDATE_WALLET);

  useLoadingHandler(loading);

  const updateWalletHandler = async (id, walletUpdate) => {
    return await updateWallet({
      variables: { id, walletUpdate },
    });
  };

  return [updateWalletHandler, { loading, error, data, reset }];
};

export const useGetAllWalletList = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_WALLET);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetWalletBalanceReport = (limit, page, email) => {
  const { loading, data, error, refetch } = useQuery(
    GET_WALLET_BALANCE_REPORT,
    {
      variables: {
        filter: {
          limit,
          page,
          ...email,
        },
        skip: !email ? true : false,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetWalletBalanceReportList = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_WALLET_BALANCE_REPORT_LIST
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetPaginatedStepoutUser = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_STEPOUT_USERS,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetAllStepoutWallOfLove = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_WALL_OF_LOVE_IMAGES
  );
  return { loading, error, data, refetch };
};

export const useDeleteStepoutWallOfLove = (id) => {
  const [deleteWallOfLoveImage, { loading, error, data, refetch }] =
    useMutation(DELETE_STEP_OUT_WALL_OF_LOVE);
  useLoadingHandler(loading);

  const deleteStepoutWallofLoveHandler = (id) => {
    deleteWallOfLoveImage({
      variables: {
        id,
      },
    });
  };
  return [deleteStepoutWallofLoveHandler, { loading, error, data }];
};

export const useCreateStepoutWallOfLove = () => {
  const [createStepoutWallofLove, { loading, error, data, refetch }] =
    useMutation(CREATE_WALL_OF_LOVE_IMAGE);
  useLoadingHandler(loading);

  const createStepoutWallOfLoveHandler = (ImageData) => {
    // console.log(ImageData)
    createStepoutWallofLove({
      variables: {
        ImageData,
      },
    });
  };
  return [createStepoutWallOfLoveHandler, { loading, error, data, refetch }];
};

export const useUpdateStepoutWallOfLove = () => {
  const [updateStepoutWallOfLove, { loading, error, data, refetch }] =
    useMutation(UPDATE_WALL_OF_LOVE_IMAGE);
  useLoadingHandler(loading);

  const updateStepoutWallOfLoveHandler = (id, ImageData) => {
    // console.log(id, ImageData);
    updateStepoutWallOfLove({
      variables: {
        id,
        ImageData,
      },
    });
  };
  return [updateStepoutWallOfLoveHandler, { loading, error, data, refetch }];
};

export const useUpdateFakeReview = () => {
  const [updateFakeReview, { loading, error, data, reset }] =
    useMutation(UPDATE_FAKE_REVIEW);

  useLoadingHandler(loading);

  const updateFakeReviewHandler = (_id, isReviewFake, sentForRemoval) => {
    updateFakeReview({
      variables: {
        _id,
        isReviewFake,
        sentForRemoval,
      },
    });
  };

  return [updateFakeReviewHandler, { loading, error, data, reset }];
};

export const useUpdateAreaManagerInput = () => {
  const [updateAreaManagerInput, { loading, error, data, reset }] = useMutation(
    UPDATE_AREA_MANAGER_INPUT
  );

  useLoadingHandler(loading);

  const updateAreaManagerInputHandler = (_id, areaManagerRemark) => {
    updateAreaManagerInput({
      variables: {
        _id,
        areaManagerRemark,
      },
    });
  };

  return [updateAreaManagerInputHandler, { loading, error, data, reset }];
};

export const useGetStepOutPaginatedBookingList = (
  limit,
  page,
  filterFields
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_STEP_OUT_PAGINATED_BOOKING,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateStepOutBooking = () => {
  const [CreateStepOutBooking, { loading, error, data, refetch }] = useMutation(
    CREATE_STEP_OUT_BOOKING
  );

  useLoadingHandler(loading);

  const createStepOutBookingHandler = (bookingInput) => {
    CreateStepOutBooking({
      variables: { bookingInput },
    });
  };

  return [createStepOutBookingHandler, { loading, error, data, refetch }];
};

export const useUpdateStepOutBooking = () => {
  const [updateStepOutBooking, { loading, error, data, reset }] = useMutation(
    UPDATE_STEP_OUT_BOOKING
  );

  useLoadingHandler(loading);

  const updateStepOutBookingHandler = (id, bookingUpdate) => {
    updateStepOutBooking({
      variables: { id, bookingUpdate },
    });
  };

  return [updateStepOutBookingHandler, { loading, error, data, reset }];
};

export const useGetPaginatedOpsFinanceList = (limit, page, filterFields) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_OPS_FINANCE_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetOpsFinanceReportList = () => {
  const { loading, error, data, refetch } = useQuery(GET_OPS_FINANCE_REPORT);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useCreateOpsFinance = () => {
  const [CreateOpsFinance, { loading, error, data, refetch }] =
    useMutation(CREATE_OPS_FINANCE);

  useLoadingHandler(loading);

  const createOpsFinanceHandler = (opsFinanceInput) => {
    CreateOpsFinance({
      variables: { opsFinanceInput },
    });
  };

  return [createOpsFinanceHandler, { loading, error, data, refetch }];
};

export const useUpdateOpsFinance = () => {
  const [updateOpsFinance, { loading, error, data, reset }] =
    useMutation(UPDATE_OPS_FINANCE);

  useLoadingHandler(loading);

  const updateOpsFinanceHandler = (id, opsFinanceUpdate) => {
    updateOpsFinance({
      variables: { id, opsFinanceUpdate },
    });
  };

  return [updateOpsFinanceHandler, { loading, error, data, reset }];
};

export const useCreateNewORMReview = () => {
  const [createNewORMReview, { loading, error, data, refetch }] = useMutation(
    CREATE_NEW_ORM_REVIEW
  );

  useLoadingHandler(loading);

  const createNewORMReviewHandler = (reviewInput) => {
    createNewORMReview({
      variables: { reviewInput },
    });
  };

  return [createNewORMReviewHandler, { loading, error, data, refetch }];
};

export const useGetAllPaginatedHomePage = (limit, page) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_HOMEPAGE_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateHomePage = () => {
  const [createHomePage, { loading, error, data, refetch }] =
    useMutation(CREATE_HOMEPAGE);

  useLoadingHandler(loading);

  const createHomePageHandler = (homePageInput) => {
    createHomePage({
      variables: { homePageInput },
    });
  };

  return [createHomePageHandler, { loading, error, data, refetch }];
};

//added by Nitish - Final CX Remark
export const useUpdateFinalCXRemark = () => {
  const [updateFinalCXRemark, { loading, error, data, reset }] = useMutation(
    UPDATE_FINAL_CX_REMARK
  );

  useLoadingHandler(loading);

  const updateFinalCXRemarkHandler = (_id, finalCXRemark, caseMedia) => {
    updateFinalCXRemark({
      variables: {
        _id,
        finalCXRemark,
        caseMedia,
      },
    });
  };

  return [updateFinalCXRemarkHandler, { loading, error, data, reset }];
};

export const useCreateNewPost = () => {
  const [createPost, { loading, error, data, refetch }] =
    useMutation(START_CONVERSATION);
  useLoadingHandler(loading);

  const createNewPostHandler = (conversationInput) => {
    createPost({
      variables: { conversationInput },
    });
  };
  return [createNewPostHandler, { loading, error, data, refetch }];
};

export const useGetAllPosts = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_POSTS);
  return { loading, error, data, refetch };
};

export const useGetReplyToPost = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_POST_REPLY, {
    variables: {
      id,
    },
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetPostDetails = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_POST_DETAIL, {
    variables: {
      id,
    },
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useAddReplyToPost = () => {
  const [addComment, { loading, error, data, refetch }] =
    useMutation(ADD_COMMENT_ON_POST);
  useLoadingHandler(loading);

  const addReplyToPostHandler = (conversationReplyInput) => {
    addComment({
      variables: { conversationReplyInput },
    });
  };
  return [addReplyToPostHandler, { loading, error, data, refetch }];
};

export const useAddReplyToComment = () => {
  const [addReply, { loading, error, data, refetch }] =
    useMutation(ADD_REPLY_ON_COMMENT);
  useLoadingHandler(loading);
  const addReplyToCommentHandler = (id, replyToCommentInput) => {
    addReply({
      variables: {
        id,
        replyToCommentInput,
      },
    });
  };
  return [addReplyToCommentHandler, { loading, error, data, refetch }];
};

export const useSearchDataInRange = () => {
  const [getData, { loading, error, data, refetch }] = useMutation(
    GET_ALL_SEARCHES_IN_DATE_RANGE
  );
  useLoadingHandler(loading);
  const getSearchDataHandler = (startDate, endDate) => {
    getData({
      variables: {
        startDate,
        endDate,
      },
    });
  };
  return [getSearchDataHandler, { data, refetch, loading, error }];
};

export const useGetAllDayBookDataForExport = (filterFields) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_DAY_BOOK_DATA_FOR_EXPORT,
    {
      variables: {
        filter: {
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUpdateEzeeBookingRemarks = () => {
  const [updateEzeeBookingRemarks, { loading, error, data, reset }] =
    useMutation(UPDATE_EZEE_BOOKING_REMARKS);

  useLoadingHandler(loading);
  const updateEzeeBookingRemarksHandler = (
    uniqueId,
    remarksLogUpdate,
    alternateEmail,
    alternateMobile
  ) => {
    updateEzeeBookingRemarks({
      variables: {
        uniqueId,
        remarksLogUpdate,
        alternateEmail,
        alternateMobile,
      },
    });
  };
  return [updateEzeeBookingRemarksHandler, { loading, error, data, reset }];
};

export const useGenerateChatbotBookingsForExport = () => {
  const [getExportData, { loading, error, data, refetch }] = useMutation(
    GENERATE_CHATBOT_EXCEL
  );
  useLoadingHandler(loading);
  const getExportDataHandler = (fromDate, toDate, bookingSource) => {
    getExportData({
      variables: {
        filter: {
          fromDate,
          toDate,
          bookingSource,
        },
      },
    });
  };
  return [getExportDataHandler, { data, refetch, loading, error }];
};

//Added by Nitish
export const useGetHostelWiseClosingBalance = (toDate) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTEL_WISE_CLOSING_BALANCE,
    {
      variables: {
        toDate,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGenerateCheckinDataForExport = () => {
  const [generateCheckinExportData, { loading, error, data, refetch }] =
    useMutation(GENERATE_WEBCHECKIN_EXCEL_DATA);
  useLoadingHandler(loading);
  const getCheckinExportDataHandler = (inputFilter) => {
    generateCheckinExportData({
      variables: {
        inputFilter,
      },
    });
  };
  return [getCheckinExportDataHandler, { data, refetch, loading, error }];
};

export const useGetAppliedCouponListForExport = (
  fromDate,
  toDate,
  searchKeyword,
  hostelIds
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_APPLIED_COUPON_LIST_EXPORT,
    {
      variables: {
        filter: {
          fromDate,
          toDate,
          searchKeyword,
          hostelIds,
        },
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useDeleteConnectPost = () => {
  const [deletePost, { loading, error, data, refetch }] =
    useMutation(DELETE_CONNECT_POST);
  useLoadingHandler(loading);
  const deleteConnectPostHandler = (userId, postId) => {
    deletePost({
      variables: {
        userId,
        postId,
      },
    });
  };
  return [deleteConnectPostHandler, { loading, error, data, refetch }];
};

export const useDeleteConnectComment = () => {
  const [deleteComment, { loading, error, data, refetch }] = useMutation(
    DELETE_CONNECT_COMMENT
  );
  useLoadingHandler(loading);
  const deleteConnectCommentHandler = (userId, commentId) => {
    deleteComment({
      variables: {
        userId,
        commentId,
      },
    });
  };
  return [deleteConnectCommentHandler, { loading, error, data, refetch }];
};

export const useGetEzeeMealReport = (limit, page, filter) => {
  const { loading, error, data, refetch } = useQuery(GET_EZEE_MEAL_REPORT, {
    variables: {
      filter: {
        limit,
        page,
        ...filter,
      },
      skip: !filter,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useUpdateEzeeMeals = () => {
  const [updateEzeeMeals, { loading, error, data, reset }] =
    useMutation(UPDATE_EZEE_MEALS);

  useLoadingHandler(loading);
  const updateEzeeMealsHandler = (id, ezeeMealUpdate) => {
    updateEzeeMeals({
      variables: { id, ezeeMealUpdate },
    });
  };
  return [updateEzeeMealsHandler, { loading, error, data, reset }];
};

export const useGetAllUserList = (name) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_USER_LIST_TO_TAG, {
    variables: {
      name,
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useUpdateAllWhatsNew = () => {
  const [updateWhatsNewList, { loading, error, data, reset }] =
    useMutation(UPDATE_ALL_WHATSNEW);

  useLoadingHandler(loading);
  const updateAllWhatsNewHandler = (whatsNewList) => {
    updateWhatsNewList({
      variables: { whatsNewList },
    });
  };
  return [updateAllWhatsNewHandler, { loading, error, data, reset }];
};

export const useGetAllMessageConfigurationRecievers = (messageConfigFilter) => {
  const { loading, data, error, refetch } = useQuery(
    GET_COUNT_OF_MESSAGE_RECIEVER,
    {
      variables: {
        messageConfigFilter,
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetWebcheckinAnalysisReport = () => {
  const [webCheckinAnalysisReport, { loading, error, data, reset }] =
    useMutation(GET_WEBCHECKIN_ANALYSIS_REPORT);
  useLoadingHandler(loading);

  const webCheckReportAnalysisHandler = (reportInput) => {
    webCheckinAnalysisReport({
      variables: { reportInput },
    });
  };
  return [webCheckReportAnalysisHandler, { loading, error, data, reset }];
};

export const useGetPaginatedUserBookingsList = (
  limit,
  page,
  email,
  fromDate,
  toDate,
  userBooking
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_USERS_BOOKINGS_LIST,
    {
      variables: {
        filter: {
          limit,
          page,
          ...email,
        },
        skip: !email ? true : false,
      },
    }
  );
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useSaveMultiplePaySlips = () => {
  const [saveMultiplePaySlips, { loading, error, data, reset }] = useMutation(
    SAVE_MULTIPLE_PAYSLIP
  );
  const saveMultiplePaySlipsHandler = (multipleFileInput) => {
    return saveMultiplePaySlips({
      variables: { multipleFileInput },
    });
  };

  return [saveMultiplePaySlipsHandler, { loading, error, data, reset }];
};

export const useUploadXlsxFileforEmployeeProfile = () => {
  const [uploadXlsxFile, { loading, error, data, refetch }] = useMutation(
    UPLOAD_XLSX_FILE_EMPLOYEE_PROFILE
  );

  useLoadingHandler(loading);

  const uploadXlsxFileHandler = (file) => {
    uploadXlsxFile({
      variables: { file },
    });
  };

  return [uploadXlsxFileHandler, { loading, error, data, refetch }];
};

export const useShiftReviewToCantReply = () => {
  const [shiftReviewToCantReply, { loading, error, data, reset }] = useMutation(
    SHIFT_REVIEW_TO_CANT_REPLY
  );

  useLoadingHandler(loading);

  const shiftReviewToCantReplyHandler = (_id) => {
    shiftReviewToCantReply({
      variables: {
        _id,
      },
    });
  };

  return [shiftReviewToCantReplyHandler, { loading, error, data, reset }];
};

//Added by Nitish
export const useUpdateInvoicePendingClarification = () => {
  const [updateInvoicePendingClarification, { loading, error, data, reset }] =
    useMutation(UPDATE_INVOICE_PENDING_CLARIFICATION);

  useLoadingHandler(loading);

  const updateInvoicePendingClarificationHandler = (
    id,
    pendingInvoiceInput
  ) => {
    updateInvoicePendingClarification({
      variables: { id, pendingInvoiceInput },
    });
  };

  return [
    updateInvoicePendingClarificationHandler,
    { loading, error, data, reset },
  ];
};

//Added by Ashish
export const useGetCompetitorsByFilters = () => {
  const [getCompetitorsByFilters, { loading, data, error }] = useLazyQuery(
    GET_COMPETITORS_BY_FILTER
  );

  useLoadingHandler(loading);
  return { getCompetitorsByFilters, loading, error, data };
};

//Added by Ashish
export const useGetBookingCalender = (hostelId, fromDate, toDate) => {
  const shouldFetch = hostelId && fromDate && toDate;

  const { loading, error, data, refetch } = useQuery(GET_BOOKING_CALENDER, {
    variables: {
      hostelId: shouldFetch ? hostelId : "",
      fromDate: shouldFetch ? fromDate : "",
      toDate: shouldFetch ? toDate : "",
    },
    skip: !shouldFetch, // Skip query if required variables are missing
  });

  useLoadingHandler(loading);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

//Added by Ashish
export const useGetRoomTypeByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_ROOM_TYPES, {
    variables: {
      hostelId: hostelId ? hostelId : "",
    },
    skip: !hostelId, // Skip query if required variables are missing
  });
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

//Added by Ashish
export const useGetWebCheckinDataByReservationId = (reservationID) => {
  const { loading, error, data, refetch } = useQuery(
    GET_WEB_CHECKIN_DATA_BY_RESERVATION_ID,
    {
      variables: {
        reservationID,
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

// Added by Ashish
export const useMakeCheckin = () => {
  const [makeCheckin, { loading, error, data, reset }] = useMutation(CHECKIN);

  useLoadingHandler(loading);

  const makeCheckinHandler = async (
    hostelId,
    BookingId,
    GuestName,
    Email,
    Address,
    Phone,
    Mobile
  ) => {
    return makeCheckin({
      variables: {
        hostelId,
        checkinArray: [
          {
            BookingId,
            GuestName,
            Email,
            Address,
            Phone,
            Mobile,
          },
        ],
      },
    });
  };

  return { makeCheckinHandler, loading, error, data, reset };
};

// Added by Ashish
export const useMakeCheckout = () => {
  const [makeCheckout, { loading, error, data, reset }] = useMutation(CHECKOUT);

  useLoadingHandler(loading);

  const makeCheckoutHandler = async (hostelId, BookingId) => {
    try {
      const response = await makeCheckout({
        variables: {
          hostelId,
          checkoutArray: [
            {
              BookingId,
            },
          ],
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { makeCheckoutHandler, loading, error, data, reset };
};

//Added by Ashish
export const useRoomAssign = () => {
  const [roomAssign, { loading, error, data, reset }] =
    useMutation(ROOM_ASSIGN);

  useLoadingHandler(loading);

  const roomAssignHandler = async (hostelId, BookingId, RoomTypeID, RoomID) => {
    try {
      const response = await roomAssign({
        variables: {
          hostelId,
          roomData: [
            {
              BookingId,
              RoomTypeID,
              RoomID,
            },
          ],
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { roomAssignHandler, loading, error, data, reset };
};

//Added by Ashish
//GET_ALL_COMPETITORS_NAME
export const useGetAllCompetitorsName = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_COMPETITORS_NAME);
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Ashish
export const useDeleteHostelComparison = () => {
  const [deleteHostelComparison, { loading, error, data, reset }] = useMutation(
    DELETE_HOSTEL_COMPARISON
  );

  useLoadingHandler(loading);

  const deleteHostelComparisonHandler = async (id) => {
    try {
      const response = await deleteHostelComparison({
        variables: {
          id,
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { deleteHostelComparisonHandler, loading, error, data, reset };
};

export const useLogGuestAction = () => {
  const [logGuestAction, { loading, error, data, reset }] =
    useMutation(LOG_GUEST_ACTION);

  useLoadingHandler(loading);

  const logGuestActionHandler = (_id) => {
    logGuestAction({
      variables: {
        _id,
      },
    });
  };

  return [logGuestActionHandler, { loading, error, data, reset }];
};

//Added by Ashish
export const useGetGuestDatabase = (searchKeyword, hostelId) => {
  const { loading, error, data, refetch } = useQuery(_GET_GUEST_DATABASE, {
    variables: {
      filter: {
        searchKeyword,
        hostelId: hostelId,
        limit: 5,
      },
    },
    skip: !searchKeyword && !hostelId,
  });
  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useUploadXlsxFileforEmployeeTrainingScore = () => {
  const [uploadXlsxFileTrainingScore, { loading, error, data, refetch }] =
    useMutation(UPLOAD_XLSX_FILE_EMPLOYEE_TRAINING_SCORE);

  useLoadingHandler(loading);

  const uploadXlsxFileHandlerTrainingScore = async (file) => {
    return await uploadXlsxFileTrainingScore({
      variables: { file },
    });
  };

  return [
    uploadXlsxFileHandlerTrainingScore,
    { loading, error, data, refetch },
  ];
};

export const useUploadXlsxFileforEmployeePerformanceEval = () => {
  const [uploadXlsxFilePerformanceEval, { loading, error, data, refetch }] =
    useMutation(UPLOAD_XLSX_FILE_EMPLOYEE_PERFORMANCE_EVAL);

  useLoadingHandler(loading);

  const uploadXlsxFileHandlerPerformanceEvaluation = async (file) => {
    return await uploadXlsxFilePerformanceEval({
      variables: { file },
    });
  };

  return [
    uploadXlsxFileHandlerPerformanceEvaluation,
    { loading, error, data, refetch },
  ];
};

export const useGetXlsxFormatforEmployee = () => {
  const [generateXlsxForEmployee, { loading, error, data, refetch }] =
    useMutation(GET_XLSX_FORMAT_FOR_EMPLOYEE);
  useLoadingHandler(loading);
  const generateXlsxForEmployeeHandler = (operationName) => {
    generateXlsxForEmployee({
      variables: {
        operationName,
      },
    });
  };
  return [generateXlsxForEmployeeHandler, { data, refetch, loading, error }];
};

//ADDED BY NITISH
export const useGetServiceEventsSalesReport = (fromDate, toDate) => {
  const shouldFetch = fromDate && toDate;

  const { loading, error, data, refetch } = useQuery(
    GET_SERVICE_EVENTS_SALES_REPORT,
    {
      variables: {
        fromDate: shouldFetch ? fromDate : "",
        toDate: shouldFetch ? toDate : "",
      },
      skip: !shouldFetch,
    }
  );

  useLoadingHandler(loading);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const useGetServiceCategoryWiseRevenue = (
  hostelId,
  fromDate,
  toDate
) => {
  const shouldFetch = fromDate && toDate;

  const { loading, error, data, refetch } = useQuery(
    GET_SERVICE_CATEGORY_WISE_REVENUE,
    {
      variables: {
        hostelId: hostelId || [],
        fromDate: shouldFetch ? fromDate : "",
        toDate: shouldFetch ? toDate : "",
      },
      skip: !shouldFetch,
    }
  );

  useLoadingHandler(loading);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const useGetServiceTitleWiseRevenue = (
  hostelId,
  fromDate,
  toDate,
  category
) => {
  const shouldFetch = fromDate && toDate;

  const { data, loading, error, refetch } = useQuery(
    GET_SERVICE_TITLE_WISE_REVENUE,
    {
      variables: {
        hostelId: hostelId || [],
        fromDate: shouldFetch ? fromDate : "",
        toDate: shouldFetch ? toDate : "",
        // category: category || "",
        category: category.length > 0 ? category : [],
      },
      skip: !shouldFetch,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//added by Ashish
export const useGetHourlyReportOTA = (fromDate, toDate, source) => {
  const shouldSkip = !fromDate || !toDate;

  const { loading, error, data, refetch } = useQuery(GET_HOURLY_REPORT_OTA, {
    variables: {
      fromDate,
      toDate,
      source,
    },
    skip: shouldSkip,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

//Added by Nitish for ORM Keyword Classification
export const useGetNegativeKeyClassification = (fromDate, toDate) => {
  const shouldFetch = fromDate && toDate;

  const { data, loading, error, refetch } = useQuery(
    GET_NEGATIVE_KEY_CLASSIFICATION,
    {
      variables: {
        filter: {
          fromDate: shouldFetch ? fromDate : "",
          toDate: shouldFetch ? toDate : "",
        },
      },
      skip: !shouldFetch,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

// upload for hr
export const useUploadXlsxFileforEmployeeSalaryStructure = () => {
  const [uploadXlsxFileEmpSalaryStructure, { loading, error, data, refetch }] =
    useMutation(UPLOAD_XLSX_FILE_EMPLOYEE_SALARY_STRUCTURE);

  useLoadingHandler(loading);

  const uploadXlsxFileHandlerSalaryStructure = async (file) => {
    return await uploadXlsxFileEmpSalaryStructure({
      variables: { file },
    });
  };

  return [
    uploadXlsxFileHandlerSalaryStructure,
    { loading, error, data, refetch },
  ];
};

export const useGetEmployeeAttendanceExportFile = () => {
  const [getEmployeeAttendanceExportFile, { loading, error, data, refetch }] =
    useMutation(GET_ATTENDANCE_DATA_EXPORT);
  useLoadingHandler(loading);
  const getEmployeeAttendanceExportFileHandler = (month, year) => {
    getEmployeeAttendanceExportFile({
      variables: {
        month,
        year,
      },
    });
  };
  return [
    getEmployeeAttendanceExportFileHandler,
    { data, refetch, loading, error },
  ];
};

// code by arun
export const useGetHostelFoodCharges = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_HOSTEL_FOOD_CHARGES
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useUpdateHostelFoodCharge = () => {
  const [hostelFoodCharge, { loading, error, data, reset }] = useMutation(
    UPDATE_HOSTEL_FOOD_CHARGE
  );

  useLoadingHandler(loading);

  const hostelFoodChargeHandler = (hostelFoodChargeList) => {
    console.log(hostelFoodChargeList);
    hostelFoodCharge({
      variables: { hostelFoodChargeList },
    });
  };

  return [hostelFoodChargeHandler, { loading, error, data, reset }];
};

//Added by Nitish
export const useGenerateORMExport = () => {
  const [generateORMExport, { loading, error, data, refetch }] = useMutation(
    GENERATE_ORM_EXPORT_DATA
  );
  useLoadingHandler(loading);
  const generateORMExportHandler = (filter) => {
    generateORMExport({
      variables: {
        filter,
      },
    });
  };
  return [generateORMExportHandler, { data, refetch, loading, error }];
};

//Added by Nitish
export const useGenerateServiceExport = () => {
  const [generateServiceExport, { loading, error, data, refetch }] =
    useMutation(GENERATE_SERVICE_EXPORT_DATA);
  useLoadingHandler(loading);

  const generateServiceExportHandler = (filter) => {
    generateServiceExport({
      variables: {
        filter,
      },
    });
  };
  return [generateServiceExportHandler, { data, refetch, loading, error }];
};

// arun
export const useUploadMonthlyValuesEmployeeMonthlySalary = () => {
  const [uploadMonthlyValuesOfSalary, { loading, error, data, refetch }] =
    useMutation(UPLOAD_XLSX_EMPLOYEE_MONTHLY_VALUES);

  useLoadingHandler(loading);

  const uploadMonthlyValuesHandler = async (file) => {
    return await uploadMonthlyValuesOfSalary({
      variables: { file },
    });
  };

  return [uploadMonthlyValuesHandler, { loading, error, data, refetch }];
};

export const useGetEmployeeProcessedSalariesExportFile = () => {
  const [
    getEmployeeProcessedSalariesPaysheet,
    { loading, error, data, refetch },
  ] = useMutation(GET_PROCESSED_SALARIES_EXPORT);
  useLoadingHandler(loading);
  const getEmployeeProcessedSalariesPaysheetHandler = (month, year) => {
    getEmployeeProcessedSalariesPaysheet({
      variables: {
        month,
        year,
      },
    });
  };
  return [
    getEmployeeProcessedSalariesPaysheetHandler,
    { data, refetch, loading, error },
  ];
};

//Added by Nitish
export const useGetHostelReviewSummary = (month, year) => {
  const { loading, error, data, refetch } = useQuery(
    GET_HOSTEL_REVIEW_SUMMARY,
    {
      variables: { month, year },
      skip: month === null || year === null,
    }
  );

  return { loading, error, data, refetch };
};

//Added by Nitish - For events bookings
export const useGenerateEventsExport = () => {
  const [generateEventsExport, { loading, error, data, refetch }] = useMutation(
    GENERATE_EVENTS_EXPORT_DATA
  );
  useLoadingHandler(loading);

  const generateEventsExportHandler = (filter) => {
    generateEventsExport({
      variables: {
        filter,
      },
    });
  };
  return [generateEventsExportHandler, { data, refetch, loading, error }];
};

//Added by Nitish - For User Request bookings
export const useGenerateUserRequestExport = () => {
  const [generateUserRequestExport, { loading, error, data, refetch }] =
    useMutation(GENERATE_USER_REQUEST_EXPORT_DATA);
  useLoadingHandler(loading);

  const generateUserRequestExportHandler = (filter) => {
    generateUserRequestExport({
      variables: {
        filter,
      },
    });
  };
  return [generateUserRequestExportHandler, { data, refetch, loading, error }];
};

// ADDED BY ARUN
export const useUploadBulkDocument = () => {
  const [uploadBulkEmployeeDocuments, { loading, error, data, refetch }] =
    useMutation(UPLOAD_BULK_EMPLOYEE_DOCUMENTS);

  // useLoadingHandler(loading);

  const uploadBulkEmployeeDocumentsHandler = async (file) => {
    return await uploadBulkEmployeeDocuments({
      variables: { file },
    });
  };

  return [
    uploadBulkEmployeeDocumentsHandler,
    { loading, error, data, refetch },
  ];
};

//Added by Nitish
export const useGenerateDayBookExport = () => {
  const [generateDayBookExport, { loading, error, data, refetch }] =
    useMutation(GENERATE_DAY_BOOK_EXPORT_DATA);
  useLoadingHandler(loading);
  const generateDayBookExportHandler = (filter) => {
    generateDayBookExport({
      variables: {
        filter,
      },
    });
  };
  return [generateDayBookExportHandler, { data, refetch, loading, error }];
};

//Added by Ashish
export const useGetRoomNames = () => {
  const [ getRoomNames, { loading, error, data, refetch }] = useLazyQuery(GET_ROOM_NAMES)

  return { getRoomNames, loading, error, data, refetch };
}

//Added by Ashish
export const useGetmaxDateForWhichDataisPresent = () => {
  const [ getMaxDateForWhichTheDataIsPresent, { loading, error, data, refetch }] = useLazyQuery(GET_MAX_DATE_FOR_DATA);

  return { getMaxDateForWhichTheDataIsPresent, loading, error, data, refetch };
}

//Added by Ashish
export const useGetLatestLastUpdatedAt = () => {
  const [ getLatestLastUpdatedAt, { loading, error, data, refetch }] = useLazyQuery(GET_LATEST_LAST_UPDATED_AT);

  return { getLatestLastUpdatedAt, loading, error, data, refetch };
}

export const useGetDatewiseOccupancy = () => {
  const [ getDateWiseOccupancy, { loading, error, data, refetch } ] = useLazyQuery(GET_DATE_WISE_OCCUPANCY);
  useLoadingHandler(loading);
  return { getDateWiseOccupancy, loading, error, data, refetch };
}